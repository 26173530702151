<template>
  <div>
    <div >
      <!-- 教学计划 -->
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        选课管理
      </div>
      <!-- 添加 ---重置按钮 -->
      <section class="tianjia">
        <section>
          <el-button-group>
            <template slot-scope="scope">
            <el-button type="primary" size="medium" v-permission="'eduStudentCourse:listXK'" @click="courseOne(scope.row)">单个选课<i class="el-icon-download"></i></el-button>
            <el-button type="primary" size="medium" v-permission="'eduStudentCourse:listXKs'" @click="courseList">批量选课<i class="el-icon-download"></i></el-button>
<!--            <el-button type="primary" size="medium" @click="importTemplate">导入选课名单<i class="el-icon-download"></i></el-button>-->
            <el-button type="primary" size="medium" @click="download">导出<i class="el-icon-download"></i></el-button>
            </template>
          </el-button-group>
        </section>
        <section>
          <el-button type="primary" size="medium" @click="query" v-permission="'eduStudentCourse:listYx'">查询</el-button>
          <el-button type="primary" size="medium" @click="rest(bo)">重置</el-button>
        </section>
      </section>
      <!-- 名称-是否同步 -->
      <section class="mingcheng">
        <el-form
            :model="bo"
            ref="bo"
            :inline="true"
            class="demo-form-inline"
            label-width="80px"
            size="medium">
          <el-form-item label="学号/姓名" prop="name">
            <el-input v-model="bo.name"  style="width: 220px" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <grade-select-all v-model="bo.gradeId" @input="query" ref="grades"></grade-select-all>
          </el-form-item>
          <el-form-item label="层次" prop="stageId">
            <stage-select-all v-model="bo.stageId" @input="query" ref="stages"></stage-select-all>
          </el-form-item>
          <el-form-item label="专业" prop="profId">
            <prof-select-all v-model="bo.profId" @input="query" ref="profs"></prof-select-all>
          </el-form-item>
          <el-form-item label="站点" prop="siteId">
            <site-select-all v-model="bo.siteId" @input="query" ref="site"></site-select-all>
          </el-form-item>

        </el-form>
      </section>
      <!-- 共选中0条数据 -->
      <div style="padding: 18px; text-align: left; color: #1890ff; font-size: 17px">
        共选中{{ multipleSelection.length }}条数据
      </div>
      <div style="padding: 18px; font-size: 17px"
      >
        <el-table
            size="medium"
            height="505px"
            max-height="505px"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableData"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"

        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" type="index" width="60">
          </el-table-column>
          <el-table-column
              prop="name"
              sortable
              show-overflow-tooltip
              label="姓名"
          >
          </el-table-column>
          <el-table-column
              prop="studentNo"
              sortable
              show-overflow-tooltip
              label="学号"
          >
          </el-table-column>
          <el-table-column
              prop="siteName"
              sortable
              show-overflow-tooltip
              label="函授站"
          >
          </el-table-column>
          <el-table-column
              prop="gradeName"
              sortable
              show-overflow-tooltip
              label="年级"
          >
          </el-table-column>
          <el-table-column
              prop="stageName"
              sortable
              show-overflow-tooltip
              label="层次"
          >
          </el-table-column>
          <el-table-column
              prop="profName"
              sortable
              show-overflow-tooltip
              label="专业"
          >
          </el-table-column>
          <!-- 产看课程 -->
          <el-table-column
              sortable
              width="150"
              show-overflow-tooltip
              label="查看选课情况">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="lookWork(scope.row)" v-permission="'eduStudentCourse:listOne'">查看选课情况</el-link>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="padding: 18px; font-size: 17px">
          <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="bo.page"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="bo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="downloadbutton">下载模板</el-button>
        <span style="color: red"
        >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
          class="upload-demo"
          drag
          action="#"
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>

    <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUploades" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="downloadbuttons">下载模板</el-button>
        <span style="color: red"
        >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
          class="upload-demo"
          drag
          action="#"
          :show-file-list="false"
          :http-request="uploadFiles"
          :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>


    <el-dialog class="add" :title="title" :visible.sync="putDialog2"  center>
      <div>
        <el-button type="primary" icon="el-icon-folder-add" size="small" @click="remove">删除</el-button>
        <el-button type="primary" icon="el-icon-circle-close" size="small" @click="queryLooks">核查课程</el-button>
      </div>
      <div style="margin-top: 20px">
        <el-input style="width: 30vw" v-model="newBo.info">
          <el-button slot="append" icon="el-icon-search" type="primary" @click="queryLook"></el-button>
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-table
            size="medium"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableData2"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="selectStudent">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
              type="index"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="courseName"
              show-overflow-tooltip
              label="课程名称"
          >
          </el-table-column>
          <el-table-column
              prop="courseCode"
              show-overflow-tooltip
              label="课程编号"
          >
          </el-table-column>
          <el-table-column
              prop="period"
              show-overflow-tooltip
              label="学时"
          >
          </el-table-column>
          <el-table-column
              prop="semester"
              show-overflow-tooltip
              label="学期"
          >
          </el-table-column>
          <el-table-column
              prop="courseType"
              show-overflow-tooltip
              label="课程类型"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.courseType==0">公共必修</span>
              <span v-if="scope.row.courseType==1">公共选修</span>
              <span v-if="scope.row.courseType==2">专业必修</span>
              <span v-if="scope.row.courseType==3">专业选修</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="profFlag"
              show-overflow-tooltip
              label="是否是当前专业所选课程"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.profFlag==0">是</span>
              <span v-if="scope.row.profFlag==1">否</span>
            </template>
          </el-table-column>

        </el-table>
                <el-pagination
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="1"
                    :page-sizes="[10, 30, 50, 100]"
                    :page-size="1"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total2">
                </el-pagination>
      </div>
    </el-dialog>

    <el-dialog class="add" :title="title" :visible.sync="putDialog3" center>
      <el-button type="primary" size="medium" @click="sumbit">选课<i class="el-icon"></i></el-button>
      <div style="margin-top: 20px">
        <el-table
            size="medium"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableDataStu"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange3"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
              width="80"
              sortable
              type="index"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="courseName"
              label="课程名称">
          </el-table-column>
          <el-table-column
              prop="courseCode"
              label="课程编号">
          </el-table-column>
          <el-table-column
              prop="period"
              label="学时">
          </el-table-column>
          <el-table-column
              prop="semester"
              label="学期">
          </el-table-column>
          <el-table-column
              prop="courseType"
              label="课程类型">
            <template slot-scope="scope">
              <span v-if="scope.row.courseType==0">公共必修</span>
              <span v-if="scope.row.courseType==1">公共选修</span>
              <span v-if="scope.row.courseType==2">专业必修</span>
              <span v-if="scope.row.courseType==3">专业选修</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog class="add" :title="title" :visible.sync="putDialog4" center>
      <el-button type="primary" size="medium" @click="sumbits">选课<i class="el-icon"></i></el-button>
      <div style="margin-top: 20px">
        <el-table
            size="medium"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            ref="multipleTable"
            :data="tableDataStus"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange4"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
              width="50"
              sortable
              type="index"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="courseName"
              label="课程名称">
          </el-table-column>
          <el-table-column
              prop="courseCode"
              label="课程编号">
          </el-table-column>
          <el-table-column
              prop="period"
              label="学时">
          </el-table-column>
          <el-table-column
              prop="semester"
              label="学期">
          </el-table-column>
          <el-table-column
              prop="courseType"
              label="课程类型">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import GradeSelectAll from "../../components/public/grade-select-all";
import StageSelectAll from "../../components/public/stage-select-all";
import ProfSelectAll from "../../components/public/prof-select-all";
import SiteSelectAll from "../../components/public/site-select-all";

export default {
  components: {
    SiteSelectAll,
    ProfSelectAll,
    StageSelectAll,
    GradeSelectAll
  },
  data() {
    return {
      kecId:null,
      snedStu: [],
      snedStus: [],
      tableDataStu: [],
      tableDataStus: [],
      putDialog3: false,
      putDialog4: false,
      testTypeOpt: [
        {value: 0, label: '考查课'},
        {value: 1, label: '在线考试'},
        {value: 2, label: '书面考试'},
      ],
      semesterOpt: [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
        {value: '6', label: '6'},
        {value: '7', label: '7'},
        {value: '8', label: '8'},
        {value: '9', label: '9'},
        {value: '10', label: '10'},
      ],
      degreeFlag: [
        {value: '0', label: '否'},
        {value: '1', label: '是'},
      ],
      typeOpt: [{
        value: 0,
        label: '公共必修'
      }, {
        value: 1,
        label: '公共选修'
      }, {
        value: 2,
        label: '专业必修'
      }, {
        value: 3,
        label: '专业选修'
      },],
      newBo: {
        info: '',
        page: 1,
        pageSize: 10,
       // planId: null,
        //status: ''
      },
      total2: 0,
      putDialog2: false,
      putDialog: false,
      dialogVisibleUploads: false,
      dialogVisibleUploades: false,
      addBo: {
        id: null,
        gradeId: '',//年级ID
        stageId: '',//层次ID
        profId: '',//专业ID
        planName: '',//计划名称
        subName: '',//建议名称
        remark: ''//备注
      },
      total: 0,
      bo: {
        page: 1,
        pageSize: 10,
        name: '',
        gradeId: '',//年级ID
        stageId: '',//层次ID
        profId: '',//专业ID
        siteId: '',//站点id
      },
      formInline: {
        user: "",
        region: "",
      },
      xuanke:{
        studentId:null
      },
      tableData: [],
      currentPage4: 1,
      pageSize: 100,
      multipleSelection: [],
      title: '',
      pop: [{name: ""}, {name: ""}, {name: ""}],
      tableData2: [],
      studentId: null,
      selectStu: [],
    };
  },

  mounted() {

    this.query();
  },
  methods: {
    remove(){
      if (this.selectStu.length <= 0) {
        this.$message.warning("请选择条目");
        return
      }

      let ids = "";
      this.selectStu.forEach(item => {
        ids = ids + item.id + ",";
      });
      ids = ids.substr(0, ids.length - 1).trim();

      let formData = new FormData();
      formData.append("list",ids);
      formData.append("studentId",this.studentId);
      this.$axios_supermallData.post("hnjxjy-core/eduStudentCourse/removeByStudent",formData).then(resp =>{
        if (resp.data.code == 2000){
          this.$message.success("操作成功");
          this.queryLook();
        }
      })
    },
    //保存课程
    endStu(){
      if (this.snedStu.length <= 0){
        this.$message.warning("请选择条目");
      }
      let ids = "";
      this.snedStu.forEach(item => {
        ids = ids + item.id + ",";
      });
      ids = ids.substr(0, ids.length - 1).trim();
      let bo ={
        planId:this.id,
        ids:ids
      };
      this.$axios_supermall.post("/hnjxjy-core/eduTeachPlanCourse/insertEduPlanCourse",bo).then(resp =>{
        if (resp.data.code == 2000){
          this.putDialog3 = false;
          this.queryLook();
        }
      })

    },
    //开启关闭课程
    isEnable(type) {
      if (this.selectStu.length <= 0) {
        this.$message.warning("请选择条目");
      }

      let ids = "";
      this.selectStu.forEach(item => {
        ids = ids + item.id + ",";
      });
      ids = ids.substr(0, ids.length - 1).trim();
      let formData = new FormData();
      formData.append("ids",ids);
      formData.append("status",type);
      this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlanCourse/isEnable",formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
          this.queryLook();
        }
      });
    },
    handleSelectionChange3(val) {
      this.snedStu = val;
    },
    handleSelectionChange4(val) {
      this.snedStus = val;
    },
    //添加课程查询
    addStu() {
      this.$axios_supermall.post("/hnjxjy-core/eduTeachPlanCourse/list?info=" + this.newBo.info + "&planId=" + this.id + "&status=1").then(resp => {
        if (resp.data.code == 2000) {
          this.tableDataStu = resp.data.data.list;
        }
      });
      this.putDialog3 = true;
    },
    //修改课程内容
    updateFun(row) {
      let formData = new FormData();
      formData.append("id", row.id);
      formData.append("courseType", row.courseType);
      formData.append("period", row.period);
      formData.append("semester", row.semester);
      formData.append("degreeFlag", row.degreeFlag);
      formData.append("testType", row.testType);
      this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlanCourse/edit", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("修改成功");
        }
      })
    },
    importcourse(){
      this.dialogVisibleUploades = true
    },
    handleCurrentChange2(pages) {
      this.newBo.page = pages;
      this.queryLook();
    },
    handleSizeChange2(sizes) {
      this.newBo.pageSize = sizes;
      this.queryLook();
    },
    selectStudent(val) {
      this.selectStu = val;
    },
    lookWork(row) {
      this.id = row.id;
      this.studentId=row.id
      this.newBo.info=""
      this.queryLook();
      this.putDialog2 = true;

    },
    queryLook() {

      this.$axios_supermall.post("/hnjxjy-core/eduStudentCourse/listOne?info=" + this.newBo.info + "&studentId=" + this.id + "&page=" + this.newBo.page + "&pageSize=" + this.newBo.pageSize).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData2 = resp.data.data.list;
          this.total2 = resp.data.data.total
        }
      });
    },
    queryLooks() {

      this.$axios_supermall.post("/hnjxjy-core/eduStudentCourse/listOne?studentId=" + this.id + "&page=" + this.newBo.page + "&pageSize=" + this.newBo.pageSize).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData2 = resp.data.data.list;
          this.total2 = resp.data.data.total
          this.$message.success("核查成功")
        }
      });
    },
    courseOne(){
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.xuanke.studentId = arr
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        if(this.multipleSelection.length>1){
          this.$message({
            type:"error",
            message:"只能选择一个"
          })
        }else{
          this.putDialog3 = true
          this.$axios_supermall.get("/hnjxjy-core/eduStudentCourse/selectEduCourseByGradeId?studentId=" +arr).then(resp => {
            if (resp.data.code == 2000) {
              this.tableDataStu = resp.data.data;
            }
          });
        }
      }else{
        this.$message({
          type:"error",
          message:"请至少选择一个"
        })
      }
    },
    courseList(){
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.xuanke.studentId = arr
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        if(this.bo.gradeId!=null&&this.bo.gradeId!=""&&this.bo.stageId!=null&&this.bo.stageId!=""&&this.bo.profId!=null&&this.bo.profId!=""){
          this.putDialog4 = true
          this.openLoadingView();
          this.$axios_supermall.get("/hnjxjy-core/eduStudentCourse/selectEduCourseByGradeIdand?gradeId="+this.bo.gradeId+"&profId="+this.bo.profId+"&stageId="+this.bo.stageId).then(resp => {
            if (resp.data.code == 2000) {
              this.tableDataStus = resp.data.data;
            }
          });
        }else{
          this.$message({
            type:"error",
            message:"批量选课必须选择年级、专业和层次"
          })
        }
      }else{
        this.$message({
          type:"error",
          message:"请至少选择一个"
        })
      }
    },
    rest(form){
      this.$refs.bo.resetFields()
      this.$refs['grades'].clearValue();
      this.$refs['stages'].clearValue();
      this.$refs['profs'].clearValue();
      this.$refs['site'].clearValue();
    },
    sumbit(){
      if(this.snedStu!=null&&this.snedStu!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.snedStu.length; i++) {
          array.push(this.snedStu[i].id);
        }
        let arr = array.toString();
          this.$axios({
            url: "hnjxjy-core/eduStudentCourse/courseSelection",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
              studentId:this.xuanke.studentId
            },
          }).then((res) => {
            if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.putDialog3 = false
                this.query();
            }
          })
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }

    },
    sumbits(){
      if(this.snedStus!=null&&this.snedStus!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.snedStus.length; i++) {
          array.push(this.snedStus[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduStudentCourse/batchCourseSelection",
          headers: this.loginList,
          method: "post",
          params: {
            ids2: arr,
            ids	:this.xuanke.studentId
          },
        }).then((res) => {
          if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.putDialog4 = false
              this.query();
          }
        })
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }

    },
    updateShow(row) {
      this.$axios_supermall.get("/hnjxjy-core/eduTeachPlan/getOne?id=" + row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.addBo = resp.data.data;
        }
        this.$refs['grade'].selValue = this.addBo.gradeId;
        this.$refs['stage'].selValue = this.addBo.stageId;
        this.$refs['prof'].selValue = this.addBo.profId;
      })
      this.putDialog = true;
    },
    end() {
      if (this.addBo.id != null) {
        let formData = new FormData();
        formData.append("id", this.addBo.id);
        formData.append("gradeId", this.addBo.gradeId);
        formData.append("stageId", this.addBo.stageId);
        formData.append("profId", this.addBo.profId);
        formData.append("planName", this.addBo.planName);
        formData.append("remark", this.addBo.remark);
        this.$axios_supermallData.post("/hnjxjy-core/eduTeachPlan/edit", formData).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功");
            this.putDialog = false;
          }
        });
      } else {
        this.$axios_supermall.post("/hnjxjy-core/eduTeachPlan/addEduTeachPlanService", this.addBo).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功");
            this.putDialog = false;
          }
        });
      }
      this.query();

    },
    sendGra() {
      this.pop[0].name = this.$refs['grade'].options.find(i => i.id == this.addBo.gradeId).gradeName;
      this.forPop();
    },
    sendSta() {
      this.pop[1].name = this.$refs['stage'].options.find(i => i.id == this.addBo.gradeId).stageName;
      this.forPop();
    },
    sendPro() {
      this.pop[2].name = this.$refs['prof'].options.find(i => i.id == this.addBo.gradeId).profName;
      this.forPop();
    },
    forPop() {
      this.addBo.planName = this.pop[0].name + this.pop[1].name + this.pop[2].name;
      this.addBo.subName = this.pop[0].name + this.pop[1].name + this.pop[2].name;
    },
    addPut() {
      this.addBo = {
        gradeId: '',//年级ID
        stageId: '',//层次ID
        profId: '',//专业ID
        planName: '',//计划名称
        subName: '',//建议名称
        remark: ''//备注
      }
      this.putDialog = true;
    },
    query() {
      this.getPermission('eduStudentCourse:listYx', () => {
        this.openLoadingView();
        this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list?page="
            + this.bo.page + "&pageSize="
            + this.bo.pageSize + "&info="
            + this.bo.name + "&gradeId="
            + this.bo.gradeId + "&stageId="
            + this.bo.stageId + "&profId="
            + this.bo.profId + "&siteId="
            + this.bo.siteId).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
          this.loadingView.close();
        }).catch(() => {
          this.loadingView.close();
        })
      });
    },
    importTemplate(){
      this.dialogVisibleUploads = true
    },
    // 导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentCourse/export",
        responseType: "blob",
        headers: objPar,
        params:{
          info:this.bo.name,
          stageId:this.bo.stageId,
          siteId:this.bo.siteId,
          gradeId:this.bo.gradeId,
          profId:this.bo.profId,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduTeachPlan/eduTeachPlanImportTemplate",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
          })
          .catch((err) => {
          });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTeachPlan/downloadEduTeachPlanTemplate",
        responseType: "blob",
        headers: objPar,
        params:{
          status:1
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
          })
          .catch((err) => {
          });
    },
    uploadFiles(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduCourse/exportExcelCourse",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
          })
          .catch((err) => {
          });
    },
    downloadbuttons(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduTeachPlanCourse/exportMB",
        responseType: "blob",
        headers: objPar,
        params:{
          status:1
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
          })
          .catch((err) => {
          });
    },
    onSubmit() {
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(index, row) {
    },
    handleDelete(row) {
      this.$axios_supermall.post("hnjxjy-core/eduTeachPlan/remove?id="+row.id,).then(resp => {
        if(resp.data.code!=2000){
          return;
        }
        this.$message({
          type: "success",
          message: resp.data.message,
        });
        this.query()
      })
    },

    handleCurrentChange(page) {
      this.bo.page = page;
      this.query();
    },

    handleSizeChange(size) {
      this.bo.pageSize = size;
      this.query();
    }
  },
};
</script>
<style scoped>
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}

/*  添加 ---重置按钮 */
.tianjia {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.tianjia section:nth-child(1) {
  margin-left: 10px;
}

.tianjia section:nth-child(1) .el-button {
  margin-left: 10px;
  background: #1890ff;
}

.tianjia section:nth-child(2) {
  margin-right: 10px;
}

.tianjia section:nth-child(2) .el-button {
  margin-left: 10px;
  background: #1890ff;
}

.mingcheng {
  width: 100%;
  margin-left: 15px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
