<template>
    <div>
        <div>
            <div
                    style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
            >
                学生成绩查询
            </div>
            <div class="search">
                <el-form :model="search" class="demo-ruleForm" label-width="75px" inline>
                    <el-form-item>
                        <el-button icon="el-icon-download" type="primary" v-permission="'eduStudentScore:importData'"
                                   @click="opendilog">导入总评成绩
                        </el-button>
                        <el-button icon="el-icon-download" type="primary" @click="downloads">导出成绩单</el-button>
                        <el-button icon="el-icon-download" type="primary" @click="download"
                                   v-permission="'eduStudentScore:export'">导出
                        </el-button>
                        <!--            <el-button icon="el-icon-download" type="primary" @click="downloadss">导出成绩大表</el-button>-->
                        <!--            <el-button icon="el-icon-close" type="primary" @click="centerfabu">取消发布</el-button>-->
                        <el-button type="primary" v-permission="'eduStudentScore:list'" @click="handleSearch">查询
                        </el-button>
                        <el-button @click="resetSearch">重置
                        </el-button
                        >
                    </el-form-item>
                    <div>
                        <div style="display: inline-block">
                            <el-form-item label="姓名/学号" prop="siteId">
                                <el-input
                                        v-model="search.name"
                                        clearable
                                        @input="() => (search.name = search.name.trim())"
                                        placeholder="姓名/学号"
                                        style="width: 200px"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item label="专业" prop="batchId" style="margin-bottom: 0">
                                <el-select
                                        clearable
                                        v-model="search.profId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                        style="width: 200px;"
                                >
                                    <el-option
                                            v-for="(item,index) in eduProfession"
                                            :key="index"
                                            :label="item.profName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style="display: inline-block">
                            <el-form-item label="年级" prop="gradeId" style="margin-bottom: 0;">
                                <el-select style="width: 200px;" clearable v-model="search.gradeId"
                                           @change="QuerySemester" class="el-form-input-width" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in this.options"
                                            :key="index"
                                            :label="item.gradeName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style="display: inline-block">
                            <el-form-item label="学期" prop="values" style="margin-bottom: 0">
                                <el-select
                                        style="width: 200px;"
                                        clearable
                                        v-model="search.semester"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="(item,index) in semesters"
                                            :key="index"
                                            :label="item.maxTerm"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>


                        <div style="display: inline-block">
                            <el-form-item label="考试课程" prop="courseId" style="margin-bottom: 0">
                                <el-select
                                        style="width: 200px;"
                                        clearable
                                        v-model="search.courseId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="(item,index) in course"
                                            :key="index"
                                            :label="item.courseName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style="display: inline-block">
                            <el-form-item label="学习状态" prop="value" style="margin-bottom: 0;">
                                <el-select style="width: 200px;" v-model="search.passFlag" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in optionsss"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item label="层次" prop="stageId" style="margin-bottom: 0">
                                <el-select style="width: 200px;" v-model="search.stageId" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in eduStage"
                                            :key="index"
                                            :label="item.stageName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item label="函授站" prop="siteId" style="margin-bottom: 0">
                                <el-select
                                        style="width: 200px;"
                                        clearable
                                        v-model="search.siteId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="(item,index) in site"
                                            :key="index"
                                            :label="item.siteName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                          size="medium"
                          height="500px"
                          max-height="500px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="100" type="index"></el-table-column>
                    <el-table-column label="姓名" prop="name" width="160"></el-table-column>
                    <el-table-column label="学号" prop="studentNo" width="160"></el-table-column>
                    <el-table-column label="函授站" prop="siteName" width="160"></el-table-column>
                    <el-table-column label="年级" prop="gradeName" width="160"></el-table-column>
                    <el-table-column label="层次" prop="stageName" width="160"></el-table-column>
                    <el-table-column label="专业" prop="profName" width="160"></el-table-column>
                    <el-table-column label="课程名称" show-overflow-tooltip prop="courseName" width="160"></el-table-column>
                    <el-table-column label="课程编号" show-overflow-tooltip prop="courseCode" width="160"></el-table-column>
                    <el-table-column label="学习状态是否通过" show-overflow-tooltip prop="passFlag" width="160"></el-table-column>
                    <el-table-column label="学习进度" prop="learnProgress" width="160"></el-table-column>
                    <el-table-column label="离线成绩" prop="offlineScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.offlineScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.offlineScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="在线交互" prop="mutualScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.mutualScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.mutualScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="在线作业" prop="workScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.workScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.workScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="免试成绩" prop="freeScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.freeScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.freeScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="考试成绩" prop="examScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.examScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.examScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="总评成绩" prop="overallScore" width="160">
                        <template slot-scope="scope">
                            <div v-if="null == scope.row.overallScore">
                                暂无成绩
                            </div>
                            <div v-else>
                                {{scope.row.overallScore}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="已发布" prop="publishOverrall" width="160"></el-table-column>
                    <el-table-column label="详情" width="180" fixed="right">
                        <template slot-scope="scope">
                            <div
                                    style="color: #1890ff; cursor: pointer"
                                    @click="reshuffle(scope.row)"
                            >
                                查看详情
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="search.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="search.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="this.total"
                    >
                    </el-pagination>
                </div>
            </div>
            <!-- 查看详情 -->
            <el-dialog
                    title="查看详情"
                    :visible.sync="dialogVisibleUpload1"
                    width="50%"
            >
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogVisibleUpload1 = false">关闭</el-button>
                </div>
                <div>
                    <div style="margin-bottom: 20px; text-align: left">
                        <el-table :data="versionList" border style="width: 100%">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="courseName" label="课程名称"></el-table-column>
                            <el-table-column prop="courseCode" label="课程编号"></el-table-column>
                            <el-table-column prop="passFlag" label="是否通过"></el-table-column>
                            <el-table-column prop="offlineScore" label="离线成绩">
                                <template slot-scope="scope">
                                    <div v-if="null == scope.row.offlineScore">
                                        暂无成绩
                                    </div>
                                    <div v-else>
                                        {{scope.row.offlineScore}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="mutualScore" label="在线交互">
                                <template slot-scope="scope">
                                    <div v-if="null == scope.row.mutualScore">
                                        暂无成绩
                                    </div>
                                    <div v-else>
                                        {{scope.row.mutualScore}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="workScore" label="在线作业"></el-table-column>
                            <el-table-column prop="freeScore" label="免试成绩">
                                <template slot-scope="scope">
                                    <div v-if="null == scope.row.freeScore">
                                        暂无成绩
                                    </div>
                                    <div v-else>
                                        {{scope.row.freeScore}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="examScore" label="考试成绩"></el-table-column>
                            <el-table-column prop="overallScore" label="总评成绩">
                              <template slot-scope="scope">
                                <div v-if="null == scope.row.overallScore">
                                  暂无成绩
                                </div>
                                <div v-else>
                                  {{scope.row.overallScore}}
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="publishOverrall" label="发布状态"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-dialog>
            <!-- 导入 上传文件 -->
            <el-dialog title="上传文件" :visible.sync="dialogVisibleImport" width="30%">
                <div style="margin-bottom: 20px">
                    <el-button type="primary" @click="downloadbutton"
                    >下载模板
                    </el-button
                    >
                    <span style="color: red"
                    >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                    >
                </div>
                <el-upload
                        class="upload-demo"
                        drag
                        action="#"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        :before-upload="beforeUpload"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传excel文件，且不超过50M
                    </div>
                </el-upload>
            </el-dialog>
            <el-dialog
                    title="设置总评成绩生成规则"
                    :visible.sync="dialogVisibleUpload"
                    width="30%"
            >
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogVisibleUpload = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                    >确定
                    </el-button
                    >
                </div>
                <div>
                    <div style="margin-bottom: 20px; text-align: left">
                        离线成绩：
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px; text-align: left">
                        在线交互：
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px; text-align: left">
                        考试成绩：
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px; text-align: left">
                        通过成绩 ≥：
                        <el-input v-model="input" placeholder="请输入内容" style="width:40%"></el-input>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>


    export default {

        created() {
            this.handleSearch()
            this.queryEduStage();
            this.queryEduProfession();
            this.queryEduSite();
            this.gradList();
            this.queryCourse();
        },
        data() {
            return {
                myHeaders: {
                    'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
                    'x-perm': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
                },
                course: [],
                eduStage: [],
                eduProfession: [],
                site: [],
                eduGraduateWorkBatch: [],
                total: 0,
                pageNum: 1,
                num: 1,
                input: "",
                pageSize: 10,
                textarea: "",
                dialogVisibleUpload: false,
                dialogVisible: false,
                dialogVisibleUpload1: false,
                dialogVisibleImport: false,
                radio: "1",
                title: "",
                semesters: [],
                search: {
                    name: "",
                    gradeId: "",
                    semester: "",
                    courseId: "",
                    passFlag: "",
                    siteId: "",
                    stageId: "",
                    profId: "",
                    value: "",
                    values: "",
                    page: 1,
                    pageSize: 10,
                },
                teacherForm: {
                    name: "",
                    time: "",
                },
                tableChecked: [],
                tableData: [],
                versionList: [],
                currentPage4: 4,
                currentPage1: 4,
                options: [],
                optionsss: [
                    {
                        value: 0,
                        label: "否",
                    },
                    {
                        value: 1,
                        label: "是",
                    },
                ],
                value: "",
                pickerOptions: {},
                value1: "",
            };
        },
        methods: {
            resetSearch() {
                this.search.name = "";
                this.search.gradeId = "";
                this.search.semester = "";
                this.search.courseId = "";
                this.search.passFlag = "";
                this.search.siteId = "";
                this.search.stageId = "";
                this.search.profId = "";
            },
            handleSearch() {
                this.openLoadingView()
                let fromData = new FormData();
                fromData.append("gradeId", this.search.gradeId);
                fromData.append("semester", this.search.semester);
                fromData.append("info", this.search.name);
                fromData.append("siteId", this.search.siteId);
                fromData.append("stageId", this.search.stageId);
                fromData.append("profId", this.search.profId);
                fromData.append("courseId", this.search.courseId);
                fromData.append("passFlag", this.search.passFlag);
                fromData.append("page", this.search.page);
                fromData.append("pageSize", this.search.pageSize);
                this.$axios_supermall.post("/hnjxjy-core/eduStudentScore/list?page=" + this.search.page + "&pageSize=" + this.search.pageSize, fromData).then(resp => {
                    this.tableData = resp.data.data.list; //表格列表
                    this.total = resp.data.data.total;

                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            //查询课程
            queryCourse() {
                this.$axios_supermall.post("/hnjxjy-core/eduCourse/courseList").then(resp => {
                    if (resp.data.code == 2000) {
                        this.course = resp.data.data;
                    }
                })
            },
            //查询年级
            gradList() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    url: "hnjxjy-core/eduGrade/listGL",
                    headers: this.loginList,
                    method: "POST",
                }).then((res) => {
                    if (res.data.code !== 2000) {
                        return;
                    }
                    this.options = res.data.data;
                });
            },
            //查询学期
            QuerySemester() {
              if(this.search.gradeId != null && this.search.gradeId != ""){
                this.openLoadingView()
                this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId=" + this.search.gradeId).then(resp => {
                  if (resp.data.code == 2000) {
                    if(resp.data.data!=null){
                      this.semesters = []
                      for (let i = resp.data.data.maxTerm; i > 0; i--) {
                        this.semesters.push({id: i, maxTerm: i});
                      }
                    }else{
                      this.$message.error("该年级下暂无学期")
                    }

                  }
                })
              }
            },
            queryEduStage() {
                this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduStage = resp.data.data;
                    }
                })
            },
            //查询专业
            queryEduProfession() {
                this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduProfession = resp.data.data;
                    }
                })
            },
            //查询函授站下拉
            queryEduSite() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            reshuffle(row) {
                this.dialogVisibleUpload1 = true;
                this.versionList = []
                this.$axios_supermall.get("/hnjxjy-core/eduStudentScore/getOne?id=" + row.id).then(resp => {
                    if (resp.data.code == 2000) {
                        this.versionList.push(resp.data.data);
                    }
                })
            },
            centerfabu() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    let array = [];
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        array.push(this.multipleSelection[i].id);
                    }
                    let arr = array.toString();
                    this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.$axios({
                            url: "hnjxjy-core/eduScorePublish/isRelease",
                            headers: this.loginList,
                            method: "post",
                            params: {
                                ids: arr,
                                status: 4
                            },
                        }).then((res) => {
                            if (res.data.code == 2000) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.handleSearch()
                            }
                        })
                            // this.getList()
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "操作失败",
                                });
                            });
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选中一条数据!",
                    });
                }
            },
            opendilog() {
                this.dialogVisibleImport = true;
            },
            downloadbutton() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView()
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentScore/exportMB",
                    responseType: "blob",
                    headers: objPar,
                })
                    .then((res) => {
                        let blob = new Blob([res.data], {
                            type: "application/vnd.ms-excel",
                        });
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                        this.loadingView.close()
                        this.$message.success("操作成功")
                    })
                    .catch((err) => {
                    });
            },
            download() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView()
                if (this.search.gradeId != null && this.search.gradeId != "" && this.search.siteId != null && this.search.siteId != "") {
                    this.$axios({
                        method: "get",
                        url: "hnjxjy-core/eduStudentScore/exportCjD",
                        responseType: "blob",
                        headers: objPar,
                        params: {
                            info: this.search.name,
                            gradeId: this.search.gradeId,
                            semester: this.search.semester,
                            siteId: this.search.siteId,
                            stageId: this.search.stageId,
                            profId: this.search.profId,
                            courseId: this.search.courseId,
                            passFlag: this.search.passFlag
                        },
                    })
                        .then((res) => {
                            let blob = new Blob([res.data], {
                                type: "application/vnd.ms-excel",
                            });
                            let url = window.URL.createObjectURL(blob);
                            window.location.href = url;
                            this.loadingView.close()
                            this.$message.success("操作成功")
                        })
                        .catch((err) => {
                        });
                } else {
                    this.$message({
                        type: "error",
                        message: "请选择函授站和年级!",
                    });
                    this.loadingView.close()
                }

            },
            downloads() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView()
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentScore/exportCjD",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        info: this.search.name,
                        gradeId: this.search.gradeId,
                        semester: this.search.semester,
                        siteId: this.search.siteId,
                        stageId: this.search.stageId,
                        profId: this.search.profId,
                        courseId: this.search.courseId,
                        passFlag: this.search.passFlag
                    },
                })
                    .then((res) => {
                        let blob = new Blob([res.data], {
                            type: "application/vnd.ms-excel",
                        });
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                        this.loadingView.close()
                        this.$message.success("操作成功")
                    })
                    .catch((err) => {
                    });

            },
            downloadss() {
                // let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                // this.$axios({
                //   method: "get",
                //   url: "hnjxjy-core/eduScoreStudent/exportCJDB",
                //   responseType: "blob",
                //   headers: objPar,
                //   params:{
                //     info:this.search.name,
                //     gradeId:this.search.gradeId,
                //     semester:this.search.semester,
                //     siteId:this.search.siteId,
                //     stageId:this.search.stageId,
                //     profId:this.search.profId,
                //     courseId:this.search.courseId,
                //     passFlag:this.search.passFlag
                //   },
                // })
                //     .then((res) => {
                //       let blob = new Blob([res.data], {
                //         type: "application/vnd.ms-excel",
                //       });
                //       let url = window.URL.createObjectURL(blob);
                //       window.location.href = url;
                //     })
                //     .catch((err) => {
                //     });

            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView()
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduStudentScore/importData",
                    data: forms,
                    headers: objPar,
                })
                    .then((res) => {
                        this.loadingView.close()
                        this.dialogVisibleImport = false
                        this.$message.success("操作成功")
                    })
                    .catch((err) => {
                    });
            },
            handleSizeChange(val) {
                this.search.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.search.page = val;
                this.handleSearch();
            },
            handleSizeChange1(val) {
            },
            handleCurrentChange1(val) {
            },
            addexam() {
                this.dialogVisibleUpload = true;
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
