<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学生管理
            </div>
            <div class="search">

                <el-form inline label-width="120px" ref="search" :model="search">
                    <el-form-item>
                        <el-button icon="el-icon-bottom" type="primary" v-permission="'eduStudentInfo:export'"
                                   @click="download">导出
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" v-permission="'upload:uploadPictures'"
                                   @click="Batch()">批量上传照片
                        </el-button>
                        <el-button icon="el-icon-bottom" type="primary" v-permission="'upload:downloadPictures'"
                                   @click="downloadPhoto">批量下载照片
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" v-permission="'upload:uploadremark'"  @click="importTXS1">导入备注</el-button>
                        <el-button icon="el-icon-edit" v-permission="'sysEduStuInfo:resetPassword2'" type="primary"
                                   @click="editPassword">修改密码
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" v-permission="'upload:uploadBY'" @click="importTXS2">导入毕业生库</el-button>
                        <el-button icon="el-icon-upload2" type="primary" v-permission="'eduStudentInfo:importTXS'"
                                   @click="importTXS3">导入学生
                        </el-button>
                        <el-button icon="el-icon-edit-outline" v-permission="'set:setBY'" type="primary" @click="edit">设置毕业</el-button>
                        <el-button type="primary" v-permission="'eduStudentInfo:list'" @click="handleSearch">查询
                        </el-button>
                        <el-button type="primary" @click="rest(search)">重置</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/学号/证件号" prop="name">
                        <el-input
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="请输入内容"

                        ></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="contactPhone">
                        <el-input
                                v-model="search.contactPhone"
                                clearable
                                @input="() => (search.contactPhone = search.contactPhone.trim())"
                                placeholder="请输入内容"

                        ></el-input>
                    </el-form-item>
                    <el-form-item label="函授站" prop="siteId">
                        <el-select
                                clearable
                                v-model="search.siteId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in site"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select clearable v-model="search.gradeId" class="el-form-input-width" placeholder="请选择">
                            <el-option
                                    v-for="item in option"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select clearable v-model="search.stageId" class="el-form-input-width" placeholder="请选择">
                            <el-option
                                    v-for="item in eduStage"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select
                                clearable
                                v-model="search.profId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduProfession"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学籍状态" prop="rollStatus">
                        <el-select clearable v-model="search.rollStatus" class="el-form-input-width" placeholder="请选择">
                            <el-option
                                    v-for="item in this.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证件类型" prop="certificateType">
                        <el-select clearable v-model="search.certificateType" class="el-form-input-width"
                                   placeholder="请选择">
                            <el-option
                                    v-for="item in this.certificateType"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!--            <el-form-item label="报税状态" prop="taxStatus">-->

                    <!--              <el-select clearable v-model="search.taxStatus"    class="el-form-input-width" placeholder="请选择">-->
                    <!--                <el-option-->
                    <!--                    v-for="item in this.taxStatus"-->
                    <!--                    :key="item.value"-->
                    <!--                    :label="item.label"-->
                    <!--                    :value="item.value"-->
                    <!--                >-->
                    <!--                </el-option>-->
                    <!--              </el-select>-->
                    <!--            </el-form-item>-->
                </el-form>
            </div>
            <div class="table-wrap">
                <div style="text-align:left;margin-bottom:0.8rem">
                    <div class="backgroundColor" style="background: red;">退学</div>
                    <div class="backgroundColor" style="background: rgb(238, 173, 14);">休学</div>
                    <div class="backgroundColor" style="background: rgb(30, 144, 255);">毕学</div>
                    <div class="backgroundColor" style="background: rgb(255, 127, 0);">肄业</div>
                </div>
                <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                          size="medium"
                          height="480px"
                          max-height="480px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="150" type="index"></el-table-column>
                    <el-table-column label="学籍状态" prop="rollStatus" width="200">
                    </el-table-column>
                    <el-table-column label="姓名" prop="name" width="200">
                        <template slot-scope="scope">
                            <button style="color: #1890ff; background-color: white;  outline: none; border: none;  cursor: pointer"
                                    @click="action(scope.row.id)">{{scope.row.name}}
                            </button>
                        </template>
                    </el-table-column>
                    <el-table-column label="学号" prop="studentNo" width="200"></el-table-column>
                    <el-table-column label="性别" prop="sex" width="180"></el-table-column>
                    <el-table-column label="证件号" prop="certificateNo" width="200">
                    </el-table-column>

                    <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
                    <el-table-column label="考生号" prop="candidateNo" width="200"></el-table-column>
                    <el-table-column label="函授站" prop="siteName" width="200">
                    </el-table-column>
                    <el-table-column label="年级" prop="gradeName" width="180">
                    </el-table-column>
                    <el-table-column label="层次" prop="stageName" width="180">
                    </el-table-column>
                    <el-table-column label="专业" prop="profName" width="180">
                    </el-table-column>
                    <el-table-column label="专业代码" prop="profCode" width="200">
                    </el-table-column>
                    <el-table-column label="联系电话" prop="contactPhone" width="200">
                    </el-table-column>
                    <el-table-column label="证件类型" prop="certificateType" width="200">
                    </el-table-column>
                    <el-table-column label="入学时间" prop="createTime" width="180">
                    </el-table-column>
                    <el-table-column label="备注" prop="remark" width="200">
                    </el-table-column>
                    <el-table-column label="操作" width="180" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editorphoto(scope.row)" v-permission="'upload:photo'" type="primary"
                                       size="small">上传照片
                            </el-button>
                            <el-button v-permission="'eduStudentInfo:edit'" @click="editor(scope.row)" type="primary"
                                       size="small">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="search.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="search.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="this.total"
                    >
                    </el-pagination>
                </div>
            </div>

            <!-- 查看详情 -->
            <el-dialog
                    title="查看详情"
                    :visible.sync="dialogVisible1"
                    width="60%"
                    :before-close="dialogVisibleClose"
            >
                <!-- <div style="margin-bottom: 20px; text-align: left">
                          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
                          <el-button type="primary"
                            >设置休学</el-button
                          >
                        </div> -->

                <div style="display: block;width: 100%">
                    <div style="width: 70%;display: inline;">
                        <h4>基础信息</h4>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            姓名：{{ studentDetails.information.name }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            性别：<span v-if="studentDetails.information.sex === '0'">男</span><span
                                v-if="studentDetails.information.sex === '1'">女</span>
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            身份证号：{{ studentDetails.information.certificateNo}}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            文化程度：{{ studentDetails.information.educationS }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            政治面貌：{{studentDetails.information.politicalStatusS }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            电话： {{ studentDetails.information.contactPhone }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            通讯地址： {{ studentDetails.information.contactAddr }}
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <!--          <div style="width: 30%;display: inline;float: right;">-->
                    <!--            <img style="width: 100px; height: 140px;" :src="studentDetails.information.headerImg" alt=""/>-->
                    <!--          </div>-->

                </div>


                <div style="display: block;width: 100%">
                    <div style="width: 100%;display: inline;margin-top: 20px; margin-bottom: 20px">
                        <h4>学籍信息</h4>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            学号：{{ studentDetails.information.studentNo }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            函授站：{{ studentDetails.information.siteName }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            年级：{{ studentDetails.information.gradeName }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            层次：{{ studentDetails.information.stageName }}
                        </div>
                        <div style="padding: 0.4rem; display: inline-block;width:35%">
                            专业：{{studentDetails.information.profName }}
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div>
                    <h4>异动记录</h4>
                    <el-table :data="studentDetails.moveRecord" border style="width: 100%; margin-top: 5px"
                              size="medium"
                              max-height="220"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                    >
                        <el-table-column prop="changeTime" label="异动时间">
                        </el-table-column>
                        <el-table-column prop="orgProfName" label="专业">
                        </el-table-column>
                        <el-table-column prop="checkStatus" label="审核状态">
                        </el-table-column>
                        <el-table-column prop="newProfName" label="新专业">
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <h4 style="margin-top: 20px">选课记录</h4>
                    <el-table :data="studentDetails.courseRecord" border style="width: 100%; margin-top: 5px"
                              size="medium"
                              max-height="220"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                    >
                        <el-table-column prop="time" label="选课时间">
                        </el-table-column>
                        <el-table-column prop="courseName" label="课程名称">
                        </el-table-column>
                        <el-table-column prop="courseCode" label="课程编号">
                        </el-table-column>
                        <el-table-column prop="semester" label="学期">
                        </el-table-column>
                        <el-table-column prop="score" label="成绩">
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>

            <el-dialog
                    title="上传照片"
                    :visible.sync="dialogVisiblephoto"
                    :before-close="handleClose"
                    width="40%"
                    style="text-align: left">
                <div class="infinite-list" style="overflow:auto;margin-bottom: 20px;height: 300px;text-align: center">
                    <el-upload action="#" auto-upload ref="photoUpload" :http-request="uploadFilePhoto"
                               class="upload-demo" multiple :limit="1" :show-file-list="false"
                               style="text-align: center;margin-bottom: 15px">
                        <el-button size="small" type="primary">上传蓝底照片</el-button>
                    </el-upload>
                    <el-image :src="imageList.blueImage" style="width: 170px;height: 235px; text-align: center"
                              :preview-src-list="[imageList.blueImage]" v-show="imageList.blueImage"></el-image>
                    <el-upload action="#" auto-upload ref="photoUploads" :http-request="uploadFilePhotos"
                               class="upload-demo" multiple :limit="1" :show-file-list="false"
                               style="text-align: center;margin-bottom: 15px">
                        <el-button style="margin-top: 50px" v size="small" type="primary">上传白底照片</el-button>
                    </el-upload>
                    <el-image :src="imageList.whiteImage" style="width: 170px;height: 235px; text-align: center"
                              :preview-src-list="[imageList.whiteImage]" v-show="imageList.whiteImage"></el-image>
                </div>
                <div>
                    <el-row class="message">
                        电子证件照片要求：<br/>
                        1、报名照片格式：<span class="red">电子版且是jpg或png</span>;<br/>
                        2、报名照片尺寸要求：<span class="red">宽413像素×高626像素</span
                    >，近三个月内的本人正面免冠电子证件照片;<br/>
                        3、报名照片底色要求：彩色(蓝、白底色均可)。
                    </el-row>
                </div>
            </el-dialog>

            <!-- 编辑 -->
            <el-dialog
                    title="编辑"
                    :visible.sync="dialogVisible"
                    width="50%"
                    style="text-align: left"
            >
                <el-form
                        size="small"
                        :model="bo"
                        ref="ruleForm"
                        class="demo-ruleForm item"
                        label-width="120px"
                >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="所属函授站" prop="jobNo">
                                <el-select
                                        style="width: 200px"
                                        clearable
                                        v-model="bo.siteId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="item in site"
                                            :key="item.id"
                                            :label="item.siteName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属年级" prop="jobNo">
                                <el-select
                                        style="width: 200px"
                                        clearable
                                        v-model="bo.gradeId"
                                        class="el-form-input-width"
                                        placeholder="请选择">
                                    <el-option
                                            v-for="item in this.option" z
                                            :key="item.id"
                                            :label="item.gradeName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属层次" prop="jobNo">
                                <el-select
                                        style="width: 200px"
                                        clearable
                                        v-model="bo.stageId"
                                        class="el-form-input-width"
                                        placeholder="请选择">
                                    <el-option
                                            v-for="item in eduStage"
                                            :key="item.id"
                                            :label="item.stageName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属专业" prop="jobNo">
                                <el-select
                                        style="width: 200px"
                                        clearable
                                        v-model="bo.profId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="item in eduProfession"
                                            :key="item.id"
                                            :label="item.profName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.name"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.name = bo.name.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="学号" prop="studentNo">
                                <el-input
                                        style="width: 200px"
                                        disabled="disabled"
                                        v-model="bo.studentNo"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.name = bo.name.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="性别" prop="sex">
                                <el-select style="width: 200px" v-model="bo.sex" placeholder="请选择">
                                    <el-option
                                            v-for="item in sexOpt"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="民族" prop="clan">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.clan"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.clan = bo.clan.trim())"
                                ></el-input>

                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="出生日期" prop="birthday">
                                <el-date-picker
                                        style="width: 200px"
                                        v-model="bo.birthday"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="政治面貌" prop="politicalStatus">
                                <el-select style="width: 200px" v-model="bo.politicalStatus" placeholder="请选择">
                                    <el-option
                                            v-for="item in IdentityStatusType"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="文化程度" prop="education">
                                <el-select style="width: 200px" v-model="bo.education" placeholder="请选择">
                                    <el-option
                                            v-for="item in IdentityStatusTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="邮政编码" prop="postCode">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.postCode"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.postCode = bo.postCode.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="证件类型" prop="certificateType">
                                <el-select style="width: 200px" v-model="bo.certificateType" placeholder="请选择">
                                    <el-option
                                            v-for="item in roleTypeOpt"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="证件号码" prop="certificateNo">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.certificateNo"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.certificateNo = bo.certificateNo.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="移动电话" prop="contactPhone">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.contactPhone"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.contactPhone = bo.contactPhone.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="固定电话" prop="contactPhone">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.contactPhone"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.contactPhone = bo.contactPhone.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="通信地址" prop="contactAddr">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.contactAddr"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.contactAddr = bo.contactAddr.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="学籍状态" prop="rollStatus">
                                <el-select style="width: 200px" v-model="bo.rollStatus"
                                           class="el-form-input-width" placeholder="请选择">
                                    <el-option
                                            disabled="disabled"
                                            style="width: 200px"
                                            v-for="item in this.options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="录取时间" prop="matchTime">
                                <el-date-picker
                                        disabled="disabled"
                                        style="width: 200px"
                                        v-model="bo.matchTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="入学时间" prop="entranceTime">
                                <el-date-picker
                                        disabled="disabled"
                                        style="width: 200px"
                                        v-model="bo.entranceTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="email" prop="email">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.email"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.email = bo.email.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="入学前学校名称" prop="schoolName">
                                <el-input
                                        style="width: 200px"
                                        v-model="bo.schoolName"
                                        placeholder="请输入"
                                        class="el-form-input-width"
                                        @input="() => (bo.schoolName = bo.schoolName.trim())"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="入学前学校代码" prop="schoolCode">
                                <el-input
                                        style="width: 200px"
                                        :rows="2"
                                        placeholder="请输入内容"
                                        v-model="bo.schoolCode">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="入学前毕业年份" prop="graduateYear">
                                <el-input
                                        style="width: 200px"
                                        :rows="2"
                                        placeholder="请输入内容"
                                        v-model="bo.graduateYear">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="入学前毕业证书编号" prop="diplomaNo">
                                <el-input
                                        style="width: 200px"
                                        :rows="2"
                                        placeholder="请输入内容"
                                        v-model="bo.diplomaNo">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="备注" prop="remark">
                                <el-input
                                        style="width: 200px"
                                        :rows="2"
                                        placeholder="请输入内容"
                                        v-model="bo.remark">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('ruleForm')"
        >确 定</el-button
        >
      </span>
            </el-dialog>

            <!-- 导入 上传文件 -->
            <el-dialog title="批量上传图片" :visible.sync="dialogVisibleUpload" width="30%">
                <div style="margin-bottom: 20px;text-align:left">
        <span style="color: red"
        >提示：照片格式：jpg,照片命名：证件号码,只可导入zip格式的压缩文件</span
        >
                </div>
                <div style="text-align:left;margin-bottom:1rem">
                    <el-select v-model="photos.photoType" placeholder="请选择">
                        <el-option
                                v-for="item in photoType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div style="text-align:left;margin-bottom:1rem">
                    zip文件：
                    <el-upload
                            action="#"
                            :http-request="uploadFiles"
                            :before-upload="beforeUploads"
                            class="upload-demo"
                            multiple
                            :limit="3"
                            ref="upload"
                    >
                        上传照片：
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </div>
                <div style="margin-bottom: 1rem;text-align:left">
                    <div style="color: red;margin-bottom: 0.3rem;"
                    >照片命名规则（字母大小写不区分）
                    </div
                    >
                    <span style="color: red"
                    >请以证件号码命名,例如： 例如: 34081543234354.jpg</span
                    >
                </div>
            </el-dialog>

            <el-dialog title="修改密码" :visible.sync="dialogVisiblepassword" width="30%">
                <el-form :model="teacherForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="新密码" prop="pass">
                        <el-input style="width:200px;" type="password" v-model="teacherForm.newpassword"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input style="width:200px;" type="password" v-model="teacherForm.qdpassword"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm">提交</el-button>
                        <el-button @click="this.dialogVisiblepassword=false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>


            <!-- 导入 上传文件 -->
            <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads" width="30%">
                <div style="margin-bottom: 20px">
                    <el-button type="primary" @click="downloadbutton">下载模板</el-button>
                    <span style="color: red"
                    >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                    >
                </div>
                <el-upload
                        class="upload-demo"
                        drag
                        action="#"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        :before-upload="beforeUpload"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传excel文件，且不超过50M
                    </div>
                </el-upload>
            </el-dialog>

            <!-- 导入 上传文件 -->
            <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads2" width="30%">
                <div style="margin-bottom: 20px">
                    <el-button type="primary" @click="downloadbutton2">下载模板</el-button>
                    <span style="color: red"
                    >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                    >
                </div>
                <el-upload
                        class="upload-demo"
                        drag
                        action="#"
                        :show-file-list="false"
                        :http-request="uploadFile2"
                        :before-upload="beforeUpload"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传excel文件，且不超过50M
                    </div>
                </el-upload>
            </el-dialog>

            <!-- 导入 上传文件 -->
            <el-dialog title="上传文件" :visible.sync="dialogVisibleUploads3" width="30%">
                <div style="margin-bottom: 20px">
                    <el-button type="primary" v-permission="'eduStudentInfo:exportMB'" @click="downloadbutton3">下载模板
                    </el-button>
                    <span style="color: red"
                    >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                    >
                </div>
                <el-upload
                        class="upload-demo"
                        drag
                        action="#"
                        :show-file-list="false"
                        :http-request="uploadFile3"
                        :before-upload="beforeUpload"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传excel文件，且不超过50M
                    </div>
                </el-upload>
            </el-dialog>

        </div>
    </div>
</template>
<script>


    export default {

        created() {
            this.queryEduStage();
            this.queryEduSite();
            this.queryEduProfession();
            this.gradList();
            this.handleSearch();
        },
        data() {
            return {
                studentDetails: {
                    information: [],
                    moveRecord: [],
                    courseRecord: []
                },
                site: [],
                option: [],
                eduStage: [],
                eduProfession: [],
                photoUrl: [],
                imageList: {
                    whiteImage: '',
                    blueImage: ''
                },
                bo: {
                    id: "",
                    entranceTime: "",
                    matchTime: "",
                    siteId: "",
                    gradeId: "",
                    rollStatus: "",
                    stageId: "",
                    profId: "",
                    name: "",
                    sex: "",
                    clan: "",
                    birthday: "",
                    politicalStatus: "",
                    studentNo: "",
                    education: "",
                    postCode: "",
                    certificateType: "",
                    certificateNo: "",
                    contactPhone: "",
                    email: "",
                    schoolCode: "",
                    schoolName: "",
                    contactAddr: "",
                    graduateYear: "",
                    diplomaNo: "",
                    remark: "",
                },
                total: 0,
                pageNum: 1,
                num: 1,
                input: "",
                pageSize: 10,
                textarea: "",
                radio: '1',
                title: "",
                dialogVisibleUpload: false,
                dialogVisible: false,
                dialogVisibleUploads: false,
                dialogVisibleUploads2: false,
                dialogVisibleUploads3: false,
                dialogVisiblepassword: false,
                dialogVisiblephoto: false,
                dialogVisible1: false,
                search: {
                    name: "",
                    siteId: "",
                    gradeId: "",
                    stageId: "",
                    rollStatus: "",
                    certificateType: "",
                    contactPhone: "",
                    taxStatus: "",
                    profId: "",
                    page: 1,
                    pageSize: 10,
                },
                photofile: {
                    id: "",
                    photoType: "",

                },
                teacherForm: {
                    newpassword: "",
                    qdpassword: "",
                },
                tableChecked: [],


                tableData: [],
                versionList: [
                    {
                        value: 0,
                        label: "旧版",
                    },
                    {
                        value: 1,
                        label: "新版",
                    },
                ],
                IdentityStatusType: [
                    {
                        value: 0,
                        label: "共青团员",
                    }, {
                        value: 1,
                        label: "中共党员",
                    }, {
                        value: 2,
                        label: "群众",
                    }, {
                        value: 3,
                        label: "中共预备党员",
                    }, {
                        value: 4,
                        label: "民革会员",
                    }, {
                        value: 5,
                        label: "民盟会员",
                    }, {
                        value: 6,
                        label: "民建会员",
                    }, {
                        value: 7,
                        label: "民进会员",
                    }, {
                        value: 8,
                        label: "农工党党员",
                    }, {
                        value: 9,
                        label: "致公党党员",
                    }, {
                        value: 10,
                        label: "九三学社社员",
                    }, {
                        value: 11,
                        label: "台盟盟员",
                    }, {
                        value: 12,
                        label: "无党派民主人士",
                    },
                ],
                IdentityStatusTypes: [
                    {
                        value: 0,
                        label: "高中毕业",
                    },
                    {
                        value: 1,
                        label: "同等学历",
                    },
                    {
                        value: 2,
                        label: "中专技校",
                    },
                    {
                        value: 3,
                        label: "大专毕业",
                    },
                    {
                        value: 4,
                        label: "本科毕业",
                    },
                    {
                        value: 5,
                        label: "本科以上",
                    },
                ],
                roleTypeOpt: [
                    {
                        value: 0,
                        label: "身份证",
                    },
                    {
                        value: 1,
                        label: "军人证件",
                    },
                    {
                        value: 2,
                        label: "其他",
                    },
                ],
                currentPage4: 4,
                currentPage1: 4,
                sexOpt: [
                    {
                        value: 0,
                        label: "男",
                    },
                    {
                        value: 1,
                        label: "女",
                    },
                ],
                clans: [
                    {
                        value: 0,
                        label: "少数民族",
                    },
                    {
                        value: 1,
                        label: "汉族",
                    },
                ],
                options: [
                    {
                        value: 0,
                        label: "正式生",
                    },
                    {
                        value: 1,
                        label: "退学",
                    },
                    {
                        value: 2,
                        label: "休学",
                    },
                    {
                        value: 3,
                        label: "毕业",
                    },
                    {
                        value: 4,
                        label: "肄业",
                    },
                ],

                photos: {
                    photoType: ""
                },
                photoType: [
                    {
                        value: "1",
                        label: "蓝底照片",
                    },
                    {
                        value: "2",
                        label: "白底照片",
                    },
                    {
                        value: "3",
                        label: "证件照片",
                    },
                    {
                        value: "4",
                        label: "毕业证照片",
                    },
                ],

                taxStatus: [
                    {
                        value: "0",
                        label: "未填报",
                    },
                    {
                        value: "1",
                        label: "已填报",
                    },
                ],
                certificateType: [
                    {
                        value: "0",
                        label: "身份证",
                    },
                    {
                        value: "1",
                        label: "军人证件",
                    },
                    {
                        value: "2",
                        label: "其他",
                    },
                ],
                value: "",
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                value1: '',
            };
        },
        methods: {
            handleClose(done) {
                this.$confirm('确认关闭？').then(_ => {
                    this.$refs['photoUpload'].clearFiles(); // 清除历史文件列表
                    this.$refs['photoUploads'].clearFiles(); // 清除历史文件列表
                    done();
                }).catch(_ => {
                });
            },
            handleSearch() {
                this.getPermission('eduStudentCourse:list', () => {
                    this.openLoadingView()
                    let fromData = new FormData();
                    fromData.append("siteId", this.search.siteId);
                    fromData.append("gradeId", this.search.gradeId);
                    fromData.append("stageId", this.search.stageId);
                    fromData.append("profId", this.search.profId);
                    fromData.append("certificateType", this.search.certificateType);
                    fromData.append("rollStatus", this.search.rollStatus);
                    fromData.append("taxStatus", this.search.taxStatus);
                    fromData.append("info", this.search.name);
                    fromData.append("contactPhone", this.search.contactPhone);
                    this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list?page=" + this.search.page + "&pageSize=" + this.search.pageSize, fromData).then(resp => {
                        this.tableData = resp.data.data.list; //表格列表
                        this.total = resp.data.data.total;

                    })
                });
            },
            action(id) {
                this.getPermission('eduStudentCourse:list', () => {
                    this.dialogVisible1 = true
                    setTimeout(() => {
                        this.openLoadingView();
                        this.$axios_supermallData.get("/hnjxjy-core/eduStudentInfo/studentDetails?studentId=" + id)
                            .then(resp => {
                                if (null != resp) {
                                    this.studentDetails.information = resp.data[0];
                                    this.studentDetails.moveRecord = resp.data[1];
                                    this.studentDetails.courseRecord = resp.data[2];
                                    this.loadingView.close();
                                } else {
                                    this.$message.error("数据异常");
                                    this.loadingView.close();
                                }
                            }).catch(err => {
                            this.$message.error(err)
                            this.loadingView.close();
                        });
                    });

                }, 100);
            },
            dialogVisibleClose(done) {
                this.studentDetails = {
                    information: [],
                    moveRecord: [],
                    courseRecord: []
                }
                done();
            },
            //查询年级
            gradList() {
                this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.option = resp.data.data;
                    }
                })
            },
            //查询函授站下拉
            queryEduSite() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            //查询层次
            queryEduStage() {
                this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduStage = resp.data.data;
                    }
                })
            },
            //查询专业
            queryEduProfession() {
                this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduProfession = resp.data.data;
                    }
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            editor(row) {
                this.openLoadingView();
                this.$axios_supermall.get("hnjxjy-core/eduStudentInfo/selectById?id=" + row.id
                ).then(resp => {
                    this.dialogVisible = true;
                    this.bo = resp.data.data;
                    this.$set(this.bo, 'birthday', new Date(resp.data.data.birthday));
                })
            },
            editorphoto(row) {
                this.openLoadingView();
                this.$axios_supermall.get("hnjxjy-core/eduStudentIndex/selectEduStudentPhotoList?id=" + row.id
                ).then(resp => {
                    if (resp.data.code === 2000) {
                        // this.imageList.blueImage = 'https://repair-java.oss-cn-beijing.aliyuncs.com/images/1631522109245.jpg';
                        // this.imageList.whiteImage = 'https://repair-java.oss-cn-beijing.aliyuncs.com/images/1631522109245.jpg';
                        this.imageList.blueImage = '';
                        this.imageList.whiteImage = '';
                        resp.data.data.forEach(item => {
                            if (item.photoType == '1') {
                                this.imageList.blueImage = item.photoUrl;
                            } else if (item.photoType == '2') {
                                this.imageList.whiteImage = item.photoUrl;
                            }
                        });
                        this.dialogVisiblephoto = true;
                    }
                });
                this.photofile.id = row.id
            },


            handleSave() {
                if (this.bo.graduateYear != null && this.bo.graduateYear != "") {
                    let fromData = new FormData();
                    if (this.getIsNull(this.bo.siteId)) {
                        fromData.append("siteId", this.bo.siteId);
                    }
                    if (this.getIsNull(this.bo.gradeId)) {
                        fromData.append("gradeId", this.bo.gradeId);
                    }
                    if (this.getIsNull(this.bo.id)) {
                        fromData.append("id", this.bo.id);
                    }

                    if (this.getIsNull(this.bo.stageId)) {
                        fromData.append("stageId", this.bo.stageId);
                    }

                    if (this.getIsNull(this.bo.profId)) {
                        fromData.append("profId", this.bo.profId);
                    }
                    if (this.getIsNull(this.bo.name)) {
                        fromData.append("name", this.bo.name);
                    }
                    if (this.getIsNull(this.bo.sex)) {
                        fromData.append("sex", this.bo.sex);
                    }

                    if (this.getIsNull(this.bo.clan)) {
                        fromData.append("clan", this.bo.clan);
                    }

                    if (this.getIsNull(this.bo.birthday)) {
                        fromData.append("birthday", this.bo.birthday);
                    }

                    if (this.getIsNull(this.bo.politicalStatus)) {
                        fromData.append("politicalStatus", this.bo.politicalStatus);
                    }

                    if (this.getIsNull(this.bo.education)) {
                        fromData.append("education", this.bo.education);
                    }

                    if (this.getIsNull(this.bo.postCode)) {
                        fromData.append("postCode", this.bo.postCode);
                    }

                    if (this.getIsNull(this.bo.certificateType)) {
                        fromData.append("certificateType", this.bo.certificateType);
                    }

                    if (this.getIsNull(this.bo.certificateNo)) {
                        fromData.append("certificateNo", this.bo.certificateNo);
                    }

                    if (this.getIsNull(this.bo.contactPhone)) {
                        fromData.append("contactPhone", this.bo.contactPhone);
                    }

                    if (this.getIsNull(this.bo.email)) {
                        fromData.append("email", this.bo.email);
                    }

                    if (this.getIsNull(this.bo.schoolCode)) {
                        fromData.append("schoolCode", this.bo.schoolCode);
                    }

                    if (this.getIsNull(this.bo.schoolName)) {
                        fromData.append("schoolName", this.bo.schoolName);
                    }

                    if (this.getIsNull(this.bo.contactAddr)) {
                        fromData.append("contactAddr", this.bo.contactAddr);
                    }

                    if (this.getIsNull(this.bo.graduateYear)) {
                        fromData.append("graduateYear", this.bo.graduateYear);
                    }
                    if (this.getIsNull(this.bo.diplomaNo)) {
                        fromData.append("diplomaNo", this.bo.diplomaNo);
                    }
                    if (this.getIsNull(this.bo.remark)) {
                        fromData.append("remark", this.bo.remark);
                    }
                    this.openLoadingView();
                    this.$axios_supermall.post("hnjxjy-core/eduStudentInfo/edit", fromData).then(resp => {
                        if (resp.data.code == 2000) {
                            this.$message.success("操作完成");
                            this.handleSearch();
                            this.dialogVisible = false;
                        }
                    })
                } else {
                    this.$message.warning("入学前毕业年份不能为空")
                }
            },
            rest(form) {
                this.$refs.search.resetFields()
            },
            edit() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                    let array = [];
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        array.push(this.multipleSelection[i].id);
                    }
                    let arr = array.toString();
                    this.$axios({
                        url: "hnjxjy-core/eduStudentInfo/edit",
                        headers: this.loginList,
                        method: "post",
                        params: {
                            ids: arr,
                        },
                    }).then((res) => {
                        if (res.data.code == 2000) {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                            this.dialogreasons = false
                            this.handleSearch()
                        } else {
                            this.$message({
                                type: "info",
                                message: res.data.message,
                            });
                        }
                    })

                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "操作失败",
                            });
                        });
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一条!",
                    });
                }
            },
            editPassword() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    this.teacherForm = {}
                    this.dialogVisiblepassword = true
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一条!",
                    });
                }
            },
            importTXS1() {
                this.dialogVisibleUploads = true
            },
            importTXS2() {
                this.dialogVisibleUploads2 = true
            },
            importTXS3() {
                this.dialogVisibleUploads3 = true
            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },

            beforeUploads(file) {
                const isXls = file.type === "application/x-zip-compressed";
                if (!isXls) {
                    this.$message.error("上传文件只能是Zip格式！");
                    return false;
                }
                return isXls;
            },

            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduStudentInfo/importTXS",
                    data: forms,
                    headers: objPar,
                    params: {
                        status: 1
                    }
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message.success(res.data.message)
                        this.dialogVisibleUploads = false
                        this.handleSearch()
                    }
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            downloadbutton() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentInfo/exportMB",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        status: 1
                    }
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入备注模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            uploadFile2(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduStudentInfo/importTXS",
                    data: forms,
                    headers: objPar,
                    params: {
                        status: 2
                    }
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message.success("操作成功")
                        this.dialogVisibleUploads2 = false
                        this.handleSearch()
                    }
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            downloadbutton2() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentInfo/exportMB",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        status: 2
                    }
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入毕业生库模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            uploadFile3(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduStudentInfo/importTXS",
                    data: forms,
                    headers: objPar,
                    params: {
                        status: 3
                    }
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message.success("操作成功")
                        this.dialogVisibleUploads3 = false
                        this.handleSearch()
                    }
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            downloadbutton3() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentInfo/exportMB",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        status: 3
                    }
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入学生模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            // 导出
            download() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentInfo/export",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        "siteId": this.search.siteId,
                        "gradeId": this.search.gradeId,
                        "stageId": this.search.stageId,
                        "profId": this.search.profId,
                        "certificateType": this.search.certificateType,
                        "rollStatus": this.search.rollStatus,
                        "taxStatus": this.search.taxStatus,
                        "info": this.search.name,
                        "contactPhone": this.search.contactPhone,
                    },
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },

            // 批量下载
            downloadPhoto() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                    let array = [];
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        array.push(this.multipleSelection[i].id);
                    }
                    let arr = array.toString();
                    this.openLoadingView();
                    this.$axios({
                        method: "get",
                        url: "hnjxjy-core/upload/downloadPictures",
                        responseType: "blob",
                        headers: objPar,
                        params: {
                            studentIds: arr
                        },
                    }).then((res) => {
                        let blob = new Blob([res.data], {
                            type: "application/x-zip-compressed",
                        });
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                        this.$message.success("操作成功");
                        this.loadingView.close();
                    }).catch((err) => {
                        this.$message.error("操作失败");
                        this.loadingView.close();
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一项"
                    })
                }
            },

            submitForm() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].loginId);
                }
                let arr = array.toString();
                if (this.teacherForm.newpassword != null && this.teacherForm.newpassword != "" && this.teacherForm.newpassword.length >= 6 && this.teacherForm.qdpassword != null && this.teacherForm.qdpassword != "" && this.teacherForm.qdpassword.length >= 6) {
                    if (this.teacherForm.newpassword != this.teacherForm.qdpassword) {
                        this.$message({
                            type: "error",
                            message: "两次密码不一致!",
                        });
                    } else {
                        this.$axios({
                            url: "hnjxjy-admin/sysEduStuInfo/resetPassword2",
                            headers: this.loginList,
                            method: "post",
                            params: {
                                loginIds: arr,
                                newpassword: this.teacherForm.newpassword,
                                qdpassword: this.teacherForm.newpassword,
                            },
                        }).then((res) => {
                            if (res.data.code == 2000) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.dialogVisiblepassword = false
                                this.handleSearch()
                            }
                        })
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "请输入正确的密码格式!",
                    });
                }
            },
            uploadFiles(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("zipFile", fileDate);
                forms.append("photoType", this.photos.photoType)
                this.openLoadingView()
                this.$axios_supermallData.post("hnjxjy-core/upload/uploadPictures", forms).then((res) => {
                    if (res.data.code == 2000) {
                        this.loadingView.close();
                        this.dialogVisibleUpload = false
                        this.$message({
                            type: "success",
                            message: "操作成功"
                        })
                        this.handleSearch();
                    }
                })
            },

            uploadFilePhoto(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("studentId", this.photofile.id);
                forms.append("photoType", 1);
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/upload/photo",
                    data: forms,
                    headers: objPar,
                }).then((res) => {
                    if (res.data.code == 2000) {
                        // this.dialogVisiblephoto = false
                        this.$message({
                            type: "success",
                            message: "操作成功"
                        });
                        // this.handleSearch()
                        this.editorphoto({id: this.photofile.id});
                    }
                }).catch((err) => {
                });
            },

            uploadFilePhotos(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/upload/photo",
                    data: forms,
                    params: {
                        studentId: this.photofile.id,
                        photoType: 2,
                    },
                }).then((res) => {
                    if (res.data.code == 2000) {
                        // this.dialogVisiblephoto = false
                        this.$message({
                            type: "success",
                            message: "操作成功"
                        });
                        // this.handleSearch();
                        this.editorphoto({id: this.photofile.id});
                    }
                }).catch((err) => {
                });
            },
            handleSizeChange(val) {
                this.search.pageSize = val;

                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.search.page = val;
                this.handleSearch();
            },
            //批量上传图片
            Batch() {
                this.dialogVisibleUpload = true
                this.$refs['upload'].clearFiles() // 清除历史文件列表
            }
        },
    };
</script>
<style scoped lang="less">
    .message {
        margin-top: 20px;
        padding: 20px;

        .red {
            color: #e02020;
            font-weight: 500;
        }
    }
</style>
<style>
    .item .el-form-item__label {
        color: #64666A;
        font-weight: bold;
        text-align: left;
        padding-left: 25px;
    }

    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .backgroundColor {
        display: inline-block;
        padding: 0.3rem 0.5rem;
        color: #fff;
        margin-right: 1rem;
        font-size: 14px;
        border-radius: 6px;
    }

    .background {
        display: inline-block;
        padding: 0.5rem 1rem;
        color: #fff;
        margin-right: 1rem;
        font-size: 14px;
        border-radius: 6px;
    }


</style>
