<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                指导教师列表
            </div>
            <div class="search">
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduBigTeacherList:insert'" @click="addQueryBasic()">添加</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduBigTeacherList:deleted'" @click="deleteBasic">删除</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduBigTeacherList:export'" @click="downLoad">导出</el-button>
                    </el-form-item>
                    <div>
                        <div style="display: inline-block">
                            <el-form-item>
                                <el-input
                                        style="width: 500px"
                                        v-model="queryBO.nameOrCode"
                                        clearable
                                        @input="() => (queryBO.nameOrCode = queryBO.nameOrCode.trim())"
                                        placeholder="教师姓名/教师工号">
                                    <el-button v-permission="'eduBigTeacherList:list'" slot="append" icon="el-icon-search" @click="queryBasic"></el-button>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="main_contain">
                <span>共选中 {{multipleSelection.length}} 条数据</span>
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                            label="序号"
                    width="80">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="jobNo"
                            show-overflow-tooltip
                            label="教师工号">
                    </el-table-column>
                    <el-table-column
                            prop="sex"
                            label="性别">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            show-overflow-tooltip
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="profDir"
                            show-overflow-tooltip
                            label="专业方向">
                    </el-table-column>
                    <el-table-column
                            prop="teacherType"
                            label="教师类型">
                    </el-table-column>
                    <el-table-column
                            prop="jodTitle"
                            label="教师职称">
                    </el-table-column>
                    <el-table-column
                            prop="education"
                            label="教师学历">
                    </el-table-column>
                    <el-table-column
                            prop="jobCompany"
                            show-overflow-tooltip
                            label="工作单位">
                    </el-table-column>
                    <el-table-column
                            prop="phoneNum"
                            show-overflow-tooltip
                            label="移动电话">
                    </el-table-column>
                    <el-table-column
                            prop="telNum"
                            show-overflow-tooltip
                            label="固定电话">
                    </el-table-column>
                    <el-table-column
                            prop="qqNum"
                            show-overflow-tooltip
                            label="QQ号码">
                    </el-table-column>
                    <el-table-column
                            prop="email"
                            show-overflow-tooltip
                            label="邮箱">
                    </el-table-column>
                    <el-table-column
                            label="电子签名">
                      <template slot-scope="scope">
                        <div style="color: #1890ff;cursor: pointer;" @click="selectFJ(scope.row)">
                          电子签名
                        </div>
                      </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.pageNo"
                        :total="queryBO.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="添加指导教师"
                :visible.sync="dialog"
                width="60%">
            <div>
                <el-form inline label-width="80px">
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-circle-close" @click="dialog =false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAndClose">保存后关闭
                        </el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item>
                        <el-input
                                style="width: 500px"
                                v-model="addQueryBO.nameOrCode"
                                clearable
                                @input="() => (addQueryBO.nameOrCode = addQueryBO.nameOrCode.trim())"
                                placeholder="教师姓名/教师工号">
                            <el-button slot="append" icon="el-icon-search" @click="addQueryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-table
                        border
                        @selection-change="selectionChangeAdd"
                        :data="tableDataAdd"
                        style="width: 100%"
                        size="medium"
                        height="480px"
                        max-height="480px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="jobNo"
                            label="教师工号">
                    </el-table-column>
                    <el-table-column
                            prop="sex"
                            label="性别">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="profDir"
                            label="专业方向">
                    </el-table-column>
                    <el-table-column
                            prop="teacherType"
                            label="教师类型">
                    </el-table-column>
                    <el-table-column
                            prop="jodTitle"
                            label="教师职称">
                    </el-table-column>
                    <el-table-column
                            prop="jobCompany"
                            label="工作单位">
                    </el-table-column>
                    <el-table-column
                            prop="phoneNum"
                            label="移动电话">
                    </el-table-column>
                    <el-table-column
                            prop="email"
                            label="邮箱">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChangeAdd"
                        @current-change="handleCurrentChangeAdd"
                        :page-size="addQueryBO.pageSize"
                        :current-page="addQueryBO.pageNo"
                        :total="addQueryBO.total">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "teacherList",

        data() {
            return {
                tableData: [],
                multipleSelection: [],
                queryBO: {
                    pageNo: 1,
                    pageSize: 10,
                    nameOrCode: '',
                    status: 0,
                    total: 0
                },
                dialog: false,
                addQueryBO: {
                    nameOrCode: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0
                },
                tableDataAdd: [],
                multipleSelectionAdd: []
            }
        },
        methods: {
            //region 导出
            downLoad() {
              this.openLoadingView()
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateWorkBatch/exportAllGuidanceTeacherBySiteIdexportExcel",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '指导教师列表.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功")
                })
            },
            //endregion
            //region 删除
            deleteBasic() {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("请至少选择一项");
                    return;
                }
                let ids = '';
                this.multipleSelection.forEach(m => {
                    ids += m.id + ',';
                });
                ids = ids.substring(0, ids.length - 1);
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/updateGuidanceTeacher', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.multipleSelection = [];
                        this.queryBasic();
                        this.$message.success("操作成功")
                    }else{
                      this.$message.error("操作失败")
                    }
                }) .catch((response) => {
                  this.$message({
                    type: "info",
                    message: response.data.message,
                  });
                });
            },
            //endregion
            //region 添加后关闭
            addAndClose() {
                if (this.multipleSelectionAdd == null || this.multipleSelectionAdd.length <= 0) {
                    this.$message.warning("请至少选择一项");
                    return;
                }
                let ids = '';
                this.multipleSelectionAdd.forEach(m => {
                    ids += m.id + ',';
                });
                ids = ids.substring(0, ids.length - 1);
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/addGuidanceTeacher', {ids: ids}).then(response => {
                    if (response.data.code === 2000) {
                        this.multipleSelectionAdd = [];
                        this.dialog = false;
                        this.queryBasic();
                    }
                })
            },
            //endregion
            //region 添加dialog查询
            addQueryBasic() {
              this.openLoadingView()
                let bo = 'page=' + this.addQueryBO.pageNo + '&pageSize=' + this.addQueryBO.pageSize + '&status=1';
                if (this.addQueryBO.nameOrCode != null && this.addQueryBO.nameOrCode != '') {
                    bo += '&nameOrCode=' + this.addQueryBO.nameOrCode;
                }
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId?' + bo).then(response => {
                    if (response.data.code === 2000) {
                      this.dialog=true
                        this.tableDataAdd = response.data.data.list;
                        this.addQueryBO.total = response.data.data.total;
                    }
                });
            },
          selectFJ(row){
            if(row.elecSign!=null&&row.elecSign!=""){
              window.open(row.elecSign)
            }else{
              this.$message({
                type: "error",
                message: "签名为空!",
              });
            }
          },
            //endregion
            //region 主页面基础查询
            queryBasic() {
              this.getPermission('eduBigTeacherList:list', () => {
              this.openLoadingView()
                let bo = 'page=' + this.queryBO.pageNo + '&pageSize=' + this.queryBO.pageSize + '&status=0';
                if (this.queryBO.nameOrCode != null && this.queryBO.nameOrCode != '') {
                    bo += '&nameOrCode=' + this.queryBO.nameOrCode;
                }
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.queryBO.total = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            //region 主页面表格选中事件
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.pageNo = val;
                this.queryBasic();
            },
            //endregion
            // region Add表格选中事件
            selectionChangeAdd(val) {
                this.multipleSelectionAdd = val;
            },
            //endregion
            //region 分页size
            handleSizeChangeAdd(val) {
                this.addQueryBO.pageSize = val;
                this.addQueryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChangeAdd(val) {
                this.addQueryBO.pageNo = val;
                this.addQueryBasic();
            }
            //endregion
        },
        mounted() {
            this.queryBasic();//主页面基础查询
        }

    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
