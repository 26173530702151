<template>
  <div>
    <div >
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        报到列表
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="110px" inline>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button type="primary" v-permission="'eduEnrolStudent:editPublishStatus2'" @click="report">报道</el-button>
            <el-button type="primary" v-permission="'eduEnrolStudent:editPublishStatus2'" @click="reports">取消报道</el-button>
            <el-button type="primary" @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
            <div style="display: inline-block">
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 220px"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="siteId" label="函授站" >
              <el-select
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="gradeId" label="年级" >
              <el-select clearable v-model="search.gradeId"   class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="stageId" label="层次" >
              <el-select clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="profId" label="专业" >
              <el-select
                  style="width: 220px"
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="comingStatus" label="是否报道" >
              <el-select
                  clearable
                  v-model="search.comingStatus"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData"  border @selection-change="handleSelectionChange"
                  size="medium"
                  height="520px"
                  max-height="520px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column label="序号" width="110" type="index"></el-table-column>
          <el-table-column label="姓名"  prop="name">
          </el-table-column>
          <el-table-column label="学号" show-overflow-tooltip  prop="studentNo">
          </el-table-column>
          <el-table-column label="证件号" show-overflow-tooltip prop="candidateNo"> </el-table-column>
          <el-table-column label="函授站" show-overflow-tooltip prop="siteCode" > </el-table-column>
          <el-table-column label="年级" show-overflow-tooltip prop="gradeName" >
          </el-table-column>

          <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
          <el-table-column label="层次" prop="stageName" width="230"> </el-table-column>
          <el-table-column label="专业" prop="profName" >
          </el-table-column>
          <el-table-column label="联系电话" show-overflow-tooltip prop="contactPhone">
          </el-table-column>
          <el-table-column label="入学时间" show-overflow-tooltip prop="entrance_time">
          </el-table-column>
          <el-table-column label="学籍状态" prop="rollStatus"> </el-table-column>
          <el-table-column label="是否报到" prop="comingStatus"> </el-table-column>
          <!-- <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >绑定角色</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
        <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary">下载模板</el-button>
        <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :show-file-list="false"
        :http-request="uploadFile"
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      eduStage:[],
      eduProfession:[],
      option:[],
      total: 0,
      pageNum: 1,
      num:1,
      input:"",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload:false,
      dialogVisible:false,
      radio:'1',
      title: "",
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        comingStatus: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      status:0,
      tableChecked: [],
      tableData: [
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "未报到",
        },
        {
          value: "1",
          label: "已报到",
        },
      ],
      value: "",
    };
  },
  methods: {
    handleSearch() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("info",this.search.name);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("comingStatus",this.search.comingStatus);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      fromData.append("status","2");
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/listXj",fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    report(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
          this.$axios({
            url: "hnjxjy-core/eduEnrolStudent/editPublishStatus2",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
              status:1
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.handleSearch()
          })

              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
      }else {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    reports(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduEnrolStudent/editPublishStatus2",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            status:0
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.handleSearch()
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    //导入
    uploader(){
        this.dialogVisibleUpload=true
    },
    generation(){
        this.dialogVisible=true
    },
     beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(){

    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content{
  text-align: left;
}
</style>
