<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                分配教师
            </div>
            <div class="search">
                <el-form label-width="80px" inline>
                    <el-form-item>
                        <el-input v-model="queryByPageBO.batchNameOrCode" placeholder="批次名称/批次编码" style="width: 600px">
                            <el-button slot="append" @click="queryBasic" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduBigAssignTeachers:set'" @click="setting">设置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        :data="tableData"
                        border
                        @selection-change="selectionChange"
                        style="width: 100%"
                        size="medium"
                        height="690px"
                        max-height="690px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            fixed>
                    </el-table-column>
                    <el-table-column
                            type="index"
                            fixed
                            label="序号"
                            width="80px"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="batchCode"
                            fixed
                            show-overflow-tooltip
                            width="150px"
                            label="毕业批次编号">
                    </el-table-column>
                    <el-table-column
                            prop="teacherName"
                            fixed
                            width="150px"
                            label="老师名称">
                    </el-table-column>
                  <el-table-column
                      prop="batchName"
                      fixed
                      show-overflow-tooltip
                      width="150px"
                      label="批次名称">
                  </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="projectStartTime"
                            label="毕设开始时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="projectEndTime"
                            label="毕设结束时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="questionStartTime"
                            label="开题开始时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="questionEndTime"
                            label="开题结束时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="paperStartTime"
                            label="论文开始时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="paperEndTime"
                            label="论文结束时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="freeStartTime"
                            label="免试开始时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="freeEndTime"
                            label="免试结束时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="scoreStartTime"
                            label="成绩上报开始时间">
                    </el-table-column>
                    <el-table-column
                            width="150px"
                            show-overflow-tooltip
                            prop="scoreEndTime"
                            label="成绩上报结束时间">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            width="150px"-->
<!--                            prop="assessStartTime"-->
<!--                            label="学生评价开始时间">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                            width="150px"-->
<!--                            prop="assessEndTime"-->
<!--                            label="学生评价结束时间">-->
<!--                    </el-table-column>-->
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryByPageBO.pageSize"
                        :current-page="queryByPageBO.pageNo"
                        :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="设置指导教师"
                :visible.sync="dialogVisible"
                width="60%">
            <div>
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible = false">关闭
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-folder-add" type="primary" @click="saveAndClose">保存后关闭</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item>
                        <el-input v-model="queryTeacherBO.nameOrCode" placeholder="教师姓名/教师工号">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryTeacher"></el-button>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-table
                        border
                        ref="teachTable"
                        :data="teacherList"
                        style="width: 100%"
                        size="medium"
                        height="530px"
                        max-height="530px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                  <el-table-column fixed label="选择">
                    <template slot-scope="scope">
                      <el-radio
                          :label="scope.row.id"
                          v-model="currentRow"
                          style="color: #fff; padding-left: 10px; margin-right: -25px"
                      ></el-radio>
                    </template>
                  </el-table-column>
                    <el-table-column
                            fixed
                            prop="jobNo"
                            label="教师工号">
                    </el-table-column>
                    <el-table-column
                            fixed
                            prop="name"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="sex"
                            label="性别">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="profDir"
                            label="专业方向">
                    </el-table-column>
                    <el-table-column
                            prop="teacherType"
                            label="教师类型">
                    </el-table-column>
                    <el-table-column
                            prop="jodTitle"
                            label="教师职称">
                    </el-table-column>
                    <el-table-column
                            prop="jobCompany"
                            label="工作单位">
                    </el-table-column>
                    <el-table-column
                            prop="phoneNum"
                            label="移动电话">
                    </el-table-column>
                    <el-table-column
                            prop="email"
                            label="邮箱">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "assignTeachers",

        data() {
            return {
              currentRow: null,
                queryByPageBO: {
                    page: 1,
                    pageSize: 10,
                    batchNameOrCode: ''
                },
                total: 0,
                tableData: [],
                multipleSelection: [],
                dialogVisible: false,
                queryTeacherBO: {
                    page: 1,
                    pageSize: 10,
                    status: 0,
                    nameOrCode: ''
                },
                teacherList: [],
                teacherMultiple: {},
                teacherTotal: 0
            }
        },
        methods: {
            //region 保存后关闭
            saveAndClose() {
              if (this.currentRow == null) {
                this.$message.warning("请选择教师");
                return;
              }
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("请至少选中一行批次");
                    return;
                }
              let bo = {
                teacherId: this.currentRow,
                batchIds: ''
              };
              this.multipleSelection.forEach(m => {
                bo.batchIds += (m.id + ',');
              });
                bo.batchIds = bo.batchIds.substring(0, bo.batchIds.length - 1);
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateDesignTeaStat/updateByPrimaryKeySelective', bo).then(response => {
                    if (response.data.code === 2000) {
                        this.multipleSelection = [];
                        this.teacherMultiple = [];
                        this.dialogVisible = false;
                        this.$message.success("操作成功")
                        this.queryBasic();
                    }
                })
            },
            //endregion
            //region 老师表格变动
            teacherChange(val) {
                this.teacherMultiple = val;
            },
            //endregion
            //region 查询教师
            queryTeacher() {
                let bo = 'page=' + this.queryTeacherBO.page + '&pageSize=' + this.queryTeacherBO.pageSize + '&status=' + this.queryTeacherBO.status;
                if (this.queryTeacherBO.nameOrCode != null && this.queryTeacherBO.nameOrCode != '') {
                    bo += '&nameOrCode=' + this.queryTeacherBO.nameOrCode;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.teacherList = response.data.data.list;
                        this.teacherTotal = response.data.data.total;
                    }
                })
            },
            //endregion
            //region 设置
            setting() {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("请至少选择一列");
                    return;
                }
              this.openLoadingView();

              let bo = 'page=' + this.queryTeacherBO.page + '&pageSize=' + this.queryTeacherBO.pageSize + '&status=' + this.queryTeacherBO.status;
              if (this.queryTeacherBO.nameOrCode != null && this.queryTeacherBO.nameOrCode != '') {
                bo += '&nameOrCode=' + this.queryTeacherBO.nameOrCode;
              }

              this.$axios_supermall.get('/hnjxjy-core/eduGraduateWorkBatch/queryAllGuidanceTeacherBySiteId?' + bo).then(response => {
                if (response.data.code === 2000) {
                  this.dialogVisible = true;
                  this.teacherList = response.data.data.list;
                  this.teacherTotal = response.data.data.total;
                }
              })
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduBigAssignTeachers:list', () => {
                let bo = 'page=' + this.queryByPageBO.page + '&pageSize=' + this.queryByPageBO.pageSize;
                if (this.queryByPageBO.batchNameOrCode != null && this.queryByPageBO.batchNameOrCode != '') {
                    bo += '&batchNameOrCode=' + this.queryByPageBO.batchNameOrCode;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDesignTeaStat/queryByPage?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.total = response.data.data.total;
                        this.tableData = response.data.data.list;
                    }
                })
                });
            },
            //endregion
            //region 主页面table选择变化
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryByPageBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryByPageBO.pageNo = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .table_header .el-checkbox {
        display: none !important
    }
</style>
