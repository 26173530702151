<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学生成绩统计
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" v-permission="'eduStudentScoreStat:export'" type="primary" @click="edition()"
              >导出成绩校队单</el-button
            >
            <el-button type="primary" v-permission="'eduStudentScoreStat:list'" @click="handleSearch">查询</el-button>
            <el-button @click="reset()" >重置</el-button>
          </el-form-item>
          <div style="display:flex;">
            <div style=" display: inline-block">
              <el-form-item label="姓名/学号" style="margin-bottom: 0">
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="函授站名称"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
            </div>
            <div style=" display: inline-block">
              <el-form-item label="年级" style="margin-bottom: 0;margin-left: 20px">

                <el-select clearable v-model="search.gradeId" class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="item in this.options"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style=" display: inline-block">
              <el-form-item label="层次" style="margin-bottom: 0;margin-left: 20px">

                <el-select v-model="search.stageId" placeholder="请选择">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display: inline-block">
              <el-form-item label="专业"  style="margin-bottom: 0;margin-left: 20px">

                <el-select
                    clearable
                    v-model="search.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                    style="width: 205px;"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" prop="id"></el-table-column> -->
          <el-table-column type="index" label="序号" width="100" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="240" align="center">
          </el-table-column>
          <el-table-column prop="studentNo" label="学号" width="240" align="center">
          </el-table-column>
          <el-table-column prop="siteName" label="站点" width="240" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" width="240" align="center">
          </el-table-column>
          <el-table-column prop="stageName" label="层次" width="240" align="center">
          </el-table-column>
          <el-table-column prop="profName" label="专业" width="240" align="center">
          </el-table-column>
<!--          <el-table-column prop="stuCouId" label="stuCouId" width="180">-->
<!--          </el-table-column>-->
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <div
                style="color: #46a6ff; cursor: pointer"
                @click="editor(scope.row)"
              >
                查看详情
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 成绩单 -->
      <el-dialog
        title="成绩单"
        :visible.sync="dialogVisibleUpload1"
        width="60%"
      >
        <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary" @click="dialogVisibleUpload1 = false"
            >关闭</el-button
          >
        </div>
        <div style="text-align: left; margin-bottom: 1rem">
          <el-table :data="versionList" :model="versionList" border style="width: 100%">
            <el-table-column  type="index" label="序号" width="100">
            </el-table-column>
            <el-table-column v-model="versionList.courseName" prop="courseName" label="课程名称" width="180">
            </el-table-column>
            <el-table-column v-model="versionList.courseName" prop="courseCode" label="课程编号" width="180">
            </el-table-column>
            <el-table-column prop="semester" label="学期" width="180">
            </el-table-column>
            <el-table-column prop="passFlag" label="是否通过" width="180">
            </el-table-column>
            <el-table-column prop="offlineScore" label="离线成绩" width="180">
              <template slot-scope="scope">
                <div v-if="null == scope.row.offlineScore">
                  暂无成绩
                </div>
                <div v-else>
                  {{scope.row.offlineScore}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="mutualScore" label="在线交互" width="180">
              <template slot-scope="scope">
                <div v-if="null == scope.row.mutualScore">
                  暂无成绩
                </div>
                <div v-else>
                  {{scope.row.mutualScore}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="workScore" label="在线作业" width="180">
            </el-table-column>
            <el-table-column prop="freeScore" label="免试成绩" width="180">
              <template slot-scope="scope">
                <div v-if="null == scope.row.freeScore">
                  暂无成绩
                </div>
                <div v-else>
                  {{scope.row.freeScore}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="examScore" label="考试成绩" width="180">
            </el-table-column>
            <el-table-column prop="overallScore" label="总评成绩" width="180">
            </el-table-column>
            <el-table-column prop="publishOverrall" label="发布状态" width="180"> </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
  },
  data() {
    return {
      eduProfession: [],
      eduStage: [],
      total: 0,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      checked: false,
      num: 1,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
        gradeId:"",
        stageId:"",
        profId:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [
      ],
      site:[],
      versionList: [],

      currentPage4: 4,
      currentPage1: 4,
      options: [

      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      this.$axios_supermall.get("/hnjxjy-core/eduStudentScoreStat/list?page=" +this.search.page+
          "&pageSize="+this.search.pageSize+
          "&info="+this.search.name+
          "&gradeId="+this.search.gradeId+
          "&stageId="+this.search.stageId+
          "&profId="+this.search.profId

      ).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    editor(row) {
      this.dialogVisibleUpload1=true
      this.versionList=[]
      this.$axios_supermall.get("/hnjxjy-core/eduStudentScore/getOne?id="+row.stuCouId
      ).then(resp=>{
        this.versionList.push(resp.data.data)
      })
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentScoreStat/export",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    editors() {
      this.dialogVisibleUpload1 = true;
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile() {},
    reset() {
      this.search.name = "";
      this.search.gradeId = "";
      this.search.stageId = "";
      this.search.profId = "";
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
