<template>
  <div>
    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        未生成学号列表
      </div>
      <div class="search">
        <el-form :model="search" ref="search" class="demo-ruleForm" inline>

          <el-form-item>
            <el-button icon="el-icon-top" type="primary" v-permission="'eduEnrolStudent:importWscXh'" @click="uploader()">导入</el-button>
            <el-button icon="el-icon-edit" type="primary" v-permission="'eduStudentNoGeneraRule:edit'" @click="generation()">设置学号生成规则</el-button>
            <el-button icon="el-icon-edit" type="primary" v-permission="'eduStudentNoGeneraRule:generaStudentNo'" @click="generaStudentNo()">自动生成学号</el-button>
            <el-button icon="el-icon-bottom" type="primary" @click="download">导出
            </el-button
            >
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
            <div style=" display: inline-block">
              <el-form-item prop="name" label="姓名/证件号" style="">
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/证件号"
                    style="width: 250px"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="siteId" label="函授站" style="margin-bottom:0;margin-left:2rem">
              <el-select
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="gradeId" label="年级" style="margin-bottom:0;margin-left:2rem">
              <el-select clearable v-model="search.gradeId" class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="stageId" label="层次" style="margin-bottom:0;margin-left:2rem">
              <el-select clearable v-model="search.stageId" class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="profId" label="专业" style="margin-bottom:0;margin-left:3rem">
              <el-select
                  style="width: 250px"
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column label="序号" width="150" type="index"></el-table-column>
          <el-table-column label="姓名" width="200" prop="name">
          </el-table-column>
          <el-table-column label="证件号" prop="certificateNo" width="200"></el-table-column>
          <el-table-column label="函授站" prop="siteName" width="150"></el-table-column>
          <el-table-column label="年级" prop="gradeName" width="150">
          </el-table-column>

          <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
          <el-table-column label="层次" prop="stageName" width="230"></el-table-column>
          <el-table-column label="专业" prop="profName" width="150">
          </el-table-column>
          <el-table-column label="学籍状态" prop="rollStatus"></el-table-column>
          <el-table-column label="注册状态" prop="registerStatus"></el-table-column>
          <!-- <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >绑定角色</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <!-- 设置学号生成规则 -->
      <el-dialog
          title="设置学号生成规则"
          :visible.sync="dialogVisible"
          width="55%"
          style="text-align: left"
          :before-close="beforeClose"
      >
        <div style="margin-bottom:1rem">
          学籍状态:
          <el-select v-model="teacherForm.rollStatus" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom:1rem">
          <div style="display:inline-block;width:30%">
            年级代码[N]:
            <el-select style="width: 150px" @change="generateCourse(1)" clearable v-model="teacherForm.yearCode"
                       class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.optiones"
                  :key="item.id"
                  :label="item.gradeCode"
                  :value="item.gradeCode"
              >
              </el-option>
            </el-select>
          </div>
          开始位置：
          <el-input-number v-model="teacherForm.yearStartPosition" @change="generateCourse(1)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          代码长度：
          <el-input-number v-model="teacherForm.yearCodeLength" @change="generateCourse(1)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          <p style="padding:0.2rem 0.4rem;background-color: #e8f4ff;border-color: #d1e9ff;color: #1890ff;display:inline-block;margin-left:1rem">
            预览：</p>
          <h3 style="display:inline-block;">{{ previewTeacherForm.yearCode }}</h3>
        </div>
        <div style="margin-bottom:1rem">
          <div style="display:inline-block;width:30%">
            层次代码[C]:
            <el-select style="width: 150px" @change="generateCourse(2)" clearable v-model="teacherForm.stageCode"
                       class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.eduStages"
                  :key="item.id"
                  :label="item.stageCode"
                  :value="item.stageCode"
              >
              </el-option>
            </el-select>
          </div>

          <div style="display:inline-block;">
            开始位置：
            <el-input-number v-model="teacherForm.stageStartPosition" @change="generateCourse(2)" :min="1" :max="10"
                             label="描述文字"></el-input-number>
          </div>

          代码长度：
          <el-input-number v-model="teacherForm.stageCodeLength" @change="generateCourse(2)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          <p style="padding:0.2rem 0.4rem;background-color: #e8f4ff;border-color: #d1e9ff;color: #1890ff;display:inline-block;margin-left:1rem">
            预览：</p>
          <h3 style="display:inline-block;">{{ previewTeacherForm.stageCode }}</h3>
        </div>
        <div style="margin-bottom:1rem">
          <div style="display:inline-block;width:30%">
            专业代码[Z]:
            <el-select style="width: 150px" @change="generateCourse(3)" clearable v-model="teacherForm.profCode"
                       class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.eduProfessions"
                  :key="item.id"
                  :label="item.profCode"
                  :value="item.profCode"
              >
              </el-option>
            </el-select>
          </div>

          开始位置：
          <el-input-number v-model="teacherForm.profStartPosition" @change="generateCourse(3)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          代码长度：
          <el-input-number v-model="teacherForm.profCodeLength" @change="generateCourse(3)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          <p style="padding:0.2rem 0.4rem;background-color: #e8f4ff;border-color: #d1e9ff;color: #1890ff;display:inline-block;margin-left:1rem">
            预览：</p>
          <h3 style="display:inline-block;">{{ previewTeacherForm.profCode }}</h3>
        </div>
        <div style="margin-bottom:1rem">
          <div style="display:inline-block;width:30%">
            函授站代码[J]:
            <el-select style="width: 150px" @change="generateCourse(4)" clearable v-model="teacherForm.siteCode"
                       class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.sites"
                  :key="item.id"
                  :label="item.siteCode"
                  :value="item.siteCode"
              >
              </el-option>
            </el-select>
          </div>
          开始位置：
          <el-input-number v-model="teacherForm.siteStartPosition" @change="generateCourse(4)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          代码长度：
          <el-input-number v-model="teacherForm.siteCodeLength" @change="generateCourse(4)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          <p style="padding:0.2rem 0.4rem;background-color: #e8f4ff;border-color: #d1e9ff;color: #1890ff;display:inline-block;margin-left:1rem">
            预览：</p>
          <h3 style="display:inline-block;">{{ previewTeacherForm.siteCode }}</h3>
        </div>
        <div style="margin-bottom:1rem">
          <div style="display:inline-block;width:30%">
            序号代码[X]:{{ teacherForm.serialNumCode }}
          </div>
          开始位置：
          <el-input-number v-model="teacherForm.serialStartPosition" @change="generateCourse(5)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          代码长度：
          <el-input-number v-model="teacherForm.serialCodeLength" @change="generateCourse(5)" :min="1" :max="10"
                           label="描述文字"></el-input-number>
          <p style="padding:0.2rem 0.4rem;background-color: #e8f4ff;border-color: #d1e9ff;color: #1890ff;display:inline-block;margin-left:1rem">
            预览：</p>
          <h3 style="display:inline-block;">{{ previewTeacherForm.serialNumCode }}</h3>
        </div>
        <div style="margin-bottom:1rem">
          提示： <p
            style="padding:0.2rem 0.4rem;background-color: #ffeded;border-color: #ffdbdb;color: #ff4949;display:inline-block;margin-left:1rem">
          [N]:年级代码 [C]:层次代码 [Z]:专业代码 [J]:函授站代码 (例如: [N][C][Z][J]) 序号代码默认添加在末尾</p>
        </div>
        <div style="margin-bottom:1rem">
          生成规则：
          <el-input v-model="teacherForm.generateRule" placeholder="请输入内容" style="width:50%"></el-input>
          [X]
        </div>
        <div style="margin-bottom:1rem">
          <el-button type="primary" @click="previewStudentNo">预览学号</el-button>
          <h3 style="display:inline-block;margin-left:2rem">{{ previewTeacherForm.studentNo }}</h3>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="handleSave()">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {REG} from "../../tools/const";

export default {
  created() {
    this.handleSearch();
    this.queryEduStage();
    this.queryEduStages();
    this.queryEduProfession();
    this.queryEduProfessions();
    this.queryEduSite();
    this.queryEduSites();
    this.gradList();
    this.gradLists();
    // this.queryGetRule()
  },
  data() {
    return {
      site: [],
      sites: [],
      eduStage: [],
      eduStages: [],
      eduProfession: [],
      eduProfessions: [],
      option: [],
      optiones: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      radio: '1',
      title: "",
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        page: 1,
        pageSize: 10,
      },
      previewTeacherForm: {
        yearCode: null, //年份代码
        stageCode: "1", //层次代码
        profCode: "1", //专业代码
        siteCode: "1", //函授站代码
        serialNumCode: "1", //序号代码
        studentNo: null,
      },
      teacherForm: {
        id: null,
        rollStatus: 0, //学籍状态 0正式生 1退学 2休学 3毕业 4肄业
        yearCode: null, //年份代码
        stageCode: null, //层次代码
        profCode: null, //专业代码
        siteCode: null, //函授站代码
        serialNumCode: null, //序号代码
        yearStartPosition: 1, //年份代码开始位置
        stageStartPosition: 1, //层次代码开始位置
        profStartPosition: 1, //专业代码开始位置
        siteStartPosition: 1, //函授站代码开始位置
        serialStartPosition: 1, //序号代码开始值
        yearCodeLength: 1, //年份代码长度
        stageCodeLength: 1, //层次代码长度
        profCodeLength: 1, //专业代码长度
        siteCodeLength: 1, //函授站代码长度
        serialCodeLength: 1, //序号代码长度
        generateRule: "[N][C][Z][J]", //规则
        defFlag: 0,
        createTime: null,
        updateTime: null,
        siteId: null
      },
      tableChecked: [],
      tableData: [],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: 0,
          label: "正式生",
        },
        {
          value: 1,
          label: "退学",
        },
        {
          value: 2,
          label: "休学",
        },
        {
          value: 3,
          label: "毕业",
        },
        {
          value: 4,
          label: "肆业",
        },
      ]
    };
  },
  methods: {
    handleSearch() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId", this.search.siteId);
      fromData.append("info", this.search.name);
      fromData.append("gradeId", this.search.gradeId);
      fromData.append("stageId", this.search.stageId);
      fromData.append("profId", this.search.profId);
      fromData.append("page", this.search.page);
      fromData.append("pageSize", this.search.pageSize);
      fromData.append("status", "1");
      // fromData.append("status",2);
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/listXj", fromData).then(resp => {
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
    },

    //查询年级
    gradLists() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
      if (resp.data.code === 2000) {
        this.optiones = resp.data.data;
      }
      })
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
      if (resp.data.code === 2000) {
        this.option = resp.data.data;
      }
      })

    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code === 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfessions() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code === 2000) {
          this.eduProfessions = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSites() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code === 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStages() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code === 2000) {
          this.eduStages = resp.data.data;
        }
      })
    },
    //查询规则
    queryGetRule() {
      this.$axios_supermall.post("/hnjxjy-core/eduStudentNoGeneraRule/getRule").then(resp => {
        if (resp.data.code == 2000) {
          if (resp.data.data != null) {
            this.teacherForm = resp.data.data;
            this.generateCourse(1);
            this.generateCourse(2);
            this.generateCourse(3);
            this.generateCourse(4);
            this.generateCourse(5);
          }
        }
      })
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportXj",
        responseType: "blob",
        headers: objPar,
        params: {
          info: this.search.name,
          gradeId: this.search.gradeId,
          stageId: this.search.stageId,
          profId: this.search.profId,
          siteId: this.search.siteId,
          exportStatus: 1,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    rest(form) {
      this.$refs.search.resetFields()
    },
    previewStudentNo() {
      let generateRule = this.teacherForm.generateRule + "[X]";
      let leftParenthesis = '[';
      let rightParenthesis = ']';
      let studentNo = "";
      for (let i = 0; i < generateRule.length; i++) {
        if (generateRule[i] === leftParenthesis) {
          if (generateRule[i + 1] === "N") {
            studentNo += this.previewTeacherForm.yearCode;
            i += 2;
          } else if (generateRule[i + 1] === "C") {
            studentNo += this.previewTeacherForm.stageCode;
            i += 2;
          } else if (generateRule[i + 1] === "Z") {
            studentNo += this.previewTeacherForm.profCode;
            i += 2;
          } else if (generateRule[i + 1] === "J") {
            studentNo += this.previewTeacherForm.siteCode;
            i += 2;
          } else if (generateRule[i + 1] === "X") {
            studentNo += this.previewTeacherForm.serialNumCode;
            i += 2;
          } else {
            studentNo+=generateRule[i];
          }
        } else {
          studentNo += generateRule[i];
        }
      }
      this.previewTeacherForm.studentNo = studentNo;
    },
    generateCourse(type) {
      //todo:待办
      if (type === 1) {
        let yearCode = null;
        if (null != this.teacherForm.yearCode){
          yearCode = this.teacherForm.yearCode;
        }else {
          if (this.optiones.length > 0){
            yearCode = this.optiones[0].gradeCode;
            this.teacherForm.yearCode = this.optiones[0].gradeCode;
          }else {
            this.teacherForm.yearCode = yearCode;
          }
        }
        if (null != yearCode){
          let stateIndex = this.teacherForm.yearStartPosition;
          let endIndex = this.teacherForm.yearCodeLength;
          let after = "";
          let before = "";
          if (stateIndex > yearCode.length) {
            before = "0";
            for (let i = 0; i < endIndex; i++) {
              if (i === 0) {
                before = "0";
              } else {
                before += "0";
              }
            }
          } else {
            for (let i = stateIndex - 1; i < endIndex + stateIndex - 1; i++) {
              if (i < yearCode.length) {
                before += yearCode[i];
              } else {
                after += "0";
              }
            }
          }
          this.previewTeacherForm.yearCode = after + before;
        }
      } else if (type === 2) {
        let stageCode = null;
        if (null != this.teacherForm.stageCode){
          stageCode = this.teacherForm.stageCode;
        }else {
          if (this.eduStages.length > 0){
            stageCode = this.eduStages[0].stageCode;
            this.teacherForm.stageCode = this.eduStages[0].stageCode;
          }else {
            this.teacherForm.stageCode = stageCode;
          }
        }
        if (null != stageCode){
          let stateIndex = this.teacherForm.stageStartPosition;
          let endIndex = this.teacherForm.stageCodeLength;
          let after = "";
          let before = "";
          if (stateIndex > stageCode.length) {
            before = "0";
            for (let i = 0; i < endIndex; i++) {
              if (i === 0) {
                before = "0";
              } else {
                before += "0";
              }
            }
          } else {
            for (let i = stateIndex - 1; i < endIndex + stateIndex - 1; i++) {
              if (i < stageCode.length) {
                before += stageCode[i];
              } else {
                after += "0";
              }
            }
          }
          this.previewTeacherForm.stageCode = after + before;
        }
      } else if (type === 3) {
        let profCode = null;
        if (null != this.teacherForm.profCode){
          profCode = this.teacherForm.profCode;
        }else {
          if (this.eduProfessions.length > 0){
            profCode = this.eduProfessions[0].profCode;
            this.teacherForm.profCode = this.eduProfessions[0].profCode;
          }else {
            this.teacherForm.profCode = profCode;
          }
        }
        if (null != profCode){
          let stateIndex = this.teacherForm.profStartPosition;
          let endIndex = this.teacherForm.profCodeLength;
          let after = "";
          let before = "";
          if (stateIndex > profCode.length) {
            before = "0";
            for (let i = 0; i < endIndex; i++) {
              if (i === 0) {
                before = "0";
              } else {
                before += "0";
              }
            }
          } else {
            for (let i = stateIndex - 1; i < endIndex + stateIndex - 1; i++) {
              if (i < profCode.length) {
                before += profCode[i];
              } else {
                after += "0";
              }
            }
          }
          this.previewTeacherForm.profCode = after + before;
        }
      } else if (type === 4) {
        let siteCode = null;
        if (null != this.teacherForm.siteCode){
          siteCode = this.teacherForm.siteCode;
        }else {
          if (this.sites.length > 0){
            siteCode = this.sites[0].siteCode;
            this.teacherForm.siteCode = this.sites[0].siteCode;
          }else {
            this.teacherForm.siteCode = siteCode;
          }
        }
        if (null != siteCode){
          let stateIndex = this.teacherForm.siteStartPosition;
          let endIndex = this.teacherForm.siteCodeLength;
          let after = "";
          let before = "";
          if (stateIndex > siteCode.length) {
            before = "0";
            for (let i = 0; i < endIndex; i++) {
              if (i === 0) {
                before = "0";
              } else {
                before += "0";
              }
            }
          } else {
            for (let i = stateIndex - 1; i < endIndex + stateIndex - 1; i++) {
              if (i < siteCode.length) {
                before += siteCode[i];
              } else {
                after += "0";
              }
            }
          }
          this.previewTeacherForm.siteCode = after + before;
        }
      } else if (type === 5) {
        let serialNumCode = "1";
        if (null != this.teacherForm.serialNumCode){
          serialNumCode = this.teacherForm.serialNumCode;
        }else {
          this.teacherForm.serialNumCode = serialNumCode;
        }
        if (null != serialNumCode){
          let stateIndex = this.teacherForm.serialStartPosition;
          let endIndex = this.teacherForm.serialCodeLength;
          let after = "";
          let before = "";
          if (stateIndex > serialNumCode.length) {
            after = "0";
            for (let i = 0; i < endIndex; i++) {
              if (i === 0) {
                before = "0";
              } else {
                before += "0";
              }
            }
          } else {
            for (let i = stateIndex - 1; i < endIndex + stateIndex - 1; i++) {
              if (i < serialNumCode.length) {
                before += serialNumCode[i];
              } else {
                after += "0";
              }
            }
          }
          this.previewTeacherForm.serialNumCode = after + before;
        }
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    //导入
    uploader() {
      this.dialogVisibleUpload = true
    },
    generation() {
      this.dialogVisible = true;
      this.getPermission('eduCourseExamSet:list', () => {
      setTimeout(() => {
        this.openLoadingView();
        this.$axios_supermallData.post("hnjxjy-core/eduStudentNoGeneraRule/getRule")
            .then(resp => {
              if (resp.data.code === 2000) {
                if (null == resp.data.data){
                  this.$message.error("暂无生成学号规则");
                  this.teacherForm = {
                    id: null,
                    rollStatus: 0, //学籍状态 0正式生 1退学 2休学 3毕业 4肄业
                    yearCode: null, //年份代码
                    stageCode: null, //层次代码
                    profCode: null, //专业代码
                    siteCode: null, //函授站代码
                    serialNumCode: null, //序号代码
                    yearStartPosition: 1, //年份代码开始位置
                    stageStartPosition: 1, //层次代码开始位置
                    profStartPosition: 1, //专业代码开始位置
                    siteStartPosition: 1, //函授站代码开始位置
                    serialStartPosition: 1, //序号代码开始值
                    yearCodeLength: 1, //年份代码长度
                    stageCodeLength: 1, //层次代码长度
                    profCodeLength: 1, //专业代码长度
                    siteCodeLength: 1, //函授站代码长度
                    serialCodeLength: 1, //序号代码长度
                    generateRule: "[N][C][Z][J]", //规则
                    defFlag: 0,
                    createTime: null,
                    updateTime: null,
                    siteId: null
                  }
                  this.generateCourse(1);
                  this.generateCourse(2);
                  this.generateCourse(3);
                  this.generateCourse(4);
                  this.generateCourse(5);
                }else {
                  this.teacherForm = resp.data.data;
                  this.generateCourse(1);
                  this.generateCourse(2);
                  this.generateCourse(3);
                  this.generateCourse(4);
                  this.generateCourse(5);
                }
                this.loadingView.close();
              } else {
                this.loadingView.close();
              }
            }).catch(err => {
          this.$message.error(err);
          this.loadingView.close();
        });
      });
      }, 10);
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      this.openLoadingView();
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduEnrolStudent/importWscXh",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if (res.data.code === 2000) {
          this.$message.success(res.data.message);
          this.handleSearch();
          this.loadingView.close();
        }
      }).catch((err) => {
        this.$message.error("操作失败")
        this.loadingView.close();
      });
    },
    downloadbutton() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportXj",
        responseType: "blob",
        headers: objPar,
        params: {
          exportStatus: 2,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '未生成学号列表导入模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    generaStudentNo() {
      this.openLoadingView()
      this.$axios_supermallData.post("hnjxjy-core/eduStudentNoGeneraRule/generaStudentNo")
          .then(resp => {
            if (resp.data.code == 2000) {
              this.$message.success("设置成功");
              this.loadingView.close();
              this.handleSearch();
            } else {
              this.loadingView.close();
            }
          }).catch(err => {
        this.$message.error(err);
        this.loadingView.close();
      });
    },
    handleSave() {
      if (null == this.teacherForm.generateRule || this.teacherForm.generateRule === "") {
        return this.$message.error("请填写学号生成规则")
      }
      let formData = new FormData();
      if (null != this.teacherForm.id){
        formData.append("id", this.teacherForm.id);
      }
      formData.append("yearCode", this.teacherForm.yearCode);
      formData.append("yearStartPosition", this.teacherForm.yearStartPosition);
      formData.append("yearCodeLength", this.teacherForm.yearCodeLength);

      formData.append("stageCode", this.teacherForm.stageCode);
      formData.append("stageStartPosition", this.teacherForm.stageStartPosition);
      formData.append("stageCodeLength", this.teacherForm.stageCodeLength);

      formData.append("profCode", this.teacherForm.profCode);
      formData.append("profStartPosition", this.teacherForm.profStartPosition);
      formData.append("profCodeLength", this.teacherForm.profCodeLength);

      formData.append("siteCode", this.teacherForm.siteCode);
      formData.append("siteStartPosition", this.teacherForm.siteStartPosition);
      formData.append("siteCodeLength", this.teacherForm.siteCodeLength);

      formData.append("serialNumCode", this.teacherForm.serialNumCode);
      formData.append("serialStartPosition", this.teacherForm.serialStartPosition);
      formData.append("serialCodeLength", this.teacherForm.serialCodeLength);

      formData.append("generateRule", this.teacherForm.generateRule);
      formData.append("rollStatus", this.teacherForm.rollStatus);
      this.openLoadingView();
      this.$axios_supermallData.post("/hnjxjy-core/eduStudentNoGeneraRule/edit", formData)
          .then((resp) => {
            if (resp.data.code === 2000) {
              this.$message.success("操作成功")
              this.loadingView.close();
            } else {
              this.$message.error("操作失败")
              this.loadingView.close();
            }
          }).catch((err) => {
        this.$message.error(err)
        this.loadingView.close();
      });
      this.dialogVisible = false;
      this.previewTeacherForm = {
        yearCode: null, //年份代码
        stageCode: "", //层次代码
        profCode: "", //专业代码
        siteCode: "", //函授站代码
        serialNumCode: "", //序号代码
        studentNo: null,
      };
      this.teacherForm = {};
    },
    dialogVisibleFalse() {
      this.dialogVisible = false;
      this.previewTeacherForm = {
        yearCode: null, //年份代码
        stageCode: "", //层次代码
        profCode: "", //专业代码
        siteCode: "", //函授站代码
        serialNumCode: "", //序号代码
        studentNo: null,
      };
      this.teacherForm = {};
    },
    beforeClose(done) {
      this.previewTeacherForm = {
        yearCode: null, //年份代码
        stageCode: "", //层次代码
        profCode: "", //专业代码
        siteCode: "", //函授站代码
        serialNumCode: "", //序号代码
        studentNo: null,
      };
      this.teacherForm = {};
      done();
    },

  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}

::v-deep .el-form-item__content {
  text-align: left;
}
</style>
