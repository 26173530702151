<style>
	@media screen and (max-width: 600px) {
		.el-table__body-wrapper table td:nth-child(1):before {
		  content: "序号" !important;
		}
		.el-table__body-wrapper table td:nth-child(2):before {
		  content: "学校";
		}
		.el-table__body-wrapper table td:nth-child(3):before {
		  content: "课程编号" !important;
		}
		.el-table__body-wrapper table td:nth-child(4) {
			display: block !important;
		}
		.el-table__body-wrapper table td:nth-child(4):before {
		  content: "课程名称" !important;
			display: block !important;
			border-bottom:none !important;
			text-transform: uppercase;
		  font-weight: bold;
			float: left;
		}
		.el-table__body-wrapper table td:last-child:before{
			content: "报名人数" !important;
			display: block;
			text-align: left !important; 
			float: left;
		}
	}
</style>>
<template>
  <div>
    <div class="header">
      <div >
        <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
          选课统计1
        </div>
        <div class="name_num">
          <el-form inline label-width="80px" ref="search" :model="search" label-position="right">
        <div class="export">
          <div style="padding-left: -20px">
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduStatPlanCourse:export'">导出</el-button>
          </div>
          <div class="export_right">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button  @click="rest(search)">重置</el-button>
          </div>
        </div>
          <el-form-item label="名称/编号" prop="name">
            <el-input
                v-model="search.name"
                clearable
                @input="() => (search.name = search.name.trim())"
                placeholder="课程名称/课程编号"
                style="width: 280px"
            ></el-input>
          </el-form-item>
            <el-form-item label="函授站" prop="siteId">
              <el-select
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" prop="stageId">
              <el-select  clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="专业" prop="profId">
              <el-select
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="gradeId">
              <el-select  clearable v-model="search.gradeId" @change="QuerySemester"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学期" prop="semester">
            <el-select
                clearable
                v-model="search.semester"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in semesters"
                  :key="item.id"
                  :label="item.maxTerm"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="excle">
          <el-table
              :data="tableData"
              border
              style="width: 100%"
              slot="empty"
              size="medium"
              height="550px"
              max-height="550px"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="index" label="序号" width="100px"></el-table-column>
            <el-table-column prop="siteName" show-overflow-tooltip label="函授站" sortable ></el-table-column>
            <el-table-column prop="courseCode" show-overflow-tooltip label="课程编号" sortable ></el-table-column>
            <el-table-column prop="courseName" show-overflow-tooltip label="课程名称" sortable ></el-table-column>
            <el-table-column prop="gradeName" label="年级" sortable ></el-table-column>
            <el-table-column prop="stageName" label="层次" sortable ></el-table-column>
            <el-table-column prop="profName" label="专业" sortable ></el-table-column>
            <el-table-column prop="semester" label="学期"  ></el-table-column>
            <el-table-column prop="chooseCount" label="选课人数"  ></el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.handleSearch()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
  },
  data() {
    return {
      search: {
        name: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        passFlag: "",
        startTime: "",
        endTime: "",
        courseId: "",
        page: 1,
        pageSize: 10,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      value3: "",
      options: [],
      option: [
        {
          label: "否",
          value: 0,
        },
        {
          label: "是",
          value: 1,
        },
      ],
      total:0,
      semesters:[],
      eduStage: [],
      eduProfession: [],
      course:[],
      site:[],
      tableData: []
    };
  },
  methods: {
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSearch() {
      this.getPermission('eduStatPlanCourse:list', () => {
        this.openLoadingView()
        let fromData = new FormData();
        fromData.append("info",this.search.name);
        fromData.append("gradeId",this.search.gradeId);
        fromData.append("stageId",this.search.stageId);
        fromData.append("profId",this.search.profId);
        fromData.append("semester",this.search.semester);
        fromData.append("siteId",this.search.siteId);
        fromData.append("page",this.search.page);
        fromData.append("pageSize",this.search.pageSize);
        this.$axios_supermall.post("/hnjxjy-core/eduStatPlanCourse/list",fromData).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
        })
      });
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    //查询学期
    QuerySemester() {
      this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
        if (resp.data.code == 2000) {
          if(resp.data.data==null){
            this.$message.warning("该年级下暂无学期")
            this.semesters = []
            this.search.semester=""
            return;
          }
          this.semesters = []
          for (let i = resp.data.data.maxTerm; i >0; i--) {
            this.semesters.push({id:i,maxTerm:i});
          }
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStatPlanCourse/export",
        responseType: "blob",
        headers: objPar,
        params:{
          gradeId:this.search.gradeId,
          siteId:this.search.siteId,
          info:this.search.name,
          stageId:this.search.gradeId,
          profId:this.search.profId,
          semester:this.search.semester,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    formatter(row, column) {
      return row.address;
    },

  }
};
</script>
<style scoped>
.header_top{
  width: 7%;
}
.header {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.content {
  text-align: left;
  width: 93%;
}
.course {
  background-color: #f1f1f1;
  line-height: 54px;
  padding-left: 10px;
}
.export {
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.export_left {
  padding-left: 15px;
}
.export_right {
  padding-right: 50px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.name_num {
  background-color: rgba(164, 174, 224, 0.11);
  padding-left: 20px;
  text-align: left;
}
.name_num>>>.el-input--suffix{
  width: 280px;
}
.choose {
  margin-bottom: 15px;
}
.demonstration {
  display: inline-block;
  padding-right: 10px;
  font-size: 16px;
  color: #000;
}
.el-input__suffix {
  right: 5px;
}
.el-input__inner {
  width: 100%;
}
.span {
  display: inline-block;
  width: 36px;
}
.excle{
  padding: 0 20px 20px 20px;
  background-color: rgba(164, 174, 224, 0.11);
}
.excle>>>.el-table{
}
/* .el-table__body-wrapper{
  height: 550px;
} */


</style>

