<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        毕业设计成绩
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="90px" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" v-permission="'eduScoreGraduate:exportSj2'" type="primary" @click="download"
              >导出</el-button
            >
            <el-button icon="el-icon-download" type="primary" v-permission="'eduScoreGraduate:importData'" @click="edition">导入</el-button>
            <el-button icon="el-icon-check" type="primary" v-permission="'eduScoreGraduate:editStatus'" @click="editStatus">发布成绩</el-button>
            <el-button icon="el-icon-close" type="primary" v-permission="'eduScoreGraduate:editStatus'" @click="editStatu">取消发布成绩</el-button>
            <el-button type="primary" @click="handleSearch" v-permission="'eduScoreGraduate:listSj1'">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div class="sss">
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
           <el-form-item label="函授站" prop="siteId" >
             <el-select
                 style="width: 200px"
                 clearable
                 v-model="search.siteId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in site"
                   :key="item.id"
                   :label="item.siteName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
              <el-form-item label="年级" prop="gradeId">
                <el-select style="width: 200px" clearable v-model="search.gradeId" class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="item in this.options"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次" prop="stageId">
                <el-select style="width: 200px" clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="search.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

<!--            <div style="width:20%;display:inline-block">-->
<!--              <el-form-item style="margin-bottom:0">-->
<!--                是否发布-->
<!--                <el-select-->
<!--                    v-model="search.publishFlag"-->
<!--                    placeholder="请选择"-->
<!--                    class="el-form-input-width"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in publishFlag"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </div>-->
            <el-form-item label="毕业设计批次" prop="batchId">
              <el-select
                  style="width: 200px"
                  clearable
                  v-model="search.batchId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduGraduateWorkBatch"
                    :key="item.id"
                    :label="item.batchName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="520px"
                  max-height="520px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="120">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="180">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" prop="stageName" width="200">
          </el-table-column>
          <el-table-column label="专业" prop="profName" width="180">
          </el-table-column>
          <el-table-column label="课程名称" prop="scoreType" width="180">
          </el-table-column>
          <el-table-column label="成绩" prop="score" width="180">
          </el-table-column>
          <el-table-column label="是否发布" prop="publishFlag" width="180">
          </el-table-column>
           <!-- <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
                <div style="color: #46a6ff;cursor: pointer;" @click="editor(scope.row)">录入成绩</div>
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton"
          >下载模板
          </el-button
          >
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.QueryEduGraduateWorkBatch();
    this.handleSearch()
    },
  data() {
    return {
      myHeaders:{
        'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        'x-perm':localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
      },
      options: [],
      eduStage: [],
      eduProfession: [],
      site:[],
      eduGraduateWorkBatch:[],
      total: 0,
      pageNum: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      checked: false,
      num: 1,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        id:"",
        name: "",
        batchId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        siteId: "",
        publishFlag:"",
        status:"",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      // publishFlag: [
      //   {
      //     value: 0,
      //     label: "否",
      //   },
      //   {
      //     value: 1,
      //     label: "是",
      //   },
      // ],
      currentPage4: 4,
      currentPage1: 4,

      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {

    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.get("/hnjxjy-core/eduGraduateDesignBatch/queryAllName").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data;
        }
      })
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView();
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("siteId",this.search.siteId);
      fromData.append("batchId",this.search.batchId);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreGraduate/listSj",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editStatus(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        let array = [];
        let arrays = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        for (let j = 0; j < this.multipleSelection.length; j++) {
          arrays.push(this.multipleSelection[j].publishFlag);
        }

        arrays.forEach(item=>{

          if(item!="是"){
            let arr = array.toString();
            let arrs = arrays.toString();

            if(arrs=="否"){
              let arrInt =1;

              this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                this.$axios({
                  url: "hnjxjy-core/eduScoreGraduate/editStatus",
                  headers: this.loginList,
                  method: "POST",
                  params: {
                    ids: arr,
                    status: arrInt,
                  },
                }).then((res) => {
                  if (res.data.code == 2000) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.handleSearch()
                  }
                })
                    // this.getList()
                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "操作失败",
                      });
                    });
              });
            }
          }else{
            this.$message({
              type: "error",
              message: "该学生成绩已发布",
            });
          }
        })
      }else{
        this.$message({
          type: "error",
          message: "请至少一条数据",
        });
      }


    },
    rest(form){
      this.$refs.search.resetFields()
    },
    editStatu(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        let array = [];
        let arrays = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        for (let j = 0; j < this.multipleSelection.length; j++) {
          arrays.push(this.multipleSelection[j].publishFlag);
        }
        arrays.forEach(item => {

          if (item != "否") {
            let arr = array.toString();
            let arrs = arrays.toString();

            if (arrs == "是") {
              let arrInt = 0;

              this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                this.$axios({
                  url: "hnjxjy-core/eduScoreGraduate/editStatus",
                  headers: this.loginList,
                  method: "POST",
                  params: {
                    ids: arr,
                    status: arrInt,
                  },
                }).then((res) => {
                  if (res.data.code == 2000) {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                    });
                    this.handleSearch()
                  }
                })
                    // this.getList()
                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "操作失败",
                      });
                    });
              });
            }
          }
        })
      }else{
        this.$message.error("请选择一条数据")
      }

    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreGraduate/exportSj",
        responseType: "blob",
        headers: objPar,
        params:{
          info:this.search.name,
          batchId:this.search.batchId,
          gradeId:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          siteId:this.search.siteId,
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition() {
      this.dialogVisibleUpload = true;
    },
    editor(){
        this.dialogVisibleUpload1 = true;
    },
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      this.openLoadingView()
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreGraduate/importData",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if(res.data.code===2000){
              this.loadingView.close()
              this.$message.success("操作成功")
            }else{
              this.loadingView.close()
              this.$message.error("操作失败")
            }
          })
          .catch((err) => {
          });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreGraduate/exportSjMB",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
  },

};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}

</style>
