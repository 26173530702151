<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        总评成绩管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" v-permission="'eduScoreOverallRule:list'" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-check" type="primary" v-permission="'eduScoreOverallRule:editRules'"  @click="addexam">设置在线总评成绩生成规则</el-button>
<!--            <el-button icon="el-icon-upload2" type="primary" v-permission="'eduScoreOverallRule:importData'" @click="opendilog">批量导入生成规则</el-button>-->
            <el-button icon="el-icon-check" type="primary" v-permission="'eduScoreOverallRule:generateOverallScore'" @click="generateOverallScore">生成总评成绩</el-button>
            <el-button icon="el-icon-check" type="primary" v-permission="'eduScoreOverallRule:adjustmentOverallScore'" @click="opendilogss">调整总评成绩</el-button>
            <el-button icon="el-icon-bottom" v-permission="'eduScoreOverallRule:export'" type="primary" @click="download">导出</el-button>
          </el-form-item>
          <div>
            <div style=" display: inline-block">
              <el-form-item label="名称/编号">

                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="课程名称/课程编号"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
            </div>
            <div style="display:inline-block">
           <el-form-item label=" 考试批次" style="margin-bottom:0">

             <el-select
                 clearable
                 v-model="search.batchId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in eduGraduateWorkBatch"
                   :key="item.id"
                   :label="item.batchName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
<!--            <div style="display:inline-block">-->
<!--              <el-form-item label="年级" style="margin-bottom:0">-->

<!--                <el-select clearable v-model="search.gradeId"  @change="QuerySemester" class="el-form-input-width" placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in this.option"-->
<!--                      :key="item.id"-->
<!--                      :label="item.gradeName"-->
<!--                      :value="item.id"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </div>-->
          </div>
        </el-form>
      </div>
      <div class="table-wrap" style="padding: 0">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="80" type="index"></el-table-column>
          <el-table-column label="课程编号" show-overflow-tooltip prop="courseCode"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName"> </el-table-column>
          <el-table-column label="考试批次" prop="batchName"> </el-table-column>
          <el-table-column label="总评成绩生成规则" show-overflow-tooltip prop="scoreOveralRule"> </el-table-column>
          <el-table-column label="通过成绩" prop="passScore"> </el-table-column>
          <el-table-column label="最高成绩" prop="maxScore"></el-table-column>
          <el-table-column label="离线学习" prop="elecOfflineScore"></el-table-column>
          <el-table-column label="在线交互" prop="elecOnlineOverrall"></el-table-column>
            <el-table-column label="考试成绩" prop="elecExamScore"></el-table-column>
            <el-table-column label="是否已调整" prop="changeFlag">
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleImport" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton"
          >下载模板
          </el-button
          >
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <el-form :model="teacherForm">
      <el-dialog
        title="设置总评成绩生成规则"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-left: 80px">
        <div style="margin-bottom: 20px; text-align: left">
          离线成绩：<el-select v-model="teacherForm.elecOfflineScore" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          在线交互：<el-select v-model="teacherForm.elecOnlineOverrall" placeholder="请选择">
            <el-option
              v-for="item in optionss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          考试成绩：<el-select v-model="teacherForm.elecExamScore" placeholder="请选择">
            <el-option
              v-for="item in optionsss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
<!--          <div style="margin-bottom: 20px; text-align: left">-->
<!--            最高成绩：<el-input  v-model="teacherForm.maxScore" placeholder="请输入内容" style="width:51%"></el-input>-->
<!--          </div>-->
        <div style="margin-bottom: 20px; text-align: left">
          通过成绩≥：<el-input  v-model="teacherForm.passScore" placeholder="请输入内容" style="width:45%"></el-input>
        </div>
        </div>
        <div style="margin-top: 50px; text-align: center">
          <el-button @click="dialogVisibleUpload = false">关闭</el-button>
          <el-button type="primary" @click="editRules">确定</el-button>
        </div>
      </el-dialog>
      </el-form>
      <el-form :model="versionList">
        <el-dialog
            title="设置总评成绩生成规则"
            :visible.sync="adjustmentOverallScores"
            width="30%"
        >
          <div style="margin-left: 80px">
            <div style="margin-bottom: 20px; text-align: left">
              最高分数：<el-input  v-model="versionList.highSocre" placeholder="请输入内容" style="width:51%"></el-input>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              最低分数：<el-input  v-model="versionList.lowSocre" placeholder="请输入内容" style="width:45%"></el-input>
            </div>
            <div style="margin-bottom: 20px; text-align: left">
              调整分数：<el-input  v-model="versionList.adjustmentSocre" placeholder="请输入内容" style="width:51%"></el-input>
            </div>
          </div>
          <div style="margin-top: 50px; text-align: center">
            <el-button @click="adjustmentOverallScores = false">关闭</el-button>
            <el-button type="primary" @click="adjustmentOverallScore">确定</el-button>
          </div>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch()
    this.QueryEduGraduateWorkBatch()
    },
  data() {
    return {
      myHeaders:{
        'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        'x-perm':localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
      },
      semesters:[],
      eduGraduateWorkBatch:[],
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      dialogVisibleImport:false,
      adjustmentOverallScores:false,
      radio: "1",
      title: "",
      search: {
        name: "",
        page: 1,
        pageSize: 10,
        batchId:"",
        semester:"",
        gradeId: "",
      },
      teacherForm: {
        name: "",
        elecOfflineScore: "",
        elecOnlineOverrall: "",
        elecExamScore: "",
        passScore: "",
        maxScore:"",
      },
      tableChecked: [],
      tableData: [
      ],
      versionList:{
        lowSocre:"",
        highSocre:"",
        ids	:"",
        adjustmentSocre:"",
      },
      currentPage4: 4,
      currentPage1: 4,
      option:[],
      options: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      optionss: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      optionsss: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("batchId",this.search.batchId);
      fromData.append("info",this.search.name);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreOverallRule/list?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    editRules(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      let elecOfflineScore = parseInt(this.teacherForm.elecOfflineScore.substring(0,this.teacherForm.elecOfflineScore.lastIndexOf("%")))
      let elecOnlineOverrall = parseInt(this.teacherForm.elecOnlineOverrall.substring(0,this.teacherForm.elecOnlineOverrall.lastIndexOf("%")));
      let elecExamScore = parseInt(this.teacherForm.elecExamScore.substring(0,this.teacherForm.elecExamScore.lastIndexOf("%")));
      if(elecOfflineScore+elecOnlineOverrall+elecExamScore!=100){
        this.$message({
          type: "error",
          message: "离线成绩+在线交互+考试成绩必须等于100%!",
        });
      }else{
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduScoreOverallRule/editRules",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
              elecOfflineScore:elecOfflineScore,
              elecOnlineOverrall:elecOnlineOverrall,
              elecExamScore:elecExamScore,
              passScore:this.teacherForm.passScore,
             // maxScore:this.teacherForm.maxScore,
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.openLoadingView();
            this.handleSearch()
            this.dialogVisibleUpload =false
          }).catch(() => {
            this.$message({
              type: "info",
              message: "操作失败",
            });
          });
        });
      }
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.option = res.data.data;
      });
    },
    //查询学期
    QuerySemester() {
      this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
        if (resp.data.code == 2000) {
          this.semesters = []
          for (let i = resp.data.data.maxTerm; i >0; i--) {
            this.semesters.push({id:i,maxTerm:i});
          }
        }
      })
    },
    generateOverallScore(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduScoreOverallRule/generateOverallScore",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.handleSearch()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "操作失败",
            });
          });
        });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据",
        });
      }

    },
    adjustmentOverallScore(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: "hnjxjy-core/eduScoreOverallRule/adjustmentOverallScore",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            lowSocre: this.versionList.lowSocre,
            highSocre: this.versionList.highSocre,
            adjustmentSocre: this.versionList.adjustmentSocre,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.openLoadingView();
          this.handleSearch()
          this.adjustmentOverallScores=false
        }).catch(() => {
          this.$message({
            type: "info",
            message: "操作失败",
          });
        });
      });
    },
    opendilog(){
      this.dialogVisibleImport = true;
    },
    opendilogss(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.adjustmentOverallScores=true
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }

    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreOverallRule/exportMB",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
          })
          .catch((err) => {
          });
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      this.openLoadingView()
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreOverallRule/importData",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if(res.data.code===2000){
              this.$message.success(res.data.message)
              this.dialogVisibleImport=false;
              this.handleSearch();
              this.loadingView.close();
            }else{
              this.$message.error(res.data.message)
              this.dialogVisibleImport=false;
              this.handleSearch();
              this.loadingView.close();
            }
          })
          .catch((err) => {
          });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.dialogVisibleUpload = true;
        this.teacherForm={}
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreOverallRule/export",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
