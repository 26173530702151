<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学生分类统计
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="55px" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduStatStudentClass:export'">导出</el-button>
            <el-button type="primary" v-permission="'eduStatStudentClass:list'" @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)" >重置</el-button>
          </el-form-item>
          <div>
            <el-form-item  prop="siteId" label="函授站" >
              <el-select
                  style=""
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="gradeId" label="年级" >
              <el-select style="width: 215px" clearable v-model="search.gradeId"   class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="stageId" label="层次" >
              <el-select clearable v-model="search.stageId" style="width: 215px" class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="profId" label="专业" >
              <el-select
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item prop="sex" label="性别" >-->
<!--              <el-select clearable v-model="search.sex"  class="el-form-input-width" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in options"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item prop="political" label="政治面貌" >-->
<!--              <el-select style="width: 215px" clearable v-model="search.political"  class="el-form-input-width" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in political"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item prop="ageType" label="年龄段" >-->
<!--              <el-select style="width: 215px" clearable v-model="search.ageType"  class="el-form-input-width" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in ageType"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item  label="民族" prop="clan">-->
<!--              <el-select clearable v-model="search.clan" placeholder="请选择">-->
<!--                <el-option-->

<!--                    v-for="item in clans"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table :data="tableData" style="width: 100%"
                  size="medium"
                  height="500px"
                  max-height="500px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="siteName" label="函授站名称">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级">
          </el-table-column>
          <el-table-column prop="stageName" label="层次">
          </el-table-column>
          <el-table-column prop="profName" show-overflow-tooltip label="专业名称">
          </el-table-column>
          <el-table-column prop="personNum" label="人数">
          </el-table-column>
          <el-table-column label="性别">
            <el-table-column prop="maleNum" label="男" width="120">
            </el-table-column>
            <el-table-column prop="femaleNum" label="女" width="120">
            </el-table-column>
          </el-table-column>
           <el-table-column label="民族">
            <el-table-column prop="hanNatNum" label="汉族" width="120">
            </el-table-column>
            <el-table-column prop="otherNatNum" label="少数民族" width="120">
            </el-table-column>
          </el-table-column>
           <el-table-column label="年龄段">
            <el-table-column prop="downSevNum" label="17岁以下" width="120">
            </el-table-column>
            <el-table-column prop="centerNum" label="17岁至30岁" width="120">
            </el-table-column>
            <el-table-column prop="upThirtyNum" label="30岁以上" width="120">
            </el-table-column>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      option:[],
      eduStage:[],
      eduProfession:[],
      political:[
        {
          value: 0,
          label: "团员",
        },
        {
          value: 1,
          label: "党员",
        },
      ],
      ageType:[
        {
          value: 1,
          label: "17岁以下",
        },
        {
          value: 2,
          label: "17岁-30岁",
        },
        {
          value: 3,
          label: "30岁以上",
        },
      ],
      clans:[
        {
          value: "0",
          label: "少数民族",
        },
        {
          value: "1",
          label: "汉族",
        },
      ],
      // clan:[
      //   {
      //     value: 1,
      //     label: "17岁以下",
      //   },
      //   {
      //     value: 2,
      //     label: "17岁-30岁",
      //   },
      //   {
      //     value: 3,
      //     label: "30岁以上",
      //   },
      // ],
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      search: {
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        political: "",
        clan: "",
        ageType: "",
        sex: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [

      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("political",this.search.political);
      fromData.append("ageType",this.search.ageType);
      fromData.append("sex",this.search.sex);
      if(this.search.clan!=null&&this.search.clan!=""){
        fromData.append("clan",this.search.clan);
      }

      this.$axios_supermall.post("/hnjxjy-core/eduStatStudentClass/list?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },

    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStatStudentClass/export",
        responseType: "blob",
        headers: objPar,
        params:{
          gradeId:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          sex:this.search.sex,
          political:this.search.political,
          siteId:this.search.siteId,
          ageType:this.search.ageType,
          clan:this.search.clan,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
