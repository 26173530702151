<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考试成绩录入
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="75px" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" type="primary" v-permission="'eduScoreStudent:importData'" @click="edition()"
              >导入</el-button
            >
            <el-button icon="el-icon-download" type="primary" @click="download" v-permission="'eduScoreStudent:export'">导出</el-button>
            <el-button type="primary" @click="handleSearchs" v-permission="'eduScoreStudent:list'">查询</el-button>
            <el-button @click="rest('search')">重置</el-button>
          </el-form-item>
          <div class="sss">
              <el-form-item label="姓名/学号" prop="name" >
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="学号/姓名"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="考试批次" prop="batchId" >
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="search.batchId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduGraduateWorkBatch"
                      :key="item.id"
                      :label="item.batchName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年级" prop="gradeId" >
                <el-select style="width: 200px" v-model="search.gradeId" placeholder="请选择">
                  <el-option
                      v-for="item in this.options"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次" prop="stageId">
                <el-select style="width: 200px" v-model="search.stageId" placeholder="请选择">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >
                <el-select
                    style="width: 200px"
                    clearable
                    v-model="search.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            <el-form-item label="站点" prop="siteId" >
              <el-select
                  style="width: 200px"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="录入状态" prop="inputStatus">
              <el-select
                  style="width: 200px"
                  v-model="search.inputStatus"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in correspondType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border
                  size="medium"
                  height="520px"
                  max-height="520px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column label="序号" width="120" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="180">
          </el-table-column>
          <el-table-column label="课程名称" prop="courseName" width="180">
          </el-table-column>
          <el-table-column label="课程代码" prop="courseCode" width="180">
          </el-table-column>
          <el-table-column label="考试成绩" prop="examScore" width="180">
          </el-table-column>
          <el-table-column label="站点" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" prop="stageName" width="180">
          </el-table-column>
          <el-table-column label="专业" prop="profName" width="180">
          </el-table-column>
          <el-table-column label="学期" prop="semester" width="180">
          </el-table-column>
          <el-table-column label="录入状态" prop="inputStatus" width="180">
          </el-table-column>
          <el-table-column label="备注" show-overflow-tooltip prop="remark" width="180"> </el-table-column>
          <el-table-column  label="录入成绩" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="eduScoreStudentedit" @click="editor(scope.row)" type="primary" size="small"
              >录入成绩</el-button
              >
              <span v-if="!eduScoreStudentedit">暂无操作</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
<!--    录入成绩  -->
      <el-dialog
          title="录入成绩"
          :visible.sync="dialogVisible"
          width="40%"
          style="text-align: left"
      >
        <el-form
            :model="teacherForm"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-col :span="12">
            <el-form-item label="课程：" prop="courseName">
              <el-input
                  readonly
                  v-model="teacherForm.courseName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.courseName = teacherForm.courseName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批次：" prop="batchName">
              <el-input
                  readonly
                  v-model="teacherForm.batchName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分数：" prop="examScore">
              <el-input
                  v-model="teacherForm.examScore"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.examScore = teacherForm.examScore.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注：" prop="remark">
              <el-input
                  v-model="teacherForm.remark"
                  placeholder="请输入"
                  class="el-form-input-width"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >保存后关闭</el-button
        >
      </span>
      </el-dialog>

      <!-- 导入 上传文件 -->
      <el-dialog
        title="上传文件"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px;text-align:left">
          <el-button type="primary"  @click="downloadbutton">下载模板</el-button>
          <el-button type="primary"  @click="quitImportFile">关闭</el-button>
          <el-button type="primary"  @click="theImportFile">保存</el-button>
        </div>
         <div style="text-align:left;margin-bottom:1rem">
        课程
           <el-select
               clearable
               v-model="search.id"
               placeholder="请选择"
               class="el-form-input-width"
           >
             <el-option
                 v-for="item in course"
                 :key="item.id"
                 :label="item.courseName"
                 :value="item.id"
             >
             </el-option>
           </el-select>
        </div>
        <el-upload
          class="upload-demo"
          drag
          action="#"
          clearFiles
          :show-file-list="false"
          :http-request="uploadFile"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
          <div class="el-upload__tip" slot="tip" style="color:red">
            导入前请先下载模板，根据模版整理好数据后上传导入.
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.getPermission('eduScoreStudent:edit2', () => {
      this.eduScoreStudentedit=true;
    })
    this.handleSearchs()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
    this.gradList();
    this.queryCourse();
    },
  data() {
    return {
      importFile: null,
      dialogVisibleUpload:false,
      dialogVisible : false,
      eduScoreStudentedit : false,
      readonly: true,
      eduStage: [],
      eduProfession: [],
      course:[],
      site:[],
      eduGraduateWorkBatch:[],
      total:0,
      search: {
        id:"",
        name: "",
        batchId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        inputStatus: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        batchName:"",
        courseName:"",
        examScore:"",
        remark:"",
        id:"",
      },
      tableChecked: [],
      tableData: [],
      correspondType: [
        {
          value: 0,
          label: "未录入",
        },
        {
          value: 1,
          label: "已录入",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [],
    };
  },
  methods: {
    rest(form){
      this.$refs.search.resetFields()
    },
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询批次下拉框
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询课程
    queryCourse() {
      this.$axios_supermall.post("/hnjxjy-core/eduCourse/courseList").then(resp => {
        if (resp.data.code == 2000) {
          this.course = resp.data.data;
        }
      })
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },

    handleSearchs(){
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView();
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("batchId",this.search.batchId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("semester",this.search.semester);
      fromData.append("siteId",this.search.siteId);
      fromData.append("inputStatus",this.search.inputStatus);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
        this.$axios_supermall.post("/hnjxjy-core/eduScoreStudent/list",fromData).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
        })
        });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreStudent/exportMB",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreStudent/export",
        responseType: "blob",
        headers: objPar,
        params:{
          siteId:this.search.siteId,
          batchId:this.search.batchId,
          info:this.search.name,
          gradeId:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          inputStatus:this.search.inputStatus,
          semester:this.search.semester,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    editor(row){
      this.openLoadingView()
      this.title = "录入成绩";
      this.$axios_supermall.get("hnjxjy-core/eduScoreStudent/getOne?"+
          "id="+row.id
      ).then(resp => {
        this.dialogVisible = true;
        this.teacherForm = resp.data.data;
      });
    },
    handleSave(){
      let formData = new FormData();
      formData.append("examScore",this.teacherForm.examScore)
      if(this.teacherForm.remark!=null&&this.teacherForm.remark!=""){
        formData.append("remark",this.teacherForm.remark)
      }
      formData.append("id",this.teacherForm.id)
      this.openLoadingView()
      this.$axios_supermall.put("hnjxjy-core/eduScoreStudent/edit",formData).then(resp => {
        if (resp.data.code == 2000){
          this.$message.success("操作完成");
          this.handleSearchs();
          this.dialogVisible = false;

        }else {
          this.$message.warning(resp.data.message);
        }
      })
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearchs();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearchs();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition() {
      this.dialogVisibleUpload = true;
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      this.importFile = file;
      this.$message.success("文件上传成功")
    },
    theImportFile(){
      if(this.importFile == null){
        return this.$message.error("请上传文件")
      }
      this.openLoadingView();
      let fileDate = this.importFile.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreStudent/importData",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if (res.data.code == 2000){
          this.$message.success("导入成功")
          this.loadingView.close();
          this.handleSearchs();
          this.dialogVisibleUpload = false;
        }else {
          this.$message.error("导入失败")
          this.loadingView.close();
          this.dialogVisibleUpload = false;
        }
      }).catch((err) => {
        this.$message.error(err)
        this.loadingView.close();
        this.dialogVisibleUpload = false;
      });
    },
    quitImportFile(){
      this.importFile = null;
      this.dialogVisibleUpload = false;
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}

</style>
