<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考试成绩分析
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-download" @click="download" v-permission="'eduScoreExamAnalyse:export'" type="primary">导出</el-button>
            <el-button icon="el-icon-search" @click="handleSearch" v-permission="'eduScoreExamAnalyse:list'" type="primary">查询</el-button>
          </el-form-item>
          <div>
            <div style=" display: inline-block">
              <el-form-item label="考试批次" style="margin-bottom: 0">

                <el-select
                    clearable
                    v-model="search.batchId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduGraduateWorkBatch"
                      :key="item.id"
                      :label="item.batchName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
<!--            <div style="width: 17%; display: inline-block">-->
<!--              <el-form-item prop="gradeId" style="margin-bottom: 0">-->
<!--                年级-->
<!--                <el-select clearable v-model="search.gradeId" @change="QuerySemester" class="el-form-input-width" placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in this.options"-->
<!--                      :key="item.id"-->
<!--                      :label="item.gradeName"-->
<!--                      :value="item.id"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </div>-->
<!--            <div style="width: 20%; display: inline-block">-->
<!--              <el-form-item style="margin-bottom: 0">-->
<!--                学期-->
<!--                <el-select-->
<!--                    clearable-->
<!--                    v-model="search.semester"-->
<!--                    placeholder="请选择"-->
<!--                    class="el-form-input-width"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in semesters"-->
<!--                      :key="item.id"-->
<!--                      :label="item.maxTerm"-->
<!--                      :value="item.id"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </div>-->
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table :data="tableData" style="width: 100%"
                  size="medium"
                  height="530px"
                  max-height="530px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" width="120">
          </el-table-column>
          <el-table-column prop="courseName" label="课程名称" width="160">
          </el-table-column>
          <el-table-column prop="siteName" label="站点" width="180">
          </el-table-column>
          <el-table-column prop="examNum" label="考试人数" width="160">
          </el-table-column>
          <el-table-column label="考试成绩分析">
            <el-table-column prop="ninetyNum" label="90分以上">
            </el-table-column>
            <el-table-column prop="eightyNum" label="80分以上">
            </el-table-column>
            <el-table-column prop="seventyNum" label="70分以上">
            </el-table-column>
            <el-table-column prop="sixtyNum" label="60分以上">
            </el-table-column>
            <el-table-column prop="lessSixtyNum" label="60分以下">
            </el-table-column>
          </el-table-column>
<!--          <el-table-column prop="semester" label="学期" width="160">-->
<!--          </el-table-column>-->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
      this.handleSearch()
    this.QueryEduGraduateWorkBatch();
    this.gradList();
    },
  data() {
    return {
      semesters:[],
      options:[],
      eduGraduateWorkBatch:[],
      dialogVisibleUpload: false,
      total: 0,
      search: {
        name: "",
        page: 1,
        pageSize: 10,
        batchId:"",
        semester:"",
        gradeId: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [],
    };
  },
  methods: {
    //查询批次下拉框
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    //查询学期
    QuerySemester() {
      this.semesters = []
      this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
        if (resp.data.code == 2000) {
          this.semesters = []
          for (let i = resp.data.data.maxTerm; i >0; i--) {
            this.semesters.push({id:i,maxTerm:i});
          }
        }
      })
    },
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("batchId",this.search.batchId);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreExamAnalyse/list?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{

        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    // 点击导出
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreExamAnalyse/export",
        responseType: "blob",
        headers: objPar,
        params:{
          batchId:this.search.batchId,
          semester:this.search.semester
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
