<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        免试审核
      </div>
      <div class="search">
        <el-form :model="search" ref="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-error" v-permission="'eduScoreFree:editStatus'" type="primary" @click="reject">驳回
            </el-button>
            <el-button icon="el-icon-success" v-permission="'eduScoreFree:editStatus'" type="primary" @click="examine">
              审核
            </el-button>
            <el-button icon="el-icon-download" type="primary" @click="download">导出</el-button>
            <el-button type="primary" v-permission="'eduScoreFree:list'" @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
            <div style="display:inline-block">
              <el-form-item label="姓名/学号" prop="name" style="margin-bottom:0">

                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="学号/姓名"
                    style="width: 250px"
                ></el-input>

              </el-form-item>
            </div>
            <div style="display:inline-block">
              <el-form-item prop="siteId" label="函授站" style="margin-bottom:1rem;margin-left: 20px">
                <el-select
                    clearable
                    v-model="search.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display:inline-block">
              <el-form-item label="审核状态" prop="checkStatus" style="margin-bottom:0;margin-left: 20px">

                <el-select v-model="search.checkStatus" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="550px"
                  max-height="550px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column label="序号" width="120" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="200">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="课程名称" prop="courseName" width="180">
          </el-table-column>
          <el-table-column label="课程编号" prop="courseCode" width="200">
          </el-table-column>
          <el-table-column label="申请时间" prop="applyTime" width="180">
          </el-table-column>
          <el-table-column label="审核状态" prop="checkStatus" width="180">
          </el-table-column>
          <el-table-column label="审核人" prop="checkPerson" width="180"></el-table-column>
          <el-table-column label="审核时间" prop="checkTime" width="180"></el-table-column>
          <el-table-column label="驳回原因" prop="rejectReason" width="180"></el-table-column>
          <el-table-column label="免试成绩" prop="freeScore" width="200">
            <template slot-scope="scope">
              <el-input-number v-if="eduScoreFreeEdit" v-model="scope.row.freeScore" :min="1" @change="updateFun(scope.row)"
                               label="描述文字"></el-input-number>
              <span v-if="!eduScoreFreeEdit">{{scope.row.freeScore}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
              >查看附件
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
        <el-dialog
            title="状态驳回"
            :visible.sync="dialogreject"
            width="30%"
        >
          <div style="margin-bottom: 20px;  text-align: left">
            <span style="font-size: 16px; font-weight: bold">驳回原因</span>：
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入内容"
                v-model="yuanyin">
            </el-input>
          </div>
          <div style="margin-bottom: 20px; text-align: left">
            <el-button @click="dialogVisibleUpload = false"
            >关闭
            </el-button
            >
            <el-button type="primary"
                       @click="clear">确定并清空总评成绩
            </el-button>
            <el-button type="primary"
                       @click="noClear">确定并不清空总评成绩
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.getPermission('eduScoreFree:edit1', () => {
        this.eduScoreFreeEdit=true;
    })
    this.handleSearch();
    this.queryEduSite();
  },
  data() {
    return {
      dialogreject: false,
      site: "",
      total: 0,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      num: 1,
      dialogVisible: false,
      radio: '1',
      title: "",
      yuanyin: "",
      search: {
        name: "",
        siteId: "",
        page: 1,
        pageSize: 10,
        checkStatus: "",
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [],
      attachmentUrl: "",
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 1,
      currentPage1: 4,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "驳回",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      value1: '',
      eduScoreFreeEdit:false
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
        this.openLoadingView();
        let fromData = new FormData();
        fromData.append("info", this.search.name);
        fromData.append("page", this.search.page);
        fromData.append("pageSize", this.search.pageSize);
        fromData.append("checkStatus", this.search.checkStatus);
        fromData.append("siteId", this.search.siteId);
        // fromData.append("batchId",this.search.batchId);
        // fromData.append("isMatch",this.search.isMatch);
        this.$axios_supermall.post("/hnjxjy-core/eduScoreFree/list", fromData).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
          }
        })
      });
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    rest(form) {
      this.$refs.search.resetFields()
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreFree/export",
        responseType: "blob",
        headers: objPar,
        params: {
          info: this.search.name,
          checkStatus: this.search.checkStatus,
          siteId: this.search.siteId
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    editor(row) {
      if (row.attachmentUrl != null && row.attachmentUrl != "") {
        window.open(row.attachmentUrl)
      } else {
        this.$message({
          type: "error",
          message: "附件为空!",
        });
      }

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //录入成绩
    updateFun(row) {
      let formData = new FormData();
      formData.append("id", row.id);
      formData.append("freeScore", row.freeScore);
      this.$axios_supermallData.post("/hnjxjy-core/eduScoreFree/edit", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("录入成功");
        } else {
          this.$message.error("录入失败");
        }
      })
    },
    examine() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      if (array.length <= 0) {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      } else {
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduScoreFree/editStatus",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
              status: 1
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.openLoadingView();
            this.handleSearch()
          })

              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }
    },
    clear() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: "hnjxjy-core/eduScoreFree/editStatus",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            rejectReason: this.yuanyin,
            status: 3
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.openLoadingView();
          this.handleSearch()
          this.dialogreject = false
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      });
    },
    noClear() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: "hnjxjy-core/eduScoreFree/editStatus",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            rejectReason: this.yuanyin,
            status: 2
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
          this.openLoadingView();
          this.handleSearch()
          this.dialogreject = false
        })

            .catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      });
    },
    reject() {
      if (this.multipleSelection != null && this.multipleSelection != "") {
        this.dialogreject = true
        this.yuanyin = ""
      } else {
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}

::v-deep .el-form-item__content {
  text-align: left;
}
</style>
