z<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        考试成绩查询
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="75px" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" v-permission="'eduScoreStudent:exportC'" @click="download">导出</el-button>
            <el-button icon="el-icon-upload2" type="primary" @click="opendilog" v-permission="'eduScoreStudent:importCData'" >导入修改成绩申请</el-button>
            <el-button icon="el-icon-edit" v-permission="'eduScoreStudent:editScore'" type="primary" @click="addexam()">修改成绩</el-button>
            <el-button type="primary" v-permission="'eduScoreStudent:listC'" @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
              <el-form-item label="姓名/学号" prop="name">

                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
                  style="width: 205px;"
                ></el-input>
              </el-form-item>
              <el-form-item label="考试批次"  prop="batchId">

                <el-select
                    clearable
                    v-model="search.batchId"
                    placeholder="请选择"
                    class="el-form-input-width"
                    style="width: 205px;"
                >
                  <el-option
                      v-for="(item,index) in eduGraduateWorkBatch"
                      :key="index"
                      :label="item.batchName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年级" prop="gradeId" >

                <el-select style="width: 205px;" clearable v-model="search.gradeId"  class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in this.options"
                      :key="index"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item label="学期" prop="values">-->

<!--                <el-select-->
<!--                    style="width: 205px;"-->
<!--                    clearable-->
<!--                    v-model="search.semester"-->
<!--                    placeholder="请选择"-->
<!--                    class="el-form-input-width"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="(item,index) in semesters"-->
<!--                      :key="index"-->
<!--                      :label="item.maxTerm"-->
<!--                      :value="item.id"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="考试课程" prop="courseId" >

                <el-select
                    style="width: 205px;"
                    clearable
                    v-model="search.courseId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="(item,index) in course"
                      :key="index"
                      :label="item.courseName"
                      :value="item.courseId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学习状态" prop="passFlag" >

                <el-select style="width: 205px" v-model="search.passFlag" placeholder="请选择">
                  <el-option
                    v-for="(item,index) in optionss"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次" prop="stageId" >

                <el-select v-model="search.stageId" style="width: 205px" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in eduStage"
                      :key="index"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="函授站" prop="siteId" >

                <el-select
                    clearable
                    style="width: 205px;"
                    v-model="search.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="(item,index) in site"
                      :key="index"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >

                <el-select
                    clearable
                    v-model="search.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                    style="width: 205px;"
                >
                  <el-option
                      v-for="(item,index) in eduProfession"
                      :key="index"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="500px"
                  max-height="500px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="160">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="160">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="160">
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="160">
          </el-table-column>
          <el-table-column label="层次" prop="stageName" width="160">
          </el-table-column>
          <el-table-column label="专业" prop="profName" width="160">
          </el-table-column>
          <el-table-column label="学期" prop="semester" width="160">
          </el-table-column>
          <el-table-column label="课程名称" prop="courseName" width="160">
          </el-table-column>
          <el-table-column label="课程编号" show-overflow-tooltip prop="courseCode" width="160">
          </el-table-column>
          <el-table-column label="学习状态" prop="passFlag" width="160">
          </el-table-column>
          <el-table-column label="离线成绩" prop="offlineScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.offlineScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.offlineScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="在线交互" prop="mutualScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.mutualScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.mutualScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="在线作业" prop="workScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.workScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.workScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="免试成绩" prop="freeScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.freeScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.freeScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="考试成绩" prop="examScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.examScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.examScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="总评成绩" prop="overallScore" width="160">
            <template slot-scope="scope">
              <div v-if="null == scope.row.overallScore">
                暂无成绩
              </div>
              <div v-else>
                {{scope.row.overallScore}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" show-overflow-tooltip prop="contactPhone" width="160">
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleImport" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" size="small" @click="downloadbutton">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            :headers="myHeaders"
            class="upload-demo"
            drag
            :action="this.outImportDataTeacher"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <!-- 修改成绩 -->
      <el-form :model="teacherForm">
      <el-dialog
        title="修改成绩"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >

        <div >
          <div style="margin-bottom: 20px; text-align: center">
            成绩类型：<el-select style="width: 200px" v-model="teacherForm.scoreType" placeholder="请选择">
              <el-option
                v-for="(item,index) in optionsss"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-bottom: 20px; margin-left: 30px;  text-align: center">
            分数：<el-input
              v-model="teacherForm.score"
              placeholder="请输入内容"
              style="width: 200px"
            ></el-input>
          </div>
          <div style="margin-bottom: 20px;  text-align: center">
            修改原因：<el-input
              v-model="teacherForm.reason"
              placeholder="请输入内容"
              style="width: 200px"
            ></el-input>
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogVisibleUpload = false">关闭</el-button>
          <el-button type="primary" @click="editor">确定</el-button>
        </div>
      </el-dialog>
      </el-form>

    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
    this.gradList();
    this.queryCourse();
  },
  data() {
    return {
      myHeaders:{
        'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        'x-perm':localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
      },
      course:[],
      eduStage: [],
      eduProfession: [],
      site:[],
      semesters:[],
      eduGraduateWorkBatch:[],
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      dialogVisibleImport:false,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
        gradeId:"",
        semester:"",
        batchId:"",
        courseId:"",
        passFlag:"",
        siteId:"",
        stageId:"",
        profId:"",
        value:"",
        values:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        ids:"",
        scoreType:"",
        score:"",
        reason:"",
      },
      tableChecked: [],
      tableData: [
      ],
      optionss: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      optionsss: [
        {
          value: 3,
          label: "考试成绩",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("batchId",this.search.batchId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("semester",this.search.semester);
      fromData.append("info",this.search.name);
      fromData.append("siteId",this.search.siteId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("courseId",this.search.courseId);
      fromData.append("passFlag",this.search.passFlag);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreStudent/listC?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询课程
    queryCourse() {
      this.$axios_supermallData.get("/hnjxjy-core/eduExamBatch/queryEduExamBatchCourseList").then(resp => {
        if (resp.data.code == 2000) {
          this.course = resp.data.data.list;
        }
      })
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    // //查询学期
    // QuerySemester() {
    //   this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
    //     if (resp.data.code == 2000) {
    //       this.semesters = []
    //       for (let i = resp.data.data.maxTerm; i >0; i--) {
    //         this.semesters.push({id:i,maxTerm:i});
    //       }
    //     }
    //   })
    // },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
      download(){
        let objPar = JSON.parse(localStorage.getItem("loginStrage"));
        this.openLoadingView()
        this.$axios({
          method: "get",
          url: "hnjxjy-core/eduScoreStudent/exportC",
          responseType: "blob",
          headers: objPar,
          params:{
            info:this.search.name,
            gradeId:this.search.gradeId,
            semester:this.search.semester,
            batchId:this.search.batchId,
            siteId:this.search.siteId,
            stageId:this.search.stageId,
            profId:this.search.profId,
            courseId:this.search.courseId,
            passFlag:this.search.passFlag
          },
        })
            .then((res) => {
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              let url = window.URL.createObjectURL(blob);
              window.location.href = url;
              this.loadingView.close()
              this.$message.success("操作成功")
            })
            .catch((err) => {
            });
      },

    editor(){
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      if(array.length<=0){
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }else{
        let formData = new FormData();
        formData.append("ids",arr)
        formData.append("scoreType",this.teacherForm.scoreType)
        formData.append("reason",this.teacherForm.reason)
        formData.append("score",this.teacherForm.score)
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios_supermallData.put("hnjxjy-core/eduScoreStudent/editScore",formData).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.openLoadingView();
            this.handleSearch()
            this.dialogVisibleUpload=false
          })

              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreStudent/exportMBC",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreStudent/importCData",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if (undefined === res.data.data){
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              let url = window.URL.createObjectURL(blob);
              window.location.href = url;
            }else {
              this.$message.success(res.data.message)
              this.dialogVisibleImport = false
              this.handleSearch();
            }
            this.loadingView.close();
          })
          .catch((err) => {
            this.loadingView.close();
          });
    },
    opendilog(){
      this.dialogVisibleImport = true;
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {

        if(this.multipleSelection!=null&&this.multipleSelection!=""){
          this.dialogVisibleUpload = true;
          this.teacherForm={}
        }else{
          this.$message({
            type: "error",
            message: "请至少选中一条数据!",
          });
        }
    },
    reshuffle() {
      this.dialogVisibleUpload1 = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.search{
  display: flex;
}
</style>
