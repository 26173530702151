<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        注册列表
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="75px" inline>

          <el-form-item>
            <el-button icon="el-icon-top" v-permission="'eduEnrolStudent:importZc2Xh'" type="primary" @click="uploader()"
              >根据学号导入已注册学生</el-button
            >
            <!-- 根据学号导入已注册学生 -->
            <el-button icon="el-icon-top" type="primary" v-permission="'eduEnrolBatch:editTime'" @click="generation()"
              >注册时间设定</el-button
            >
             <el-button type="primary" v-permission="'eduEnrolStudent:editRegisterStatus'" @click="examine">注册</el-button
            >
             <el-button type="primary" v-permission="'eduEnrolStudent:editRegisterStatus'" @click="examines">取消注册</el-button>
            <el-button icon="el-icon-bottom" type="primary" @click="download">导出</el-button>
            <el-button type="primary" @click="handleSearch">查询</el-button
            >
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
              <div>
                  <el-form-item prop="name" label="姓名/学号" style="">
                    <el-input
                        v-model="search.name"
                        clearable
                        @input="() => (search.name = search.name.trim())"
                        placeholder="姓名/学号"
                        style="width: 200px"
                    ></el-input>
                  </el-form-item>
                <el-form-item label=" 函授站" prop="siteId">

                  <el-select
                      clearable
                      v-model="search.siteId"
                      placeholder="请选择"
                      class="el-form-input-width"
                  >
                    <el-option
                        v-for="item in site"
                        :key="item.id"
                        :label="item.siteName"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="年级" prop="gradeId" >

                  <el-select clearable v-model="search.gradeId"   class="el-form-input-width" placeholder="请选择">
                    <el-option
                        v-for="item in this.option"
                        :key="item.id"
                        :label="item.gradeName"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="层次" prop="stageId" >

                  <el-select clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                    <el-option
                        v-for="item in eduStage"
                        :key="item.id"
                        :label="item.stageName"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="专业" prop="profId" >

                  <el-select
                      clearable
                      v-model="search.profId"
                      placeholder="请选择"
                      class="el-form-input-width"
                  >
                    <el-option
                        v-for="item in eduProfession"
                        :key="item.id"
                        :label="item.profName"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否注册" prop="registerStatus" >

                  <el-select style="width: 200px" clearable v-model="search.registerStatus"  class="el-form-input-width" placeholder="请选择" @change="registerStatusQuery">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
<!--                <el-form-item label=" 是否缴过费" prop="isPay" >-->

<!--                  <el-select clearable v-model="search.isPay"  class="el-form-input-width" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in optionss"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
              </div>

          </div>

        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData"  border @selection-change="handleSelectionChange"
                  size="medium"
                  height="520px"
                  max-height="520px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
            <el-table-column label="序号" type="index" width="80"></el-table-column>
          <el-table-column label="姓名" prop="name">
          </el-table-column>
          <el-table-column label="学号" show-overflow-tooltip prop="studentNo"> </el-table-column>
          <el-table-column  label="证件号" show-overflow-tooltip prop="certificateNo" > </el-table-column>
          <el-table-column  label="函授站" show-overflow-tooltip prop="siteCode" > </el-table-column>
          <el-table-column  label="年级" show-overflow-tooltip prop="gradeName" >
          </el-table-column>

          <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
          <el-table-column  label="层次" show-overflow-tooltip prop="stageName" > </el-table-column>
          <el-table-column  label="专业" show-overflow-tooltip prop="profName" >
          </el-table-column>
          <el-table-column  label="学籍状态" prop="rollStatus"> </el-table-column>
          <el-table-column  label="入学时间" show-overflow-tooltip prop="entrance_time"> </el-table-column>
          <el-table-column  label="联系电话" show-overflow-tooltip prop="contactPhone"> </el-table-column>
          <!-- <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >绑定角色</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
        <!-- 导入 上传文件 -->
    <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="downloadbutton">下载模板</el-button>
        <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
        >
      </div>
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :show-file-list="false"
        :http-request="uploadFile"
        :before-upload="beforeUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传excel文件，且不超过50M
        </div>
      </el-upload>
    </el-dialog>
    <!-- 设置注册时间 -->
      <el-form :model="teacherForm">
      <el-dialog
      title="设置注册时间"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: left"
    >
    <div style="margin-bottom:1rem;margin-left: 29px">
        招生批次：
      <el-select
          style="width: 220px"
          clearable
          v-model="teacherForm.batchId"
          placeholder="请选择"
          class="el-form-input-width"
      >
        <el-option
            v-for="item in eduGraduateWorkBatch"
            :key="item.id"
            :label="item.batchName"
            :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin-bottom:1rem">
        注册开始时间： <el-date-picker
      v-model="teacherForm.registerTime"
      type="datetime"
      placeholder="选择日期时间">
    </el-date-picker>
    </div>
      <div style="margin-bottom:1rem">
        注册结束时间： <el-date-picker
      v-model="teacherForm.registerTimeEnd"
      type="datetime"
      placeholder="选择日期时间">
    </el-date-picker>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      option:[],
      eduStage:[],
      eduProfession:[],
      eduGraduateWorkBatch:[],
      total: 0,
      pageNum: 1,
      num:1,
      input:"",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload:false,
      dialogVisible:false,
      radio:'1',
      title: "",
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",

        registerStatus: "",
        isPay: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        batchId: "",
        registerTime: "",
        registerTimeEnd: "",
        },
      tableChecked: [],
      tableData: [
      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "未注册",
        },
        {
          value: "1",
          label: "已注册",
        },
      ],
      optionss: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "未缴费",
        },
        {
          value: "1",
          label: "已缴费",
        },
      ],
      value: "",
       pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        value1: '',
    };
  },
  methods: {
    handleSearch() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("info",this.search.name);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("registerStatus",this.search.registerStatus);
      fromData.append("isPay",this.search.isPay);
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolStudent/listXj?page="+this.search.page+"&pageSize="+this.search.pageSize+"&status=4",fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
    },
    handleSave(){
      let fromData = new FormData();
      fromData.append("id",this.teacherForm.batchId);
      this.teacherForm.registerTime = this.teacherForm.registerTime.formatToLocalDateTime().toString().substring(0,this.teacherForm.registerTime.formatToLocalDateTime().toString().indexOf(' '))
      this.teacherForm.registerTimeEnd = this.teacherForm.registerTimeEnd.formatToLocalDateTime().toString().substring(0,this.teacherForm.registerTimeEnd.formatToLocalDateTime().toString().indexOf(' '))
      fromData.append("registerTime", this.teacherForm.registerTime);
      fromData.append("registerTimeEnd", this.teacherForm.registerTimeEnd);
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolBatch/editTime",fromData).then(resp=>{
      if(resp.data.code==2000){
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.dialogVisible = false
        this.handleSearch()
      }else{
        this.$message({
          type: "info",
          message: "操作失败!",
        });
      }

      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduEnrolBatch/list").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    examine(){
      if (this.search.registerStatus !== "0"){
        return this.$message.error("请筛选未注册列表")
      }
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        let arrays = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        for (let j = 0; j < this.multipleSelection.length; j++) {
          arrays.push(this.multipleSelection[j].batchId);
        }
        let arr = array.toString();
        let arrs = arrays.toString();
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.openLoadingView();
            this.$axios({
              url: "hnjxjy-core/eduEnrolStudent/editRegisterStatus",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                batchId: arrs,
                status:1
              },
            }).then((res) => {
              if (res.data.code === 2000) {
                this.$message.success(res.data.message)
                this.handleSearch()
              }
            })
          });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }
    },

    examines(){
      if (this.search.registerStatus !== "1"){
        return this.$message.error("请筛选已注册列表")
      }
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduEnrolStudent/editRegisterStatus",
            headers: this.loginList,
            method: "post",
            params: {
              ids: arr,
              status:0
            },
          }).then((res) => {
            if (res.data.code === 2000) {
              this.$message.success(res.data.message)
              this.handleSearch()
            }
          })
        });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }

    },

    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportXj",
        responseType: "blob",
        headers: objPar,
        params:{
          info:this.search.name,
          gradeId:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          siteId:this.search.siteId,
          exportStatus:7,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduEnrolStudent/exportXj",
        responseType: "blob",
        headers: objPar,
        params:{
          exportStatus:6,
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '根据学号导入已注册学生导入模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    tableHeaderColor({row,column,rowIndex,columnIndex}){
      return 'color:#64666A;font-size:13px;align:center;'
    },
    //导入
    uploader(){
        this.dialogVisibleUpload=true
    },
    generation(){
        this.dialogVisible=true
    },
     beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduEnrolStudent/importZc2Xh",
        data: forms,
        headers: objPar,
      }).then((res) => {
        if(res.data.code===2000){
          this.$message.success(res.data.message)
          this.dialogVisibleUpload = false
          this.handleSearch()
        }
      })
    },
    registerStatusQuery(){
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content{
  text-align: left;
}
</style>
