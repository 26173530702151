<template>
    <div>
        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                批次管理
            </div>
            <div class="search">
                <el-form class="demo-ruleForm" inline>
                    <el-form-item>
                        <el-button icon="el-icon-circle-plus-outline" v-permission="'eduGraduateBatch:insert'" type="primary" @click="addexam()">添加</el-button>
                    </el-form-item>
                    <div>
                        <div style="width: 25%; display: inline-block">
                            <el-form-item>
                                <el-input
                                        v-model="bo.batchCodeAndName"
                                        clearable
                                        @input="() => (bo.batchCodeAndName = bo.batchCodeAndName.trim())"
                                        placeholder="批次名称/批次代码"
                                        style="width: 350px"
                                >
                                    <el-button v-permission="'eduGraduateBatch:list'" slot="append" icon="el-icon-search" @click="qurey"></el-button>
                                </el-input>

                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <div>
                    <el-table ref="multipleTable" :data="tableData" border
                              size="medium"
                              height="550px"
                              max-height="550px"
                              :header-cell-style="{'text-align':'center'}"
                              :cell-style="{'text-align':'center'}"
                    >
                        <el-table-column label="序号" width="100" type="index" header-align="center"></el-table-column>
                        <el-table-column label="毕业批次代码" show-overflow-tooltip prop="batchCode" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="毕业批次名称" show-overflow-tooltip prop="batchName" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="毕业设计开始时间" prop="projectStartTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="毕业设计结束时间" prop="projectEndTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="开题开始日期" prop="questionStartTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="开题结束日期" prop="questionEndTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="撰写论文开始日期" prop="paperStartTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="撰写论文结束日期" prop="paperEndTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="成绩上报开始日期" prop="scoreStartTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="成绩上报结束日期" prop="scoreEndTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="免试申请开始日期" prop="freeStartTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="免试申请结束日期" prop="freeEndTime" width="170"
                                         header-align="center"></el-table-column>
                        <el-table-column label="操作" fixed="right" width="200">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" v-permission="'eduGraduateBatch:update'" @click="editor(scope.row)">编辑</el-button>
                                <el-button size="mini" v-permission="'eduGraduateBatch:deleted'" type="danger" @click="del(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="[10, 30, 50, 100]"
                                :page-size="1"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>

            </div>
            <!-- 添加 -->
            <el-dialog
                    :title="this.titles"
                    :visible.sync="dialogVisibleUpload"
                    width="50%" style="margin-left: 30px"
            :before-close="handleClose">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button size="mini"  @click="off" >关闭</el-button>
                    <el-button size="mini" type="primary" @click="insertSelective">保存后关闭</el-button>
                </div>
                <el-form class="demo-ruleForm" inline size="mini" style="margin-left: 30px" :model="addBo" :rules="rules" ref="ruleForm" :validate-on-rule-change="false" label-width="120px" label-position="left">
                    <el-form-item label="批次代码" prop="batchCode">
                        <el-input v-model="addBo.batchCode" style="width: 12vw;"></el-input>
                    </el-form-item>
                    <el-form-item label="批次名称" prop="batchName">
                        <el-input v-model="addBo.batchName" style="width: 12vw;"></el-input>
                    </el-form-item>
                    <el-form-item label="开题时间" prop="questionStartTime">
                        <el-date-picker type="date" placeholder="选择日期"
                                        v-model="addBo.questionStartTime" style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="至" prop="questionEndTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.questionEndTime"
                                        style="width: 12vw;"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="毕设开始时间" prop="projectStartTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.projectStartTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="至" prop="projectEndTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.projectEndTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="论文开始时间" prop="paperStartTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.paperStartTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="至" prop="paperEndTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.paperEndTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="免试开始时间" prop="freeStartTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.freeStartTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="至" prop="freeEndTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.freeEndTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="成绩上报时间" prop="freeStartTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.scoreStartTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="至" prop="scoreEndTime">
                        <el-date-picker type="date" placeholder="选择日期" v-model="addBo.scoreEndTime"
                                        style="width: 12vw"></el-date-picker>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
<script>



    export default {

        data() {
            return {
                rules: {
                    batchCode: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    batchName: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    projectStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    projectEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    questionStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    questionEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    paperStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    paperEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    freeStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                  freeEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    scoreStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    scoreEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    assessStartTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],
                    assessEndTime: [{required: true, message: '此项为必填项', trigger: 'blur'},],

                },
                addBo: {
                    id: null,
                    batchCode: "",
                    batchName: "",
                    projectStartTime: new Date(),
                    projectEndTime: new Date(),
                    questionStartTime: new Date(),
                    questionEndTime: new Date(),
                    paperStartTime: new Date(),
                    paperEndTime: new Date(),
                    freeStartTime: new Date(),
                    freeEndTime: new Date(),
                    scoreStartTime: new Date(),
                    scoreEndTime: new Date(),
                },
                bo: {
                    page: 1,
                    pageSize: 10,
                    batchCodeAndName: ''
                },
                total: 0,
                titles: "",
                currentPage: 1,
                pageSize: 10,
                dialogVisibleUpload: false,
                tableChecked: [],
                tableData: [],
                versionList: [],
            };
        },
        mounted() {
            this.qurey();
        },
        methods: {
          off(ruleForm){
            this.$refs["ruleForm"].clearValidate();
            this.dialogVisibleUpload = false
          },
          handleClose(done) {
            this.$confirm('确认关闭？').then(_ => {
              this.$refs["ruleForm"].clearValidate();
              done();
            }).catch(_ => {
            });
          },
            insertSelective() {
                if (this.addBo.projectStartTime instanceof Date) {
                    this.addBo.projectStartTime = this.addBo.projectStartTime.formatToLocalDateTime();
                }
                if (this.addBo.projectEndTime instanceof Date) {
                    this.addBo.projectEndTime = this.addBo.projectEndTime.formatToLocalDateTime();
                }
                if (this.addBo.questionStartTime instanceof Date) {
                    this.addBo.questionStartTime = this.addBo.questionStartTime.formatToLocalDateTime();
                }
                if (this.addBo.questionEndTime instanceof Date) {
                    this.addBo.questionEndTime = this.addBo.questionEndTime.formatToLocalDateTime();
                }
                if (this.addBo.paperStartTime instanceof Date) {
                    this.addBo.paperStartTime = this.addBo.paperStartTime.formatToLocalDateTime();
                }

                if (this.addBo.paperEndTime instanceof Date) {
                    this.addBo.paperEndTime = this.addBo.paperEndTime.formatToLocalDateTime();
                }
                if (this.addBo.freeStartTime instanceof Date) {
                    this.addBo.freeStartTime = this.addBo.freeStartTime.formatToLocalDateTime();
                }
                if (this.addBo.freeEndTime instanceof Date) {
                    this.addBo.freeEndTime = this.addBo.freeEndTime.formatToLocalDateTime();
                }
                if (this.addBo.scoreStartTime instanceof Date) {
                    this.addBo.scoreStartTime = this.addBo.scoreStartTime.formatToLocalDateTime();
                }
                if (this.addBo.scoreEndTime instanceof Date) {
                    this.addBo.scoreEndTime = this.addBo.scoreEndTime.formatToLocalDateTime();
                }


                let url = "eduGraduateDesignBatch/insertSelective";
                if (this.addBo.id != null) {
                    url = "eduGraduateDesignBatch/updateByPrimaryKeySelective";
                }
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.$axios_supermall.post("/hnjxjy-core/" + url,{
                          "id": this.addBo.id,
                          "batchCode": this.addBo.batchCode,
                          "batchName": this.addBo.batchName,
                          "projectStartTime": this.addBo.projectStartTime,
                          "projectEndTime": this.addBo.projectEndTime,
                          "questionStartTime": this.addBo.questionStartTime,
                          "questionEndTime": this.addBo.questionEndTime,
                          "paperStartTime": this.addBo.paperStartTime,
                          "paperEndTime": this.addBo.paperEndTime,
                          "freeStartTime": this.addBo.freeStartTime,
                          "freeEndTime": this.addBo.freeEndTime,
                          "scoreStartTime": this.addBo.scoreStartTime,
                          "scoreEndTime": this.addBo.scoreEndTime

                        }).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message.success("操作成功!");
                                this.dialogVisibleUpload = false;
                                this.qurey();
                            }
                        })
                    }
                });

            },
            qurey() {
              this.getPermission('eduGraduateBatch:list', () => {
                this.openLoadingView();
                this.$axios_supermallData.get("/hnjxjy-core/eduGraduateDesignBatch/queryByPage?page=" + this.bo.page + "&pageSize=" + this.bo.pageSize + "&batchCodeAndName=" + this.bo.batchCodeAndName).then(resp => {
                    if (resp.data.code == 2000) {
                        this.tableData = resp.data.data.list;
                        this.total = resp.data.data.total;
                        this.loadingView.close();
                    }
                }).catch(() => {
                    this.loadingView.close();
                })
                });
            },
            handleSearch() {
            },
            handleSizeChange(val) {
                this.bo.pageSize = val;
                this.qurey();
            },
            handleCurrentChange(val) {
                this.bo.page = val;
                this.qurey();
            },
            del(row) {
                this.$confirm("确定要删除此数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios_supermall.post("/hnjxjy-core/eduGraduateDesignBatch/deleteById?id=" + row.id).then(resp => {
                        if (resp.data.code == 2000) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.qurey();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
            },
            addexam() {
                this.addBo = {
                    id: null,
                    batchCode: "",
                    batchName: "",
                    projectStartTime: "",
                    projectEndTime: "",
                    questionStartTime: "",
                    questionEndTime: "",
                    paperStartTime: "",
                    paperEndTime: "",
                    freeStartTime: "",
                    freeEndTime: "",
                    scoreStartTime: "",
                    scoreEndTime: ""
                }
                this.dialogVisibleUpload = true;
            },
            editor(row) {
              this.openLoadingView();
              this.$axios_supermall.get("hnjxjy-core/eduGraduateDesignBatch/queryById?id=" + row.id
              ).then(resp => {
               if(resp.data.code === 2000){
                 this.dialogVisibleUpload = true;
                 this.addBo = resp.data.data;
               }
              })

            }
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
