<template>
    <div>


        <div>
            <div
                    style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
            >
                学籍异动管理
            </div>
            <div class="search">
                <el-form :model="search" label-width="75px" ref="search" inline>
                    <el-form-item>
                        <el-button icon="el-icon-bottom" type="primary" v-permission="'eduStudentChange:export'"
                                   @click="download">导出
                        </el-button>
                        <el-button icon="el-icon-upload2" type="primary" @click="Batch()"
                                   v-permission="'eduStudentChange:importChange'"
                        >导入异动名单
                        </el-button
                        >
                        <el-button icon="el-icon-setting" type="primary" v-permission="'eduChange:updateEduChange'"
                                   @click="Setting()"
                        >设置学籍异动时间
                        </el-button
                        >
                        <el-button icon="el-icon-setting" v-permission="'eduStudentChange:change'" type="primary"
                                   @click="unusual()"
                        >设置学籍异动（函授站）
                        </el-button
                        >
                        <el-button icon="el-icon-setting" v-permission="'eduStudentChange:change'" type="primary"
                                   @click="reshuffle()"
                        >设置学籍异动（层次和专业）
                        </el-button
                        >
                        <el-button icon="el-icon-check" type="primary" v-permission="'eduStudentChange:editCheckStatus'"
                                   @click="examine">审核
                        </el-button>
                        <el-button icon="el-icon-close" type="primary" v-permission="'eduStudentChange:editCheckStatus'"
                                   @click="bohui">驳回
                        </el-button>
                        <el-button type="primary" @click="handleSearch" v-permission="'eduStudentChange:list'">查询
                        </el-button>
                        <el-button type="primary" @click="rest(search)">重置</el-button>
                    </el-form-item>
                    <div>
                        <el-form-item label="姓名/学号" prop="name">
                            <el-input
                                    v-model="search.name"
                                    clearable
                                    @input="() => (search.name = search.name.trim())"
                                    placeholder="姓名/学号"
                                    style="width: 220px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="函授站" prop="siteId">
                            <el-select
                                    style="width: 220px"
                                    clearable
                                    v-model="search.siteId"
                                    placeholder="请选择"
                                    class="el-form-input-width"
                            >
                                <el-option
                                        v-for="item in site"
                                        :key="item.id"
                                        :label="item.siteName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="年级" prop="gradeId">
                            <el-select clearable v-model="search.gradeId" style="width: 220px" @change="QuerySemester"
                                       class="el-form-input-width" placeholder="请选择">
                                <el-option
                                        v-for="item in this.option"
                                        :key="item.id"
                                        :label="item.gradeName"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!--            <el-form-item label="学期" prop="semester">-->
                        <!--              <el-select-->
                        <!--                  style="width: 220px"-->
                        <!--                  clearable-->
                        <!--                  v-model="search.semester"-->
                        <!--                  placeholder="请选择"-->
                        <!--                  class="el-form-input-width"-->
                        <!--              >-->
                        <!--                <el-option-->
                        <!--                    v-for="item in semesters"-->
                        <!--                    :key="item.id"-->
                        <!--                    :label="item.maxTerm"-->
                        <!--                    :value="item.id"-->
                        <!--                >-->
                        <!--                </el-option>-->
                        <!--              </el-select>-->
                        <!--            </el-form-item>-->
                        <el-form-item label="审核状态" prop="checkStatus">
                            <el-select
                                    style="width: 220px;"
                                    clearable
                                    v-model="search.checkStatus"
                                    placeholder="请选择"
                                    class="el-form-input-width"
                            >
                                <el-option
                                        v-for="item in checkStatus"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
                <!--        <div style="margin-top: 1rem; color: red; font-size: 15px">-->
                <!--          注：若学籍异动发生在考场安排之后，考场安排信息无法同步，请慎重操作！-->
                <!--        </div>-->
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                          size="medium"
                          height="520px"
                          max-height="520px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="100" type="index"></el-table-column>
                    <el-table-column label="姓名" prop="name" width="200">
                    </el-table-column>
                    <el-table-column label="学号" prop="studentNo" width="200">
                    </el-table-column>
                    <el-table-column label="原函授站" prop="orgSiteName" width="200">
                    </el-table-column>

                    <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
                    <el-table-column label="新函授站" prop="newSiteName" width="200">
                    </el-table-column>
                    <el-table-column label="原层次" prop="orgStageName" width="200">
                    </el-table-column>
                    <el-table-column label="新层次" prop="newStageName" width="180">
                    </el-table-column>
                    <el-table-column label="原专业" prop="orgProfName" width="180">
                    </el-table-column>
                    <el-table-column label="新专业" prop="newProfName" width="180">
                    </el-table-column>
                    <el-table-column label="异动时间" prop="changeTime" width="200">
                    </el-table-column>
                    <el-table-column label="审核状态" prop="checkStatus" width="200">
                    </el-table-column>
                    <el-table-column label="审核人" prop="checkPersonId" width="200">
                    </el-table-column>
                    <el-table-column label="审核时间" prop="checkTime" width="180">
                    </el-table-column>
                    <el-table-column label="驳回原因" prop="sex" width="200">
                    </el-table-column>
                    <!-- <el-table-column label="操作" width="180" fixed="right">
                      <template slot-scope="scope">
                        <el-button @click="editor(scope.row)" type="primary" size="small"
                          >上传照片</el-button
                        >
                         <el-button @click="editor(scope.row)" type="primary" size="small"
                          >编辑</el-button
                        >
                      </template>
                    </el-table-column> -->
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="search.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="search.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="this.total"
                    >
                    </el-pagination>
                </div>
            </div>
            <!-- 导入 上传文件 -->
            <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
                <div style="margin-bottom: 20px">
                    <el-button type="primary" @click="downloadbutton" v-permission="'eduStudentChange:exportMB'">下载模板
                    </el-button>
                    <span style="color: red"
                    >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
                    >
                </div>
                <el-upload
                        class="upload-demo"
                        drag
                        action="#"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        :before-upload="beforeUpload"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">
                        只能上传excel文件，且不超过50M
                    </div>
                </el-upload>
            </el-dialog>
            <el-dialog
                    title="驳回"
                    :visible.sync="dialogreasons"
                    width="30%"
            >
                <div>
                    <div style="margin-bottom: 20px; text-align: left">
                        驳回原因：
                        <el-input v-model="teacherForm.reason" placeholder="请输入内容" style="width:40%"></el-input>
                    </div>
                </div>
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogreasons = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                               @click="submit">确定
                    </el-button
                    >
                </div>
            </el-dialog>
            <el-dialog
                    title="学籍异动(函授站)"
                    :visible.sync="dialogreasonss"
                    width="30%"
            >
                <div>
                    <div style="margin-bottom: 20px; text-align: left">
                        现函授站：
                        <el-select
                                style="width: 190px"
                                disabled
                                v-model="teacherForms.siteId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in site"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px;margin-left: 11px; text-align: left">
                        函授站：
                        <el-select
                                style="width: 190px"
                                clearable
                                v-model="teacherForms.siteName"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in sites"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogreasonss = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                               @click="submithsz">确定
                    </el-button
                    >
                </div>
            </el-dialog>
            <el-dialog
                    title="学籍异动(函授站)"
                    :visible.sync="dialogreasonsss"
                    width="30%"
            >
                <div>
                    <div style="margin-bottom: 20px;margin-left: 11px; text-align: left">
                        新函授站：
                        <el-select
                                style="width: 190px"
                                clearable
                                v-model="teacherForms.siteName"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in sites"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogreasonsss = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                               @click="submithszz">确定
                    </el-button
                    >
                </div>
            </el-dialog>
            <!-- 设置学籍异动时间 -->
            <el-dialog
                    title="设置学籍异动时间"
                    :visible.sync="dialogVisible1"
                    width="30%"
                    :before-close="dialogVisible1BeforeClose"
            >
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button icon="el-icon-circle-plus-outline" type="primary" @click="updateTime">更新时间</el-button>
                    <el-button icon="el-icon-remove-outline" type="primary"
                               @click="clearTime">清空时间
                    </el-button
                    >
                </div>
                <div style="text-align: left">
                    开始时间：
                    <el-date-picker
                            v-model="editTime.beginTime"
                            type="datetime"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </div>
                <div style="text-align: left; margin-top: 10px">
                    结束时间：
                    <el-date-picker
                            v-model="editTime.endTime"
                            type="datetime"
                            placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </div>
            </el-dialog>
            <!-- 学籍异动（函授站） -->
            <el-dialog
                    title="学籍异动（函授站）"
                    :visible.sync="dialogVisible2"
                    width="65%"
            >
                <el-form :model="search" inline label-width="80px">
                    <div style="margin-bottom: 20px; text-align: left">
                        <el-button icon="el-icon-circle-close" type="primary" @click="dialogVisible2=false">关闭
                        </el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="siteId">单个异动</el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="siteIds">批量异动</el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="unusual">查询</el-button>
                    </div>
                    <div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="姓名/学号" style="margin-bottom:0;">
                                <el-input
                                        v-model="searchs.name"
                                        clearable
                                        @input="() => (searchs.name = searchs.name.trim())"
                                        placeholder="请输入内容"
                                        style="width: 200px"
                                ></el-input>

                            </el-form-item>
                        </div>
                        <div style="padding: 0.4rem; display: inline-block">
                            <el-form-item prop="siteId" label="函授站" style="margin-bottom:0;">
                                <el-select
                                        style="width: 200px"
                                        @change="unusual"
                                        clearable
                                        v-model="searchs.siteId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="item in site"
                                            :key="item.id"
                                            :label="item.siteName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style="padding: 0.4rem; display: inline-block">
                            <el-form-item prop="siteId" label="年级" style="margin-bottom:0;">
                                <el-select style="width: 200px" clearable v-model="searchs.gradeId" @change="unusual"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in this.option"
                                            :key="item.id"
                                            :label="item.gradeName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="层次" style="margin-bottom:0;">
                                <el-select style="width: 200px" clearable v-model="searchs.stageId" @change="unusual"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in eduStage"
                                            :key="item.id"
                                            :label="item.stageName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="专业" style="margin-bottom:0;">
                                <el-select
                                        style="width: 200px"
                                        @change="unusual"
                                        clearable
                                        v-model="searchs.profId"
                                        placeholder="请选择"
                                >
                                    <el-option
                                            v-for="item in eduProfession"
                                            :key="item.id"
                                            :label="item.profName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="margin-top:1rem">
                        <el-table :data="tableDatas" border style="width: 100%"
                                  @selection-change="handleSelectionChange"
                                  size="medium"
                                  max-height="400px"
                                  :header-cell-style="{'text-align':'center'}"
                                  :cell-style="{'text-align':'center'}"
                        >
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column type="index" label="序号" width="130"></el-table-column>
                            <el-table-column prop="studentNo" label="学号" width="130">
                            </el-table-column>
                            <el-table-column prop="name" label="姓名" width="180">
                            </el-table-column>
                            <el-table-column prop="siteName" label="函授站">
                            </el-table-column>
                            <el-table-column prop="stageName" label="层次">
                            </el-table-column>
                            <el-table-column prop="profName" label="专业">
                            </el-table-column>
                            <el-table-column prop="gradeName" label="年级">
                            </el-table-column>
                        </el-table>
                        <div style="padding: 1rem">
                            <el-pagination
                                    @size-change="handleSizeChange1"
                                    @current-change="handleCurrentChange1"
                                    :current-page="searchs.page"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="searchs.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="this.totals"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </el-form>
            </el-dialog>
            <!-- 学籍异动（层次和专业） -->
            <el-dialog
                    title="学籍异动（层次和专业）"
                    :visible.sync="dialogVisible3"
                    width="70%"
            >
                <el-form :model="searches" label-width="80px" inline>
                    <div style="margin-bottom: 20px; text-align: left">
                        <el-button icon="el-icon-circle-close" type="primary" @click="dialogVisible3=false">关闭
                        </el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="siteIdandPfo">单个异动
                        </el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="siteIdandPfos">批量异动
                        </el-button>
                        <el-button icon="el-icon-circle-plus-outline" type="primary" @click="reshuffle">查询</el-button>
                    </div>
                    <div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="姓名/学号" style="margin-bottom:0;">
                                <el-input
                                        v-model="searches.name"
                                        clearable
                                        @input="() => (searches.name = searches.name.trim())"
                                        placeholder="请输入内容"
                                        style="width: 200px"
                                ></el-input>

                            </el-form-item>
                        </div>
                        <div style="padding: 0.4rem; display: inline-block">
                            <el-form-item prop="siteId" label="函授站" style="margin-bottom:0;">
                                <el-select
                                        style="width: 200px"
                                        @change="reshuffle"
                                        clearable
                                        v-model="searches.siteId"
                                        placeholder="请选择"
                                        class="el-form-input-width"
                                >
                                    <el-option
                                            v-for="item in site"
                                            :key="item.id"
                                            :label="item.siteName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style="padding: 0.4rem; display: inline-block">
                            <el-form-item prop="siteId" label="年级" style="margin-bottom:0;">
                                <el-select style="width: 200px" clearable v-model="searches.gradeId" @change="reshuffle"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in this.option"
                                            :key="item.id"
                                            :label="item.gradeName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="层次" style="margin-bottom:0;">
                                <el-select style="width: 200px" clearable v-model="searches.stageId" @change="reshuffle"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in eduStage"
                                            :key="item.id"
                                            :label="item.stageName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div style=" display: inline-block">
                            <el-form-item prop="siteId" label="专业" style="margin-bottom:0;">
                                <el-select
                                        style="width: 200px"
                                        @change="reshuffle"
                                        clearable
                                        v-model="searches.profId"
                                        placeholder="请选择"
                                >
                                    <el-option
                                            v-for="item in eduProfession"
                                            :key="item.id"
                                            :label="item.profName"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="margin-top:1rem">
                        <el-table :data="tableDataes" border style="width: 100%"
                                  @selection-change="handleSelectionChange"
                                  size="medium"
                                  max-height="400px"
                                  :header-cell-style="{'text-align':'center'}"
                                  :cell-style="{'text-align':'center'}"
                        >
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column type="index" label="序号" width="130"></el-table-column>
                            <el-table-column prop="studentNo" label="学号" width="130">
                            </el-table-column>
                            <el-table-column prop="name" label="姓名" width="180">
                            </el-table-column>
                            <el-table-column prop="siteName" label="函授站">
                            </el-table-column>
                            <el-table-column prop="stageName" label="层次">
                            </el-table-column>
                            <el-table-column prop="profName" label="专业">
                            </el-table-column>
                            <el-table-column prop="gradeName" label="年级">
                            </el-table-column>
                        </el-table>
                        <div style="padding: 1rem">
                            <el-pagination
                                    @size-change="handleSizeChange2"
                                    @current-change="handleCurrentChange2"
                                    :current-page="searches.page"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="searches.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="this.totales"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </el-form>
            </el-dialog>
            <el-dialog
                    title="学籍异动（层次和专业）"
                    :visible.sync="dialogCCZY"
                    width="30%"
            >
                <div>
                    <div style="margin-bottom: 20px; margin-left: 15px; text-align: left">
                        现层次：
                        <el-select disabled clearable v-model="teacherForms.stageId" style="width: 190px"
                                   class="el-form-input-width" placeholder="请选择">
                            <el-option
                                    v-for="item in eduStage"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px;margin-left: 15px; text-align: left">
                        现专业：
                        <el-select
                                disabled
                                style="width: 190px"
                                clearable
                                v-model="teacherForms.profId"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduProfession"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px;margin-left: 11px; text-align: left">
                        新层次：
                        <el-select clearable v-model="teacherForms.stageName" style="width: 190px"
                                   class="el-form-input-width" placeholder="请选择">
                            <el-option
                                    v-for="item in eduStages"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 20px;margin-left: 11px; text-align: left">
                        新专业：
                        <el-select
                                style="width: 190px"
                                clearable
                                v-model="teacherForms.profName"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduProfessions"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogCCZY = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                               @click="submithscczy">确定
                    </el-button
                    >
                </div>
            </el-dialog>
            <el-dialog
                    title="学籍异动（层次和专业）"
                    :visible.sync="dialogCCZYs"
                    width="30%"
            >
                <div>
                    <div style="margin-bottom: 20px;margin-left: 11px; text-align: left">
                        新专业
                        <el-select
                                style="width: 200px"
                                clearable
                                v-model="teacherForms.profName"
                                placeholder="请选择"
                                class="el-form-input-width"
                        >
                            <el-option
                                    v-for="item in eduProfession"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button @click="dialogCCZYs = false"
                    >关闭
                    </el-button
                    >
                    <el-button type="primary"
                               @click="submithszs">确定
                    </el-button
                    >
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>


    export default {

        created() {
            this.queryEduStage();
            this.queryEduStages();
            this.queryEduProfession();
            this.queryEduProfessions();
            this.queryEduSite();
            this.queryEduSites();
            this.gradList();
            this.handleSearch();
        },
        data() {
            return {
                site: [],
                sites: [],
                option: [],
                eduStage: [],
                eduStages: [],
                eduProfession: [],
                eduProfessions: [],
                semesters: [],
                checkStatus: [
                    {
                        value: 0,
                        label: "等待审核",
                    },
                    {
                        value: 1,
                        label: "审核通过",
                    },
                    {
                        value: 2,
                        label: "驳回",
                    },
                ],
                total: 0,
                totals: 0,
                totales: 0,
                pageNum: 1,
                num: 1,
                input: "",
                pageSize: 10,
                textarea: "",
                radio: "1",
                title: "",
                dialogVisibleUpload: false,
                dialogreasons: false,
                dialogreasonss: false,
                dialogreasonsss: false,
                dialogCCZY: false,
                dialogCCZYs: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                search: {
                    name: "",
                    siteId: "",
                    gradeId: "",
                    profId: "",
                    checkStatus: "",
                    semester: "",
                    stageId: "",
                    page: 1,
                    pageSize: 10,
                },
                searchs: {
                    name: "",
                    siteId: "",
                    gradeId: "",
                    profId: "",
                    checkStatus: "",
                    semester: "",
                    stageId: "",
                    page: 1,
                    pageSize: 10,
                },
                searches: {
                    name: "",
                    siteId: "",
                    gradeId: "",
                    profId: "",
                    checkStatus: "",
                    semester: "",
                    stageId: "",
                    page: 1,
                    pageSize: 10,
                },
                teacherForm: {
                    reason: ""
                },
                teacherForms: {
                    siteId: "",
                    siteName: "",
                    id: "",
                    stageId: "",
                    stageName: "",
                    profName: "",
                    profId: "",
                },
                editTime: {
                    beginTime: "",
                    endTime: "",
                },
                tableChecked: [],
                tableDatas: [],
                tableDataes: [],
                tableData: [],
                versionList: [
                    {
                        value: 0,
                        label: "旧版",
                    },
                    {
                        value: 1,
                        label: "新版",
                    },
                ],
                currentPage4: 4,
                currentPage1: 4,
                options: [],
                value: "",
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "今天",
                            onClick(picker) {
                                picker.$emit("pick", new Date());
                            },
                        },
                        {
                            text: "昨天",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24);
                                picker.$emit("pick", date);
                            },
                        },
                        {
                            text: "一周前",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", date);
                            },
                        },
                    ],
                },
                value1: "",
            };
        },
        methods: {
            handleSearch() {
                this.getPermission('eduStudentCourse:list', () => {
                    this.openLoadingView()
                    this.$axios_supermall.post("/hnjxjy-core/eduStudentChange/list?page=" + this.search.page + "&pageSize=" + this.search.pageSize,
                        {
                            "siteId": this.search.siteId,
                            "gradeId": this.search.gradeId,
                            "semester": this.search.semester,
                            "checkStatus": this.search.checkStatus,
                            "info": this.search.name,
                            "stageId": this.search.stageId,
                            "profId": this.search.profId,
                        }
                    ).then(resp => {
                        this.tableData = resp.data.data.list; //表格列表
                        this.total = resp.data.data.total;
                    })
                });
            },
            rest(form) {
                this.$refs.search.resetFields()
            },
            //查询年级
            gradList() {
                this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.option = resp.data.data;
                    }
                })
            },
            //查询专业
            queryEduProfession() {
                this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduProfession = resp.data.data;
                    }
                })
            },
            queryEduProfessions() {
                this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduProfessions = resp.data.data;
                    }
                })
            },

            //查询函授站下拉
            queryEduSite() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            //查询函授站下拉
            queryEduSites() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            //查询层次
            queryEduStage() {
                this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduStage = resp.data.data;
                    }
                })
            },
            queryEduStages() {
                this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
                    if (resp.data.code == 2000) {
                        this.eduStages = resp.data.data;
                    }
                })
            },
            //查询学期
            QuerySemester() {
                this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId=" + this.search.gradeId).then(resp => {
                    if (resp.data.code == 2000) {
                        this.semesters = []
                        for (let i = resp.data.data.maxTerm; i > 0; i--) {
                            let maxTerm = "第" + this.numberfilter(i) + "学期";
                            this.semesters.push({id: i, maxTerm: maxTerm});
                        }
                    }
                })
            },
            // 阿拉伯数字 转中文小写数字
            numberfilter(num) {
                const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
                const unit = ['', '十', '百']
                num = parseInt(num)
                const getWan = (temp) => {
                    const strArr = temp.toString().split('').reverse()
                    let newNum = ''
                    for (let i = 0; i < strArr.length; i++) {
                        newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
                    }
                    return newNum
                }
                const overWan = Math.floor(num / 100)
                let noWan = num % 100
                if (noWan.toString().length < 2) noWan = '0' + noWan
                let strr = (overWan ? getWan(overWan) + '百' + getWan(noWan) : getWan(num))
                if (strr.split('')[0] == '一' && strr.split('')[1] == '十') {
                    return strr.substring(1)
                } else {
                    return overWan ? getWan(overWan) + '百' + getWan(noWan) : getWan(num)
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            examine() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                    let array = [];
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        array.push(this.multipleSelection[i].id);
                    }
                    let arr = array.toString();
                    this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.$axios({
                            url: "hnjxjy-core/eduStudentChange/editCheckStatus",
                            headers: this.loginList,
                            method: "put",
                            params: {
                                ids: arr,
                                status: 1
                            },
                        }).then((res) => {
                            if (res.data.code == 2000) {
                                this.$message({
                                    type: "success",
                                    message: "操作成功!",
                                });
                                this.openLoadingView();
                                this.handleSearch()
                            }
                        })

                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "操作失败",
                                });
                            });
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一条数据!",
                    });
                }
            },
            clearTime() {
                this.editTime.endTime = ""
                this.editTime.beginTime = ""
            },
            dateFormatting(value, format) {
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                let h = date.getHours();
                h = h < 10 ? "0" + h : h;
                let m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
                let s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                return y + format + MM + format + d + " " + h + ":" + m + ":" + s;
            },
            updateTime() {
                this.editTime.endTime = this.dateFormatting(this.editTime.endTime, "-")
                this.editTime.beginTime = this.dateFormatting(this.editTime.beginTime, "-")
                this.$axios_supermall.post("/hnjxjy-core/eduChange/updateEduChange",
                    {
                        "endTime": this.editTime.endTime,
                        "beginTime": this.editTime.beginTime,
                    }
                ).then(resp => {
                    if (resp.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功"
                        })
                        this.dialogVisible1 = false
                    } else {
                        this.$message({
                            type: "error",
                            message: "操作失败"
                        })
                    }
                    this.editTime.endTime = ""
                    this.editTime.beginTime = ""
                })
            },
            bohui() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    this.teacherForm.reason = ""
                    this.dialogreasons = true;
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一条数据!",
                    });
                }
            },
            submit() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios({
                        url: "hnjxjy-core/eduStudentChange/editCheckStatus",
                        headers: this.loginList,
                        method: "put",
                        params: {
                            ids: arr,
                            status: 2,
                            reason: this.teacherForm.reason
                        },
                    }).then((res) => {
                        if (res.data.code == 2000) {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                            this.dialogreasons = false
                            this.handleSearch()
                        }
                    })

                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "操作失败",
                            });
                        });
                });
            },
            siteId() {
                setTimeout(() => {
                    this.openLoadingView();
                }, 50);
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    if (this.multipleSelection.length > 1) {
                        this.$message({
                            type: "error",
                            message: "只能选择一个"
                        })
                        this.loadingView.close();
                    } else {
                        let array = [];
                        for (let i = 0; i < this.multipleSelection.length; i++) {
                            array.push(this.multipleSelection[i].id);
                        }
                        let arr = array.toString();
                        this.$axios_supermall.get("hnjxjy-core/eduStudentInfo/selectById?id=" + arr
                        ).then(resp => {
                            this.teacherForms.siteId = resp.data.data.siteId
                        })
                        this.loadingView.close();
                        this.dialogreasonss = true
                        this.teacherForms.siteName = ""
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一个"
                    })
                    this.loadingView.close();
                }
            },
            siteIds() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    if (this.searchs.gradeId != null && this.searchs.gradeId != "" && this.searchs.stageId != null && this.searchs.stageId != "") {
                        this.teacherForms.siteName = ""
                        this.dialogreasonsss = true
                    } else {
                        this.$message({
                            type: "error",
                            message: "批量异动需要选择年级、层次！"
                        })
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一个！"
                    })
                }
            },
            siteIdandPfo() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    if (this.multipleSelection.length > 1) {
                        this.$message({
                            type: "error",
                            message: "只能选择一个"
                        })
                    } else {
                        let array = [];
                        for (let i = 0; i < this.multipleSelection.length; i++) {
                            array.push(this.multipleSelection[i].id);
                        }
                        let arr = array.toString();
                        this.$axios_supermall.get("hnjxjy-core/eduStudentInfo/selectById?id=" + arr
                        ).then(resp => {
                            this.teacherForms.stageId = resp.data.data.stageId
                            this.teacherForms.profId = resp.data.data.profId
                        })
                        this.teacherForms.profName = "";
                        this.teacherForms.stageName = "";
                        this.dialogCCZY = true
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一个"
                    })
                }
            },
            siteIdandPfos() {
                if (this.multipleSelection != null && this.multipleSelection != "") {
                    if (this.searches.gradeId != null && this.searches.gradeId != "" && this.searches.stageId != null && this.searches.stageId != "") {
                        this.dialogCCZYs = true
                        this.teacherForms = {}
                    } else {
                        this.$message({
                            type: "error",
                            message: "批量异动需要选择年级、层次！"
                        })
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: "请至少选择一个！"
                    })
                }
            },
            submithsz() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.openLoadingView()
                this.$axios({
                    url: "hnjxjy-core/eduStudentChange/change",
                    headers: this.loginList,
                    method: "put",
                    params: {
                        ids: arr,
                        siteId: this.teacherForms.siteName
                    },
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.loadingView.close();
                        this.dialogreasonss = false
                        this.dialogVisible2 = false;
                        this.handleSearch()
                    }
                })

                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作失败",
                        });
                    });
            },
            submithscczy() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.$axios({
                    url: "hnjxjy-core/eduStudentChange/change",
                    headers: this.loginList,
                    method: "put",
                    params: {
                        ids: arr,
                        profId: this.teacherForms.profName,
                        stageId: this.teacherForms.stageName
                    },
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.dialogCCZY = false
                        this.reshuffle()
                    }
                })

                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作失败",
                        });
                    });
            },
            submithszz() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.$axios({
                    url: "hnjxjy-core/eduStudentChange/change",
                    headers: this.loginList,
                    method: "put",
                    params: {
                        ids: arr,
                        siteId: this.teacherForms.siteName,
                        stageId: this.searchs.stageId,
                        gradeId: this.searchs.gradeId
                    },
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.dialogreasonsss = false
                        this.unusual()
                    }
                })

                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作失败",
                        });
                    });
            },
            submithszs() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let array = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    array.push(this.multipleSelection[i].id);
                }
                let arr = array.toString();
                this.$axios({
                    url: "hnjxjy-core/eduStudentChange/change",
                    headers: this.loginList,
                    method: "put",
                    params: {
                        ids: arr,
                        profId: this.teacherForms.profName,
                        stageId: this.searches.stageId,
                        gradeId: this.searches.gradeId
                    },
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message({
                            type: "success",
                            message: "操作成功!",
                        });
                        this.dialogCCZYs = false
                        this.reshuffle()
                    }
                })

                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作失败",
                        });
                    });
            },
            download() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentChange/export",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        gradeId: this.search.gradeId,
                        siteId: this.search.siteId,
                        checkStatus: this.search.checkStatus,
                        info: this.search.name,
                        semester: this.search.semester,
                    }
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let url = window.URL.createObjectURL(blob);
                    window.location.href = url;
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            handleSizeChange(val) {
                this.search.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.search.page = val;
                this.handleSearch();
            },
            handleSizeChange1(val) {
                this.searchs.pageSize = val;
                this.unusual();
            },
            handleCurrentChange1(val) {
                this.searchs.page = val;
                this.unusual();
            },
            handleSizeChange2(val) {
                this.searches.pageSize = val;
                this.reshuffle();
            },
            handleCurrentChange2(val) {
                this.searches.page = val;
                this.reshuffle();
            },
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "post",
                    url: "hnjxjy-core/eduStudentChange/importChange",
                    data: forms,
                    headers: objPar,
                }).then((res) => {
                    if (res.data.code == 2000) {
                        this.$message.success("操作成功")
                        this.dialogVisibleUpload = false
                        this.handleSearch()
                    }
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },
            downloadbutton() {
                let objPar = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    method: "get",
                    url: "hnjxjy-core/eduStudentChange/exportMB",
                    responseType: "blob",
                    headers: objPar,
                    params: {
                        exportStatus: 6,
                    }
                }).then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入异动名单模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功");
                    this.loadingView.close();
                }).catch((err) => {
                    this.$message.error("操作失败");
                    this.loadingView.close();
                });
            },

            Batch() {
                this.dialogVisibleUpload = true;
            },
            // 异动设置查询
            queryEduChange1() {
                this.openLoadingView();
                this.$axios_supermallData({
                    method: "get",
                    url: "hnjxjy-core/eduChange/queryEduChange2",
                })
                    .then(resp => {
                        if (resp.data.code == 2000) {
                            this.editTime.beginTime = resp.data.data.beginTime;
                            this.editTime.endTime = resp.data.data.endTime;
                            this.loadingView.close();
                        } else {
                            this.loadingView.close();
                        }
                    })
                    .catch(err => {
                        this.loadingView.close();
                    });
            },
            //设置学籍异动
            Setting() {
                this.dialogVisible1 = true;
                setTimeout(() => {
                    this.openLoadingView();
                }, 100);
                setTimeout(() => {
                    this.queryEduChange1();
                    this.loadingView.close();
                }, 1000);
            },
            dialogVisible1BeforeClose(done) {
                done();
                this.editTime.endTime = ""
                this.editTime.beginTime = ""
            },
            //设置学籍异动（函授站）
            unusual() {
                setTimeout(() => {
                    this.openLoadingView()
                }, 50);
                let fromData = new FormData();
                fromData.append("siteId", this.searchs.siteId);
                fromData.append("gradeId", this.searchs.gradeId);
                fromData.append("stageId", this.searchs.stageId);
                fromData.append("profId", this.searchs.profId);
                fromData.append("info", this.searchs.name);
                fromData.append("page", this.searchs.page);
                fromData.append("pageSize", this.searchs.pageSize);
                this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list", fromData).then(resp => {
                    this.tableDatas = resp.data.data.list; //表格列表
                    this.totals = resp.data.data.total;
                })
                this.dialogVisible2 = true;
            },
            reshuffle() {
                this.openLoadingView()
                let fromData = new FormData();
                fromData.append("siteId", this.searches.siteId);
                fromData.append("gradeId", this.searches.gradeId);
                fromData.append("stageId", this.searches.stageId);
                fromData.append("profId", this.searches.profId);
                fromData.append("info", this.searches.name);
                fromData.append("page", this.searches.page);
                fromData.append("pageSize", this.searches.pageSize);
                this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list", fromData).then(resp => {
                    this.tableDataes = resp.data.data.list; //表格列表
                    this.totales = resp.data.data.total;
                })
                this.dialogVisible3 = true;
            }
        },
    };
</script>
<style scoped>
</style>
