<template>
  <div>


    <div>
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        退学管理
      </div>
      <div class="search">
        <el-form :model="search" ref="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduStudentQuit:export'">导出</el-button>
            <el-button icon="el-icon-upload2" type="primary" v-permission="'eduStudentQuit:importData'" @click="Batch()"
            >导入退学名单</el-button
            >
            <el-button icon="el-icon-setting" type="primary" v-permission="'eduStudentQuit:editQuit'" @click="unusual()"
            >设置退学</el-button
            >
            <el-button icon="el-icon-bottom" type="primary" v-permission="'eduStudentQuit:editRecovery'" @click="editRecovery">复学</el-button>
            <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
              >设置学籍异动（层次和专业）</el-button
            > -->
            <!-- <el-button icon="el-icon-check" type="primary">审核</el-button> -->
            <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
            <el-button type="primary" @click="handleSearch" v-permission="'eduStudentQuit:list'">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
            <div style="display: inline-block">
              <el-form-item prop="name" label="姓名/学号" style="margin-bottom: 0">
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 220px"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="函授站" prop="siteId" style="margin-bottom:0;margin-left:2rem">

              <el-select
                  @change="handleSearch"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="gradeId" style="margin-bottom:0;margin-left:2rem">

              <el-select clearable v-model="search.gradeId" @change="handleSearch"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" prop="stageId" style="margin-bottom:0;margin-left:2rem">

              <el-select @change="handleSearch" clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" width="100" prop="id"></el-table-column>
          <el-table-column label="姓名" prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="180">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" prop="stageName" width="180">
          </el-table-column>
          <el-table-column label="专业" prop="profName" width="180">
          </el-table-column>
          <el-table-column label="退学时间" prop="quitTime" width="180">
          </el-table-column>
          <el-table-column label="复学时间" prop="recoveryTime" width="180">
          </el-table-column>
          <el-table-column label="操作人" prop="loginAccount" width="180">
          </el-table-column>
          <el-table-column label="退学原因" show-overflow-tooltip prop="quitReason" width="180">
          </el-table-column>
          <el-table-column label="操作" width="130" fixed="right">
            <template slot-scope="scope">
              <div style="color: #1890ff;cursor: pointer;" @click="reshuffle(scope.row)">
                查看成绩
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[2, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton" v-permission="'eduStudentQuit:exportMB'">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <!-- 查看成绩 -->
      <el-dialog
          title="查看成绩"
          :visible.sync="dialogVisible1"
          width="60%"
      >
        <!-- <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
          <el-button type="primary"
            >设置休学</el-button
          >
        </div> -->
        <div style="text-align: left">
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            姓名：{{ selects.name }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            学号：{{ this.selects.studentNo }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            教学点：{{ this.selects.siteName }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            退学时间：{{ this.selects.quitTime }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            专业：{{ this.selects.profName }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            层次： {{ this.selects.stageName }}
          </div>
        </div>
        <div style="margin-top:1rem">
          <el-table :data="versionList" border style="width: 100%"
                    size="medium"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column prop="courseName" label="课程名称" >
            </el-table-column>
            <el-table-column prop="courseCode" label="课程编号">
            </el-table-column>
            <el-table-column prop="score" label="总评成绩" >
            </el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <!--设置休学学生 -->
      <el-dialog
          title="设置退学学生"
          :visible.sync="dialogVisible2"
          width="60%"
      >
        <div style=" text-align: left">
          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
          <el-button type="primary" @click="editPause" style="margin-bottom: 10px">设置退学</el-button>
          <el-button type="primary" @click="unusual" style="margin-bottom: 10px">查询</el-button>
        </div>

        <div>
          <el-form :model="search" ref="search" label-width="90px" inline>
            <div>
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="函授站" prop="siteId" >
                <el-select
                    @change="unusual"
                    style="width: 220px"
                    clearable
                    v-model="search.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年级" prop="gradeId" >
                <el-select clearable style="width: 220px" v-model="search.gradeId" @change="unusual"  class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次" prop="stageId" >
                <el-select @change="unusual" style="width: 220px" clearable v-model="search.stageId"  placeholder="请选择">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >
                <el-select
                    @change="unusual"
                    style="width: 220px"
                    clearable
                    v-model="search.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div style="margin-top:1rem">
          <el-table :data="tableDatas" border style="width: 100%" @selection-change="handleSelectionChange"
                    size="medium"
                    max-height="400px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" label="序号" width="130"></el-table-column>
            <el-table-column prop="studentNo" label="学号" width="130">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column  prop="siteName" label="函授站" width="200">
            </el-table-column>
            <el-table-column prop="stageName" label="层次">
            </el-table-column>
            <el-table-column prop="profName" label="专业" >
            </el-table-column>
            <el-table-column prop="gradeName" label="年级">
            </el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totals"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <!--   设置休学   -->
      <el-dialog
          title="设置退学原因"
          :visible.sync="dialogreasons"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">
            原因：
            <el-input v-model="teacherForm.reason" type="textarea" placeholder="请输入内容" style="width:40%"></el-input>
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasons = false"
          >关闭</el-button
          >
          <el-button type="primary"
                     @click="submit">确定</el-button
          >
        </div>
      </el-dialog>
      <!--   复学   -->
      <el-dialog
          title="复学"
          :visible.sync="dialogreasonss"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">

            <span style="margin-left: 20px">年级</span>
            <el-select clearable v-model="versionLists.gradeId" style="width: 200px;margin-left: 15px"  class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.option"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="margin-left: 550px">学学籍状态</span>
            <el-select clearable v-model="versionLists.xueji" style="width: 200px;margin-left: 10px"  class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div >
          <div style="margin-bottom: 20px;margin-left: 105px ">
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasonss = false">关闭</el-button>
          <el-button type="primary"
                     @click="submits">确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduSite();
    this.queryEduProfession();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      option:[],
      eduStage:[],
      eduProfession:[],
      selects:{
        name:"",
        studentNo:"",
        siteName:"",
        profName:"",
        stageName:"",
        quitTime:"",
      },
      total: 0,
      totals: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogreasons:false,
      dialogreasonss:false,
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        profId: "",
        stageId: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        reason: "",
        url: "",
      },
      tableChecked: [],
      tableDatas: [],
      tableData: [
      ],
      versionList: [],
      versionLists:{
        gradeId:"",
        xueji:""
      },
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "正式生",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      this.$axios_supermall.post("/hnjxjy-core/eduStudentQuit/list",
          {
            "siteId":this.search.siteId,
            "gradeId":this.search.gradeId,
            "stageId":this.search.stageId,
            "info":this.search.name,
            "page":this.search.page,
            "pageSize":this.search.pageSize,
          }
      ).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduStudentQuit/importData",
        data: forms,
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        if(res.data.type == "application/json"){
          this.$message.success("操作成功")
          this.dialogVisibleUpload = false
          this.handleSearch()
          this.loadingView.close();
        }else{
          this.$message.error("操作失败")
          this.dialogVisibleUpload = false
          this.loadingView.close();
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
        }
      }).catch((err) => {
        this.$message.error("操作失败")
        this.dialogVisibleUpload = false
        this.loadingView.close();
      });
    },



    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentQuit/exportMB",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '导入退学名单模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
//导入休学
    Batch() {
      this.dialogVisibleUpload = true;
    },
    unusual() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list",fromData).then(resp=>{
        this.tableDatas = resp.data.data.list; //表格列表
        this.totals = resp.data.data.total;
      })
      this.dialogVisible2 = true;
    },

    editRecovery(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        if(this.search.gradeId!=null&&this.search.gradeId!=""){
          this.dialogreasonss = true
          this.versionLists ={}
        }else{
          this.$message({
            type: "error",
            message: "请选择年级!",
          });
        }
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }
    },

    reshuffle(row){
      this.dialogVisible1 = true
      this.versionList=[]
      this.$axios_supermall.get("/hnjxjy-core/eduStudentScoreStat/selectAchievement?studentId="+row.studentId,
      ).then(resp=>{
        this.versionList=resp.data.data
      })
      this.selects.name = row.name;
      this.selects.studentNo = row.studentNo;
      this.selects.siteName = row.siteName;
      this.selects.profName = row.profName;
      this.selects.stageName = row.stageName;
      this.selects.quitTime = row.quitTime;
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    editPause(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.dialogreasons = true
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentQuit/export",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    photo(){
      this.$axios_supermall.post("/hnjxjy-core/upload/uploadPictures",).then(resp=>{
      })
    },
    submit(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduStudentQuit/editQuit",
          headers: this.loginList,
          method: "post",
          params: {
            ids: arr,
            reason:this.teacherForm.reason
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.dialogreasons = false;
            this.handleSearch()
          }
        })
    },
    submits(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      if(this.versionLists.gradeId != null && this.versionLists.gradeId != ""){
        this.$axios({
          url: "hnjxjy-core/eduStudentQuit/editRecovery",
          headers: this.loginList,
          method: "put",
          params: {
            ids: arr,
            gradeId:this.search.gradeId,
            gradeId2:this.versionLists.gradeId,
            // xueji:this.versionLists.xueji
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.dialogreasonss = false
            this.handleSearch()
          }
        })
      }else{
        this.$message.error("年级不能为空")
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
      this.search.pageSize = val;
      this.unusual();
    },
    handleCurrentChange1(val) {
      this.search.page = val;
      this.unusual();
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>

