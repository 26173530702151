<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        交互成绩管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" v-permission="'eduScoreMutualRule:export'" @click="download">导出</el-button>
            <el-button icon="el-icon-setting" v-permission="'eduScoreMutualRule:updateRules'" type="primary" @click="addexam()"
              >设置在线交互成绩生成规则</el-button
            >
            <el-button icon="el-icon-check" v-permission="'eduScoreMutualRule:generateOnlineInteractiveScore'" type="primary"
              @click="editStatus">生成在线交互成绩</el-button
            >
          </el-form-item>
          <div>
            <div style="width: 25%; display: inline-block">
              <el-form-item>
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="课程名称/课程编号"
                  style="width: 250px"
                ></el-input>
                <el-button
                    v-permission="'eduStudentCourse:list'"
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="550px"
                  max-height="550px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="课程编号" prop="courseCode"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName"> </el-table-column>
          <el-table-column label="在线交互成绩生成规则" prop="onlineInteractiveRule">
          </el-table-column>
          <el-table-column
            label="使用电子教材时在线交互成绩生成规则"
            prop="electronicsOnlineInteractiveRule"
          >
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <el-form :model="teacherForm">
      <el-dialog
        title="设置在线交互成绩生成规则"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >

        <div style="margin-bottom: 20px; text-align: left">
          <el-radio v-model="radio" label="1">交互成绩规则</el-radio>
          <el-radio v-model="radio" label="2"
            >选购电子教材时的交互成绩规则</el-radio
          >
        </div>
        <div v-if="radio==1">
             <div style="margin-bottom: 20px; text-align: left">
          学习进度：<el-select v-model="teacherForm.learningRate" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
          <div style="margin-bottom: 20px; text-align: left">
            在线作业：<el-select v-model="teacherForm.onlineWork" placeholder="请选择">
            <el-option
                v-for="item in optionss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
          </div>
        </div>
       <div v-if="radio==2">
             <div style="margin-bottom: 20px; text-align: left">
          学习进度：<el-select v-model="teacherForm.learningRates" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
         <div style="margin-bottom: 20px; text-align: left">
           在线作业：<el-select v-model="teacherForm.onlineWorks" placeholder="请选择">
           <el-option
               v-for="item in optionss"
               :key="item.value"
               :label="item.label"
               :value="item.value"
           >
           </el-option>
         </el-select>
         </div>
        <div style="margin-bottom: 20px; text-align: left">
          电子教材：<el-select v-model="teacherForm.electronicsTeachMate" placeholder="请选择">
            <el-option
              v-for="item in optionsss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogVisibleUpload = false">关闭</el-button>
          <el-button type="primary" @click="sure">确定</el-button>
        </div>
      </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>


export default {

  created() {
      this.handleSearch()
    },
  data() {
    return {
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        id:"",
        name: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        learningRate:"",
        learningRates:"",
        onlineWork:"",
        electronicsTeachMate:"",
        onlineWorks:"",
        status:"",
      },
      tableChecked: [],
      tableData: [],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      optionss: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      optionsss: [
        {
          value: "0%",
          label: "0%",
        },
        {
          value: "5%",
          label: "5%",
        },
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "15%",
          label: "15%",
        },
        {
          value: "20%",
          label: "20%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "30%",
          label: "30%",
        },
        {
          value: "35%",
          label: "35%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "45%",
          label: "45%",
        },
        {
          value: "50%",
          label: "50%",
        },
        {
          value: "55%",
          label: "55%",
        },
        {
          value: "60%",
          label: "60%",
        },
        {
          value: "65%",
          label: "65%",
        },
        {
          value: "70%",
          label: "70%",
        },
        {
          value: "75%",
          label: "75%",
        },
        {
          value: "80%",
          label: "80%",
        },
        {
          value: "85%",
          label: "85%",
        },
        {
          value: "90%",
          label: "90%",
        },
        {
          value: "95%",
          label: "95%",
        },
        {
          value: "100%",
          label: "100%",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView();
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreMutualRule/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreMutualRule/export",
        responseType: "blob",
        headers: objPar,
        params:{
          info:this.search.name,
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editStatus() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduScoreMutualRule/generateOnlineInteractiveScore",
            headers: this.loginList,
            method: "get",
            params: {
              ids: arr,
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          })
              // this.getList()
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    sure(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      let learningRate = parseInt(this.teacherForm.learningRate.substring(0,this.teacherForm.learningRate.lastIndexOf("%")))
      let onlineWork = parseInt(this.teacherForm.onlineWork.substring(0,this.teacherForm.onlineWork.lastIndexOf("%")));
      let electronicsTeachMate = parseInt(this.teacherForm.electronicsTeachMate.substring(0,this.teacherForm.electronicsTeachMate.lastIndexOf("%")));
      let learningRates = parseInt(this.teacherForm.learningRates.substring(0,this.teacherForm.learningRates.lastIndexOf("%")));
      let onlineWorks = parseInt(this.teacherForm.onlineWorks.substring(0,this.teacherForm.onlineWorks.lastIndexOf("%")));
      if(this.radio!=1){
        if(learningRates+onlineWorks+electronicsTeachMate!=100){
          this.$message({
            type: "error",
            message: "三者之和必须等于100%!",
          });
        }else{
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScoreMutualRule/updateRules",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status:2,
                learningRate:learningRates,
                onlineWork:onlineWorks,
                electronicsTeachMate:electronicsTeachMate,
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
              this.dialogVisibleUpload =false
            }).catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
          });
        }
      }else{
        if(learningRate+onlineWork!=100){
          this.$message({
            type: "error",
            message: "两者之和必须等于100%!",
          });
        }else{
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScoreMutualRule/updateRules",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status:1,
                learningRate:learningRate,
                onlineWork:onlineWork,
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
              this.dialogVisibleUpload =false
            }).catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
          });
        }
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {

      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.dialogVisibleUpload = true;
      }else{
        this.$message({
          type: "error",
          message: "至少选择一条数据!",
        });
      }
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
