<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        免考成绩录入
      </div>
      <div class="search">
        <el-form :model="search" ref="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" v-permission="'eduScoreFree:exportEduScoreFreeExcel'" @click="download" type="primary"
              >导出免考成绩</el-button
            >
            <el-button icon="el-icon-download" type="primary" v-permission="'eduScoreFree:exportExcelCourse'" @click="opendilog">导入免考学生</el-button>
            <el-button icon="el-icon-download" type="primary" @click="opendilogs">导入免考成绩</el-button>
            <el-button icon="el-icon-edit" type="primary" @click="edition"
              >添加免考学生</el-button
            >
            <el-button type="primary" @click="handleSearch" v-permission="'eduScoreFree:queryEduScoreFreeAll'">查询</el-button>
            <el-button @click="rest('search')">重置</el-button>
          </el-form-item>
          <div class="sss">
            <div style="display: inline-block">
              <el-form-item prop="name" label="姓名/学号" style="margin-bottom: 0">
                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
            </div>
            <div style="display:inline-block">
           <el-form-item label="函授站" prop="siteId"  style="margin-bottom:0">
             <el-select
                 clearable
                 v-model="search.siteId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in site"
                   :key="item.id"
                   :label="item.siteName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
           <div style="display:inline-block">
           <el-form-item label="年级" prop="gradeId" style="margin-bottom:0">
             <el-select clearable v-model="search.gradeId" class="el-form-input-width" placeholder="请选择">
               <el-option
                   v-for="item in this.options"
                   :key="item.id"
                   :label="item.gradeName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
           <div style="display:inline-block">
           <el-form-item label="层次" prop="stageId" style="margin-bottom:0">
             <el-select clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
               <el-option
                   v-for="item in eduStage"
                   :key="item.id"
                   :label="item.stageName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
           <div style="display:inline-block">
           <el-form-item label="专业" prop="profId" style="margin-bottom:0">
             <el-select
                 clearable
                 v-model="search.profId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in eduProfession"
                   :key="item.id"
                   :label="item.profName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border
                  size="medium"
                  height="550px"
                  max-height="550px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" >
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" >
          </el-table-column>
          <el-table-column label="层次" prop="stageName" >
          </el-table-column>
          <el-table-column label="专业" prop="profName" >
          </el-table-column>
          <el-table-column label="课程名称" prop="courseName">
          </el-table-column>
          <el-table-column label="免考成绩" prop="freeScore">
          </el-table-column>
           <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
                <div style="color: #46a6ff;cursor: pointer;" v-if="eduScoreFreeedit" @click="editor(scope.row)">录入成绩</div>
              <span v-if="!eduScoreFreeedit">暂无操作</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog title="上传文件" :visible.sync="dialogVisibleImport" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton"
          >下载模板
          </el-button
          >
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>


      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleImports" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbuttons"
          >下载模板
          </el-button
          >
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile1"
            :before-upload="beforeUpload1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <!-- 添加免试学生 -->
      <el-dialog
        title="添加免试学生"
        :visible.sync="dialogVisibleUpload"
        width="60%"
      >
      <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary" @click="dialogVisibleUpload=false">关闭</el-button>
          <el-button type="primary" @click="selection" >选择</el-button>
          <el-button type="primary" @click="eduStudentInfoList">查询</el-button>
        </div>
        <div style="display: flex">
          <div style="padding: 0.4rem; display: inline-block">
            姓名：
            <el-input
                v-model="insertSelectiveSearch.name"
                clearable
                @input="() => (insertSelectiveSearch.name = insertSelectiveSearch.name.trim())"
                placeholder="姓名/学号"
                style="width: 250px"
            ></el-input>
          </div>
          <div style="padding: 0.4rem; display: inline-block">
            函授站
            <el-select
                clearable
                v-model="insertSelectiveSearch.siteId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in site"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div style="padding: 0.4rem; display: inline-block">
            年级
            <el-select clearable v-model="insertSelectiveSearch.gradeId" class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.options"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div style="padding: 0.4rem; display: inline-block">
            层次
            <el-select clearable v-model="insertSelectiveSearch.stageId"  class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in eduStage"
                  :key="item.id"
                  :label="item.stageName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div style="padding: 0.4rem; display: inline-block">
            专业
            <el-select
                clearable
                v-model="insertSelectiveSearch.profId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduProfession"
                  :key="item.id"
                  :label="item.profName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="margin-top:1rem">
          <el-table :data="insertSelectiveSearchTableData" ref="multipleSelection" border style="width: 100%" @selection-change="handleSelectionChange"
                    size="medium"
                    height="420px"
                    max-height="420px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index"  label="序号" width="100"></el-table-column>
            <el-table-column prop="studentNo" label="学号" width="130">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="courseName" label="课程名称" >
            </el-table-column>
            <el-table-column prop="siteName" label="函授站" >
            </el-table-column>
            <el-table-column prop="stageName" label="层次">
            </el-table-column>
            <el-table-column prop="profName" label="专业" >
            </el-table-column>
            <el-table-column prop="gradeName" label="年级">
            </el-table-column>
          </el-table>
           <div style="padding: 1rem">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="insertSelectiveSearch.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="insertSelectiveSearch.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="insertSelectiveTotal"
          >
          </el-pagination>
        </div>
        </div>
      </el-dialog>
      <!--    录入成绩  -->
      <el-dialog
          title="录入成绩"
          :visible.sync="dialogVisibleUpload1"
          width="40%"
          style="text-align: left"
      >
        <el-form
            :model="teacherForm"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >
          <el-col :span="12">
            <el-form-item label="学号：" prop="studentNo">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.studentNo"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.studentNo = teacherForm.studentNo.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.name"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.name = teacherForm.name.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程：" prop="courseName">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.courseName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.courseName = teacherForm.courseName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="站点：" prop="siteName">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.siteName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.siteName = teacherForm.siteName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级：" prop="gradeName">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.gradeName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.gradeName = teacherForm.gradeName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="层次：" prop="stageName">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.stageName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.stageName = teacherForm.stageName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业：" prop="profName">
              <el-input
                  readonly="readonly"
                  v-model="teacherForm.profName"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.profName = teacherForm.profName.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="免试成绩：" prop="freeScore">
              <el-input
                  v-model="teacherForm.freeScore"
                  placeholder="请输入"
                  class="el-form-input-width"
                  @input="() => (teacherForm.freeScore = teacherForm.freeScore.trim())"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUpload1 = false">关 闭</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
        >保存后关闭</el-button
        >
      </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.getPermission('eduScoreFree:edit', () => {
      this.eduScoreFreeedit=true;
    })
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.handleSearch()
    },
  data() {
    return {
      myHeaders:{
        'x-token': localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-token'] : null,
        'x-perm':localStorage.loginStrage ? JSON.parse(localStorage.getItem('loginStrage'))['x-perm'] : null
      },
      multipleSelection: [],
      eduStage: [],
      eduProfession: [],
      course:[],
      site:[],
      eduGraduateWorkBatch:[],
      total: 0,
      pageNum: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisibleUpload1: false,
      checked: false,
      num: 1,
      dialogVisible: false,
      dialogVisibleImport:false,
      dialogVisibleImports:false,
      eduScoreFreeedit:false,
      radio: "1",
      title: "",
      search: {
        id:"",
        name: "",
        batchId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        inputStatus: "",
        page: 1,
        pageSize: 10,
      },
      insertSelectiveSearchTableData: [],
      insertSelectiveTotal: 0,
      insertSelectiveSearch: {
        id:"",
        name: "",
        batchId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        inputStatus: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        courseName:"",
        id:"",
        studentNo:"",
        name:"",
        siteName:"",
        gradeName:"",
        stageName	:"",
        freeScore:"",
        profName:"",
      },
      eduScoreFrees:[
        {
          studentId:"",
          courseId:"",
        }
      ],
      tableChecked: [],
      tableData: [],
      currentPage4: 4,
      currentPage1: 4,
      options: [],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    rest(form){
      this.$refs.search.resetFields()
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView();
      this.$axios_supermall.get("/hnjxjy-core/eduScoreFree/queryEduScoreFreeAll?" +
          "studentNameOrCode="+this.search.name+
          "&gradeId="+this.search.gradeId+
          "&stageId="+this.search.stageId+
          "&profId="+this.search.profId+
          "&siteId="+this.search.siteId+
          "&page="+this.search.page+
          "&pageSize="+this.search.pageSize
        ).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selection() {
      let array = [];
      let arrays = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      for (let j = 0; j < this.multipleSelection.length; j++) {
        arrays.push(this.multipleSelection[j].courseId);
      }
     if(array.length!=0){
       this.eduScoreFrees.studentId = array.toString();
       this.eduScoreFrees.courseId = arrays.toString();
         this.$confirm("你将对该条数据进行操作!", "提示", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning",
         }).then(() => {
           let data = {
                 eduScoreFrees: []
               }
             let sids =  this.eduScoreFrees.studentId.split(',')
             let cids =  this.eduScoreFrees.courseId.split(',')
           for (let i = 0; i < sids.length; i++) {
             data.eduScoreFrees.push({
               "studentId": sids[i], //学生id
               "courseId": cids[i] //课程id
             })
           }
           this.openLoadingView();
           this.$axios({
             url: "hnjxjy-core/eduScoreFree/insertSelective",
             headers: this.loginList,
             method: "POST",
             data: data
           }).then((res) => {
             if (res.data.code == 2000) {
               this.$message({
                 type: "success",
                 message: "操作成功!",
               });
               this.dialogVisibleUpload = false
             }
             this.loadingView.close();
           })
         });

     }else{
       this.$message({
         type: "error",
         message: "请选择一条数据",
       });
     }
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreFree/exportTemplateCourse",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    downloadbuttons(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreFree/exportEduScoreExcel",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreFree/exportEduScoreFreeExcel",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    opendilog(){
      this.dialogVisibleImport = true;
    },
    opendilogs(){
      this.dialogVisibleImports = true;
    },

    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
      this.insertSelectiveSearch.pageSize = val;
      this.eduStudentInfoList();
    },
    handleCurrentChange1(val) {
      this.insertSelectiveSearch.page = val;
      this.eduStudentInfoList();
    },
    edition() {
      this.dialogVisibleUpload = true;
      setTimeout(() => {
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        this.eduStudentInfoList();
        this.loadingView.close();
      }, 1000);
    },
    eduStudentInfoList(){
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduStudentInfo/selectEduStudentInfoCourse",
        method: 'get',
        params: {
          page: this.insertSelectiveSearch.page,
          pageSize: this.insertSelectiveSearch.pageSize,
          info: this.insertSelectiveSearch.name,
          siteId: this.insertSelectiveSearch.siteId,
          stageId: this.insertSelectiveSearch.stageId,
          gradeId: this.insertSelectiveSearch.gradeId,
          profId: this.insertSelectiveSearch.profId,
        }
      })
      .then(res => {
        if (res.data.code === 2000){
          this.insertSelectiveSearchTableData = res.data.data.list;
          this.insertSelectiveTotal = res.data.data.total;
        }
        this.loadingView.close();
      })
      .catch(err => {
        this.loadingView.close();
      })
    },
    editor(row){
       this.openLoadingView()
      this.title = "录入成绩";
      this.$axios_supermall.get("hnjxjy-core/eduScoreFree/queryEduScoreFreeById?"+
          "id="+row.id
      ).then(resp => {
        this.dialogVisibleUpload1 = true;
        this.teacherForm = resp.data.data;

      })
    },
    handleSave(){
      let fromData = new FormData();
      fromData.append("id",this.teacherForm.id);
      fromData.append("freeScore",this.teacherForm.freeScore);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreFree/edit",fromData).then(resp => {
        if (resp.data.code == 2000) {
         this.handleSearch()
          this.dialogVisibleUpload1 = false
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.openLoadingView();
        }else{
          this.$message({
            type: "error",
            message: "操作失败",
          });
        }
      })
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    beforeUpload1(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreFree/exportExcelCourse",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
           if(res.data.code===2000){
              this.$message.success(res.data.message)
             this.loadingView.close()
           }
          })
          .catch((err) => {
          });
    },
    uploadFile1(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreFree/exportTemEduScoreExcel",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if(res.data.code===2000){
              this.$message.success(res.data.message)
              this.loadingView.close()
            }
          })
          .catch((err) => {
          });
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.sss{
  display: flex;
}
</style>
