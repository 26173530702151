<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学籍自查
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="75px" inline>
          <el-form-item>
            <el-button icon="el-icon-upload2" v-permission="'eduStudentInfo:importZiC'" type="primary" @click="Batch()"
              >导入自查学生</el-button
            >
            <el-button icon="el-icon-check" type="primary" @click="editSelf" v-permission="'eduStudentInfo:editSelf'">更改自查状态</el-button>
            <el-button icon="el-icon-bottom" type="primary" @click="download">导出</el-button>
            <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
              >设置学籍异动（层次和专业）</el-button
            > -->
            <!-- <el-button icon="el-icon-check" type="primary">审核</el-button> -->
            <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
            <el-button type="primary"  @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
            <div style="display: inline-block">
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 222px;"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="函授站" prop="siteId" >
              <el-select
                  style="width: 222px;"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="gradeId" >
              <el-select style="width: 222px;" clearable v-model="search.gradeId" @change="handleSearch"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" prop="stageId" >
              <el-select  style="width: 222px;" clearable v-model="search.stageId"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业" prop="profId" >
              <el-select
                  clearable
                  style="width: 222px;"
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否自查" prop="selfExamination" >
              <el-select style="width: 222px;" clearable v-model="search.selfExamination"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="500px"
                  max-height="500px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" show-overflow-tooltip prop="studentNo" width="180">
          </el-table-column>
          <el-table-column label="性别" prop="sex" width="180">
          </el-table-column>
          <el-table-column label="证件号" show-overflow-tooltip prop="certificateNo" width="180">
          </el-table-column>
          <el-table-column label="考生号" show-overflow-tooltip prop="name" width="180">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="年级" show-overflow-tooltip prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" show-overflow-tooltip prop="stageName" width="180">
          </el-table-column>
          <el-table-column label="专业" show-overflow-tooltip prop="profName" width="180">
          </el-table-column>
          <el-table-column label="专业代码" show-overflow-tooltip prop="profCode" width="180">
          </el-table-column>
          <el-table-column label="是否自查" prop="selfExamination" width="180">
          </el-table-column>
          <el-table-column label="联系方式" show-overflow-tooltip prop="contactPhone" width="180">
          </el-table-column>
          <el-table-column label="证件类型" prop="certificateType" width="180">
          </el-table-column>
          <el-table-column label="入学时间" show-overflow-tooltip prop="createTime" width="180">
          </el-table-column>
          <el-table-column label="学籍状态" prop="rollStatus" width="180">
          </el-table-column>
          <el-table-column label="备注" show-overflow-tooltip prop="remark" width="180">
          </el-table-column>
          <!-- <el-table-column label="操作" width="130" fixed="right">
            <template slot-scope="scope">
                <div style="color: #1890ff;cursor: pointer;" @click="reshuffle(scope.row)">
                    查看成绩
                </div>
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton" v-permission="'eduStudentInfo:exportZiCMb'">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduSite();
    this.queryEduProfession();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      option:[],
      eduStage:[],
      eduProfession:[],
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        selfExamination:"",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [

      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },

      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("info",this.search.name);
      fromData.append("selfExamination",this.search.selfExamination);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list?&page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    editSelf(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        this.$axios({
          url: "hnjxjy-core/eduStudentInfo/editSelf",
          headers: this.loginList,
          method: "POST",
          params: {
            ids: arr,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.handleSearch()
          }else{
            this.$message({
              type: "info",
              message: res.data.message,
            });
          }
        }).catch(() => {
              this.$message({
                type: "info",
                message: "操作失败",
              });
            });
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }
    },
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduStudentInfo/importZiC",
        data: forms,
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        if(res.data.type == "application/json"){
          this.$message.success("操作成功")
          this.dialogVisibleUpload = false
          this.handleSearch()
          this.loadingView.close();
        }else{
          this.$message.error("操作失败")
          this.dialogVisibleUpload = false
          this.loadingView.close();
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
        }
      }).catch((err) => {
        this.$message.error("操作失败")
        this.dialogVisibleUpload = false
        this.loadingView.close();
      });
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentInfo/export",
        responseType: "blob",
        headers: objPar,
        params:{}
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentInfo/exportZiCMb",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '导入自查学生模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    Batch() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>
