<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        发布成绩管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" v-permission="'eduScorePublish:list'" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-check" type="primary"
              @click="examine">发布考试成绩</el-button
            >
            <el-button icon="el-icon-close" type="primary" v-permission="'eduScorePublish:isRelease'"  @click="cancelExamine">取消发布考试成绩</el-button>
            <el-button icon="el-icon-check" v-permission="'eduScorePublish:isRelease'" type="primary"
                       @click="examines">发布总评成绩</el-button
            >
            <el-button icon="el-icon-close" type="primary"
                       @click="cancelExamines">取消发布总评成绩</el-button
            >
            <el-button icon="el-icon-bottom" v-permission="'eduScorePublish:export'" type="primary" @click="download">导出</el-button>

          </el-form-item>
          <div>
            <div style=" display: inline-block">
              <el-form-item  label="名称/编号">

                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="名称/编号"
                    style="width: 250px"
                ></el-input>
              </el-form-item>
            </div>
            <div style="display:inline-block">
           <el-form-item label="考试批次" style="margin-bottom:0">

             <el-select
                 clearable
                 v-model="search.batchId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in eduGraduateWorkBatch"
                   :key="item.id"
                   :label="item.batchName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
           <div style="display:inline-block">
           <el-form-item label="年级"  style="margin-bottom:0">

             <el-select clearable v-model="search.gradeId"  @change="QuerySemester" class="el-form-input-width" placeholder="请选择">
               <el-option
                   v-for="item in this.options"
                   :key="item.id"
                   :label="item.gradeName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
          </div>
            <div style="display:inline-block">
              <el-form-item label="学期" style="margin-bottom:0">

                <el-select
                    clearable
                    v-model="search.semester"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in semesters"
                      :key="item.id"
                      :label="item.maxTerm"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="教学计划" prop="planName"> </el-table-column>
          <el-table-column label="课程编号" prop="courseCode"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName"> </el-table-column>
          <el-table-column label="学期" prop="semester"> </el-table-column>
          <el-table-column label="是否已发布考试成绩" prop="publishScore"> </el-table-column>
          <el-table-column label="是否已发布总评成绩" prop="publishOverrall">
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="设置总评成绩生成规则"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div style="margin-bottom: 20px; text-align: left">
          <el-button @click="dialogVisibleUpload = false"
            >关闭</el-button
          >
           <el-button type="primary"
            >确定</el-button
          >
        </div>
        <div >
        <div style="margin-bottom: 20px; text-align: left">
          离线成绩：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          在线交互：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          考试成绩：<el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          通过成绩 ≥：<el-input v-model="input" placeholder="请输入内容" style="width:40%"></el-input>
        </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
      this.handleSearch()
      this.QueryEduGraduateWorkBatch()
    this.gradList();

    },
  data() {
    return {
      semesters:[],
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      radio: "1",
      title: "",
      eduGraduateWorkBatch:[],
      search: {
        name: "",
        gradeId:"",
        semester:"",
        batchId:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [

      ],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("batchId",this.search.batchId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("semester",this.search.semester);
      fromData.append("info",this.search.name);
      this.$axios_supermall.post("/hnjxjy-core/eduScorePublish/list?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;


      })
      })
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    //查询年级
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    //查询学期
    QuerySemester() {
      this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
        if (resp.data.code == 2000) {
          this.semesters = []
          for (let i = resp.data.data.maxTerm; i >0; i--) {
            this.semesters.push({id:i,maxTerm:i});
          }
        }
      })
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScorePublish/export",
        responseType: "blob",
        headers: objPar,
        params:{
          "info":this.search.name,
          "gradeId":this.search.gradeId,
          "semester":this.search.semester,
          "batchId":this.search.batchId,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    examine(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        if (array.length <= 0) {
          this.$message({
            type: "error",
            message: "请至少选中一条数据!",
          });
        } else {
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScorePublish/isRelease",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status: 1
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
            })

                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });
        }
      }else{
        this.$message.error("请选择一条数据")
      }
    },
    examines(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScorePublish/isRelease",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status: 3
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
            })

                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });

      }else{
        this.$message.error("请选择一条数据")
      }
    },
    cancelExamine(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        if (array.length <= 0) {
          this.$message({
            type: "error",
            message: "请至少选中一条数据!",
          });
        } else {
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScorePublish/isRelease",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status: 2
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
            })

                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });
        }
      }else {
        this.$message.error("请选择一条数据")
      }
    },
    cancelExamines(){
      if (this.multipleSelection != null && this.multipleSelection != "") {
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();
        if (array.length <= 0) {
          this.$message({
            type: "error",
            message: "请至少选中一条数据!",
          });
        } else {
          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScorePublish/isRelease",
              headers: this.loginList,
              method: "post",
              params: {
                ids: arr,
                status: 4
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
            })

                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });
        }
      }else{
        this.$message.error("请选择一条数据")
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
