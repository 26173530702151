<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        材料申请
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border
                  size="medium"
                  height="580px"
                  max-height="580px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="学生" prop="name" width="180">
          </el-table-column>
          <el-table-column label="审核状态" prop="checkStatus" width="180">
          </el-table-column>
          <el-table-column label="申请类型" prop="applyType" width="180">
          </el-table-column>
          <el-table-column label="申请说明" show-overflow-tooltip prop="applyDesc" width="180">
          </el-table-column>
          <el-table-column label="查看申请附件" prop="attachmentUrl" width="180">
            <template slot-scope="scope">
              <div style="color: #1890ff;cursor: pointer;" @click="selectFJ(scope.row)">
                查看附件
              </div>
            </template>
          </el-table-column>
          <el-table-column label="申请时间" show-overflow-tooltip prop="applyTime" width="180">
          </el-table-column>
          <el-table-column label="审核人姓名" prop="loginAccount" width="180">
          </el-table-column>
          <el-table-column label="审核时间" show-overflow-tooltip prop="checkTime" width="180">
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
                <el-button type="primary" v-permission="'eduStudentMaterial:editStatus'" @click="reshuffle(scope.row)">通过</el-button>
                <el-button type="primary" v-permission="'eduStudentMaterial:editStatus'" @click="reshuffles(scope.row)">驳回</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
          title="审核意见"
          :visible.sync="dialogreasons"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">
            原因：
            <el-input v-model="teacherForm.remark" type="textarea" placeholder="请输入内容" style="width:40%"></el-input>
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasons = false"
          >关闭</el-button
          >
          <el-button type="primary"
                     @click="submit">确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
          title="审核意见"
          :visible.sync="dialogreasonss"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">
            原因：
            <el-input v-model="teacherForm.remark" type="textarea" placeholder="请输入内容" style="width:40%"></el-input>
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasonss = false"
          >关闭</el-button
          >
          <el-button type="primary"
                     @click="submits">确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch()
  },
  data() {
    return {
      dialogreasons:false,
      dialogreasonss:false,
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      search: {
        name: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        id:"",
        remark:""
      },
      tableChecked: [],
      tableData: [

      ],
      currentPage4: 4,
      currentPage1: 4,
      value: "",
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
       fromData.append("schoolId",localStorage.getItem("siteId"));
      this.$axios_supermall.post("/hnjxjy-core/eduStudentMaterial/list?page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
            this.tableData = resp.data.data.list; //表格列表
            this.total = resp.data.data.total;
          })
          });
    },
    reshuffle(row){
      this.dialogreasons = true
      this.teacherForm=[]
      this.teacherForm.id = row.id
    },
    submit(){
      let fromData = new FormData();
      fromData.append("status","1");
      fromData.append("remark",this.teacherForm.remark);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentMaterial/editStatus?id="+this.teacherForm.id,fromData).then(resp=>{
        if(resp.data.code==2000){
          this.dialogreasons = false;
          this.handleSearch();
          this.$message({
            type:"success",
            message:"操作成功"
          })
        }else{
          this.$message({
            type:"error",
            message:resp.data.message
          })
        }
      })
    },
    reshuffles(row){
      this.dialogreasonss = true
      this.teacherForm=[]
      this.teacherForm.id = row.id
    },
    submits(){
      let fromData = new FormData();
      fromData.append("status","2");
      fromData.append("remark",this.teacherForm.remark);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentMaterial/editStatus?id="+this.teacherForm.id,fromData).then(resp=>{
        if(resp.data.code==2000){
          this.dialogreasons = false;
          this.handleSearch();
          this.$message({
            type:"success",
            message:"操作成功"
          })
        }else{
          // this.$message({
          //   type:"error",
          //   message:resp.data.message
          // })
        }
      })
    },
    selectFJ(row){
      if(row.attachmentUrl!=null&&row.attachmentUrl!=""){
         window.open(row.attachmentUrl)
      }else {
        this.$message({
          type:"error",
          message:"附件为空"
        })
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>
