lai<template>
  <div>
    <div class="header">
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        课程选课数据
      </div>
      <div>
        <div class="name_num">
          <el-form inline label-width="120px" ref="search" :model="search">
        <div class="export">
          <div class="export_left">
            <el-button icon="el-icon-bottom" type="primary" @click="download">导出</el-button>
          </div>
          <div class="export_right">
            <el-button type="primary" @click="handleSearch" v-permission="'eduStudentCourse:list'">查询</el-button>
            <el-button type="primary" @click="rest(search)">重置</el-button>
          </div>
        </div>

            <el-form-item label="学号/姓名" prop="name">
              <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="名称/编号"
                  style="width: 280px"
              ></el-input>
            </el-form-item>
            <el-form-item label="年级" prop="gradeId">
              <el-select clearable v-model="search.gradeId"  placeholder="请选择">
                <el-option
                    v-for="(item,index) in this.options"
                    :key="index"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" prop="stageId">
              <el-select clearable v-model="search.stageId" placeholder="请选择">
                <el-option
                    v-for="(item,index) in eduStage"
                    :key="index"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="是否通过" prop="passFlag">
              <el-select clearable v-model="search.passFlag" placeholder="请选择">
                <el-option
                    v-for="item in option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业" prop="profId">
              <el-select
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="(item,index) in eduProfession"
                    :key="index"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程" prop="courseId">
              <el-select
                  clearable
                  v-model="search.courseId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="(item,index) in course"
                    :key="index"
                    :label="item.courseName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="学期" prop="semester">-->
<!--              <el-select-->
<!--                  clearable-->
<!--                  v-model="search.semester"-->
<!--                  placeholder="请选择"-->
<!--                  class="el-form-input-width"-->
<!--              >-->
<!--                <el-option-->
<!--                    v-for="item in semesters"-->
<!--                    :key="item.id"-->
<!--                    :label="item.maxTerm"-->
<!--                    :value="item.id"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item label="函授站" prop="siteId">
              <el-select
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="(item,index) in site"
                    :key="index"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                  v-model="search.startTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                  v-model="search.endTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="excle">
          <el-table
              size="medium"
              height="520px"
              max-height="520px"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
            :data="tableData"
            border
            style="width: 100%"
            slot="empty"
          >
           <el-table-column type="index" label="序号"  width="80"></el-table-column>
            <el-table-column prop="name" label="姓名" sortable width="120"></el-table-column>
            <el-table-column prop="studentNo" show-overflow-tooltip label="学号" sortable width="120"></el-table-column>
            <el-table-column prop="siteName" show-overflow-tooltip label="函授站" sortable width="180"></el-table-column>
            <el-table-column prop="gradeName" show-overflow-tooltip label="年级" sortable width="150"></el-table-column>
            <el-table-column prop="stageName" show-overflow-tooltip label="层次" sortable width="160"></el-table-column>
            <el-table-column prop="profName" show-overflow-tooltip label="专业" sortable width="160"></el-table-column>
            <el-table-column prop="chooseTime" show-overflow-tooltip label="选课时间"  width="180"></el-table-column>
            <el-table-column prop="contactPhone" show-overflow-tooltip label="联系电话"  width="150"></el-table-column>
            <el-table-column prop="courseCode" show-overflow-tooltip label="课程编号"  width="150"></el-table-column>
            <el-table-column prop="courseName" show-overflow-tooltip label="课程名称"  width="150"></el-table-column>
            <el-table-column prop="semester" show-overflow-tooltip label="课程学期"  width="150"></el-table-column>
            <el-table-column prop="passFlag" label="是否通过"  width="150">
            </el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.handleSearch()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.gradList();
    this.queryCourse();
  },
  data() {
    return {
      search: {
        name: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        passFlag: "",
        startTime: "",
        endTime: "",
        courseId: "",
        page: 1,
        pageSize: 10,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      value3: "",
      options: [],
      option: [
        {
          label: "否",
          value: 0,
        },
        {
          label: "是",
          value: 1,
        },
      ],
      total:0,
      semesters:[],
      eduStage: [],
      eduProfession: [],
      course:[],
      site:[],
      tableData: []
    };
  },
   methods: {
     rest(form){
       this.$refs.search.resetFields()
     },
     handleSearch() {
       this.getPermission('eduStudentCourse:list', () => {
         this.openLoadingView()
         let fromData = new FormData();
         fromData.append("info",this.search.name);
         fromData.append("gradeId",this.search.gradeId);
         fromData.append("stageId",this.search.stageId);
         fromData.append("profId",this.search.profId);
         fromData.append("semester",this.search.semester);
         fromData.append("siteId",this.search.siteId);
         fromData.append("passFlag",this.search.passFlag);
         if(this.search.startTime!=null&&this.search.startTime!=""){
           let startTime = new Date(this.search.startTime).formatToLocalDateTime();
           fromData.append("startTime",startTime);
         }
         if (this.search.endTime!=null&&this.search.endTime!=""){
           let endTime = new Date(this.search.endTime).formatToLocalDateTime();
           fromData.append("endTime",endTime);
         }
         fromData.append("courseId",this.search.courseId);
         fromData.append("page",this.search.page);
         fromData.append("pageSize",this.search.pageSize);
         this.$axios_supermall.post("/hnjxjy-core/eduStudentCourse/list",fromData).then(resp => {
           if (resp.data.code == 2000) {
             this.tableData = resp.data.data.list;
             this.total = resp.data.data.total;
           }
         })
       });
     },
     //查询课程
     queryCourse() {
       this.$axios_supermall.post("/hnjxjy-core/eduCourse/courseList").then(resp => {
         if (resp.data.code == 2000) {
           this.course = resp.data.data;
         }
       })
     },
     //查询年级
     gradList() {
       this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
       // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
       this.$axios({
         url: "hnjxjy-core/eduGrade/listGL",
         headers: this.loginList,
         method: "POST",
       }).then((res) => {
         if (res.data.code !== 2000) {
           return;
         }
         this.options = res.data.data;
       });
     },
     // //查询学期
     // QuerySemester() {
     //   this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
     //     if (resp.data.code == 2000) {
     //       this.semesters = []
     //       for (let i = resp.data.data.maxTerm; i >0; i--) {
     //         this.semesters.push({id:i,maxTerm:i});
     //       }
     //     }
     //   })
     // },
     //查询层次
     queryEduStage() {
       this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
         if (resp.data.code == 2000) {
           this.eduStage = resp.data.data;
         }
       })
     },
     //查询专业
     queryEduProfession() {
       this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
         if (resp.data.code == 2000) {
           this.eduProfession = resp.data.data;
         }
       })
     },
     //查询函授站下拉
     queryEduSite() {
       let formData = new FormData();
       formData.append("isAll","1");
       this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
         if (resp.data.code == 2000) {
           this.site = resp.data.data;
         }
       })
     },
     download(){
       let objPar = JSON.parse(localStorage.getItem("loginStrage"));
       this.openLoadingView();
       this.$axios({
         method: "get",
         url: "hnjxjy-core/eduStudentCourse/exportCourseSelection",
         responseType: "blob",
         headers: objPar,
         params:{
           gradeId:this.search.gradeId,
         }
       }).then((res) => {
         let blob = new Blob([res.data], {
           type: "application/vnd.ms-excel",
         });
         let url = window.URL.createObjectURL(blob);
         window.location.href = url;
         this.$message.success("操作成功");
         this.loadingView.close();
       }).catch((err) => {
         this.$message.error("操作失败");
         this.loadingView.close();
       });
     },
     handleSizeChange(val) {
       this.search.pageSize = val;
       this.handleSearch();
     },
     handleCurrentChange(val) {
       this.search.page = val;
       this.handleSearch();
     },
      formatter(row, column) {
        return row.address;
      },

    }
};
</script>
<style scoped>
.header_top{
  width: 7%;
}
.header {
  /*display: flex;*/
  justify-content: flex-start;
  margin-top: 5px;
}
.content {
  text-align: left;
  width: 93%;
}
.course {
  background-color: #f1f1f1;
  line-height: 54px;
  padding-left: 10px;
}
.export {
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.export_left {
  padding-left: 15px;
}
.export_right {
  padding-right: 50px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.name_num {
  background-color: rgba(164, 174, 224, 0.11);
  padding-left: 50px;
  text-align: left;
}
.name_num>>>.el-input--suffix{
    width: 280px;
}
.choose {
  margin-bottom: 15px;
}
.demonstration {
  display: inline-block;
  padding-right: 10px;
  font-size: 16px;
  color: #000;
}
.el-input__suffix {
  right: 5px;
}
.el-input__inner {
  width: 100%;
}
.span {
  display: inline-block;
  width: 36px;
}
.excle{
  padding: 0 20px 20px 20px;
  background-color: rgba(164, 174, 224, 0.11);
}
.excle>>>.el-table{
}
/* .el-table__body-wrapper{
  height: 550px;
} */
</style>
