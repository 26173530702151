<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
          离线成绩管理
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="140px" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduScoreOffline:export'">导出</el-button>
            <el-button icon="el-icon-upload2"  type="primary" v-permission="'eduScoreOffline:importData'" @click="edition()">导入</el-button>
            <el-button type="primary" @click="handleSearch" v-permission="'eduScoreOffline:list'">查询</el-button>
            <el-button @click="rest('search')">重置</el-button>
          </el-form-item>
          <div class="sss">
            <el-form-item label="学号/姓名/课程名称" prop="name">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              placeholder="学号/姓名/课程名称"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select style="width: 200px" v-model="search.gradeId" placeholder="请选择">
              <el-option
                  v-for="item in this.options"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="层次" prop="stageId" >
             <el-select style="width: 200px" v-model="search.stageId" placeholder="请选择">
               <el-option
                   v-for="item in eduStage"
                   :key="item.id"
                   :label="item.stageName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
           <el-form-item label="函授站" prop="siteId">
             <el-select
                 style="width: 200px"
                 clearable
                 v-model="search.siteId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in site"
                   :key="item.id"
                   :label="item.siteName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
           <el-form-item label="专业"  prop="profId">
             <el-select
                 style="width: 200px"
                 clearable
                 v-model="search.profId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in eduProfession"
                   :key="item.id"
                   :label="item.profName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
            <el-form-item label="考试批次" prop="batchId" >
              <el-select
                  style="width: 200px"
                  clearable
                  v-model="search.batchId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduGraduateWorkBatch"
                    :key="item.id"
                    :label="item.batchName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData"  border
                  size="medium"
                  height="500px"
                  max-height="500px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
              <el-table-column type="index" width="100"></el-table-column>
          <el-table-column label="姓名" prop="name" width="160">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="200"> </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180"> </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="180">
          </el-table-column>

          <!-- <el-table-column label="专业" prop="sex" width="230"> </el-table-column> -->
          <el-table-column label="层次" prop="stageName"  width="180"> </el-table-column>
          <el-table-column label="专业" prop="profName"  width="180">
          </el-table-column>
          <el-table-column label="学期" prop="maxTerm"  width="180"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName"  width="180"> </el-table-column>
          <el-table-column label="离线成绩" prop="score" > </el-table-column>
          <!-- <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="primary" size="small"
                >绑定角色</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
       <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" @click="downloadbutton"
          >下载模板
          </el-button
          >
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch()
    this.queryEduStage();
    this.queryEduProfession();
    this.queryEduSite();
    this.QueryEduGraduateWorkBatch();
    this.gradList();
    this.queryCourse();
    },
  data() {
    return {
      total: 0,
      pageNum: 1,
      num:1,
      input:"",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload:false,
      dialogVisible:false,
      radio:'1',
      title: "",
      correspondType: [
        {
          value: 0,
          label: "未录入",
        },
        {
          value: 1,
          label: "已录入",
        },
      ],
      search: {
        id:"",
        name: "",
        batchId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        semester: "",
        siteId: "",
        inputStatus: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableChecked: [],
      tableData: [],
      versionList: [
        {
          value: 0,
          label: "旧版",
        },
        {
          value: 1,
          label: "新版",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [],
      semesters:[

      ],
      eduStage: [],
      eduProfession: [],
      course:[],
      site:[],
      eduGraduateWorkBatch:[],
    };
  },
  methods: {
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    gradList() {
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      // this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        url: "hnjxjy-core/eduGrade/listGL",
        headers: this.loginList,
        method: "POST",
      }).then((res) => {
        if (res.data.code !== 2000) {
          return;
        }
        this.options = res.data.data;
      });
    },
    // //查询学期
    // QuerySemester() {
    //   this.$axios_supermall.get("/hnjxjy-core/eduTerm/queryTermByGradeId?gradeId="+this.search.gradeId).then(resp => {
    //     if (resp.data.code == 2000) {
    //       this.semesters = []
    //       for (let i = resp.data.data.maxTerm; i >0; i--) {
    //         this.semesters.push({id:i,maxTerm:i});
    //       }
    //     }
    //   })
    // },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },

    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },
    //查询课程
    queryCourse() {
      this.$axios_supermall.post("/hnjxjy-core/eduCourse/courseList").then(resp => {
        if (resp.data.code == 2000) {
          this.course = resp.data.data;
        }
      })
    },
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("semester",this.search.semester);
      fromData.append("siteId",this.search.siteId);
      fromData.append("inputStatus",this.search.inputStatus);
      fromData.append("batchId",this.search.batchId);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduScoreOffline/list",fromData).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
      })
      });
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreOffline/export",
        responseType: "blob",
        headers: objPar,
        params:{
          siteId:this.search.siteId,
          batchId:this.search.batchId,
          info:this.search.name,
          gradeId:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          inputStatus:this.search.inputStatus,
          semester:this.search.semester,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    edition(){
       this.dialogVisibleUpload=true
    },
     beforeUpload(file) {
       const isXls = file.type === "application/vnd.ms-excel";
       const isXlsx =
           file.type ===
           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
       if (!isXls && !isXlsx) {
         this.$message.error("上传文件只能是XLS/XLSX格式！");
         return false;
       }
       return isXls || isXlsx;
    },
    uploadFile(file) {
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduScoreOffline/importData",
        data: forms,
        headers: objPar,
      })
          .then((res) => {
            if(res.data.code==2000){
              this.$message.success(res.data.message)
              this.dialogVisibleUpload = false
              this.handleSearch()
            }else{
              this.$message.error(res.data.message)
              this.dialogVisibleUpload = false
              this.handleSearch()
            }
          })
          .catch((err) => {
          });
    },
    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduScoreOffline/exportMB",
        responseType: "blob",
        headers: objPar,
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
  },

};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content{
  text-align: left;
}

</style>
