<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                指导教师统计
            </div>
            <div class="search">
                <el-form inline>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduTeacherStatistics:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/工号">
                        <el-input v-model="queryBO.nameOrCode" placeholder="教师姓名/教师工号" style="width: 500px">
                            <el-button type="primary" slot="append" icon="el-icon-search"
                                       @click="queryBasic"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="毕业设计批次">
                        <el-select v-model="queryBO.batchId" style="width: 500px" @change="queryBasic">
                            <el-option
                                    v-for="item in batchList"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        size="medium"
                        height="600px"
                        max-height="600px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                        type="index"
                            fixed
                        width="80"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            fixed
                            prop="teacherName"
                            label="教师姓名">
                    </el-table-column>
                    <el-table-column
                            prop="jobNo"
                            show-overflow-tooltip
                            label="教师工号">
                    </el-table-column>
                    <el-table-column
                            prop="batchName"
                            show-overflow-tooltip
                            label="毕业批次">
                    </el-table-column>
                    <el-table-column
                            prop="guideNum"
                            label="指导人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyNum"
                            label="答辩人数">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                            prop="taskBookNum"-->
<!--                            label="任务书下发数">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column-->
<!--                            prop="commentNum"-->
<!--                            label="评语下发数">-->
<!--                    </el-table-column>-->
                    <el-table-column
                            prop="passQuestionNum"
                            label="开题通过数">
                    </el-table-column>
                    <el-table-column
                            prop="paperNum"
                            label="论文定稿数">
                    </el-table-column>
                    <el-table-column
                            prop="checkNum"
                            label="评审通过人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyExcellentNum"
                            label="答辩优人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyGoodNum"
                            label="答辩良人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyMiddleNum"
                            label="答辩中人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyPassNum"
                            label="答辩及格人数">
                    </el-table-column>
                    <el-table-column
                            prop="replyUnpassNum"
                            label="答辩不及格人数">
                    </el-table-column>
                    <el-table-column
                            prop="undoneNum"
                            label="未完成人数">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>



    export default {
        name: "teacherStatistics",

        data() {
            return {
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    nameOrCode: '',
                    batchId: null
                },
                batchList: [],//批次列表
                tableData: [],
                total: 0
            }
        },
        methods: {
            //region 导出
            download() {
              this.openLoadingView()
                this.$axios_supermallData({
                  method:"get",
                   url: "hnjxjy-core/eduGraduateDesignTeaStat/exportEduGraduateDesignTeacherExcel1",
                  responseType: "blob"
                }).then((res) => {
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel",
                  });
                  // let url = window.URL.createObjectURL(blob);
                  // window.location.href = url;
                  let downloadElement = document.createElement('a');
                  let href = window.URL.createObjectURL(blob);
                  downloadElement.href = href;
                  downloadElement.download = '指导教师统计.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                  document.body.appendChild(downloadElement);
                  downloadElement.click();
                  document.body.removeChild(downloadElement);
                  window.URL.revokeObjectURL(href);
                  this.loadingView.close()
                  this.$message.success("操作成功")
                }).catch((err) => {
                });
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduTeacherStatistics:list', () => {

                    let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.queryBO.nameOrCode != null && this.queryBO.nameOrCode != '') {
                    bo += '&teacherNameOrNum=' + this.queryBO.nameOrCode;
                }
                if (this.queryBO.batchId != null && this.queryBO.batchId != '') {
                    bo += '&batchId=' + this.queryBO.batchId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDesignTeaStat/queryTeaByPage?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.total = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            //region 查询批次下拉框
            queryBatchList() {
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDesignBatch/queryAllName').then(response => {
                    if (response.data.code === 2000) {
                        this.batchList = response.data.data;
                        this.batchList.unshift({id: null, batchName: "全部"})
                    }
                })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.queryBatchList();
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .table_header .el-checkbox {
        display: none !important
    }
</style>
