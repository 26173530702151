<template>
  <div>
    <div >
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        在线考试管理
      </div>
      <div class="search">
        <el-button @click="changeTime()" type="primary">更改考试时间</el-button>
        <el-button icon="el-icon-remove-outline" type="primary" style="margin-left:15px;"
                   @click="batchDelete">更改所有考试时间</el-button>
        <el-form :model="search" class="demo-ruleForm" style="margin-top: 20px" inline>
          <el-form-item label="" style="margin-bottom: 0">
            <el-input v-model="search.name" clearable @input="() => (search.name = search.name.trim())"
                      placeholder="课程名称/考试名称" style="width: 300px">
              <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="考试批次" style="margin-bottom: 0">
            <el-select
                @change="handleSearch"
                clearable
                v-model="search.batchId"
                placeholder="请选择"
                class="el-form-input-width"
            >
              <el-option
                  v-for="item in eduGraduateWorkBatch"
                  :key="item.id"
                  :label="item.batchName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap" style="padding-top: 0">
        <el-table border ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange"
                  size="medium"
                  height="600px"
                  max-height="600px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="80" type="index">
          </el-table-column>
          <el-table-column label="考试名称" show-overflow-tooltip  prop="examName" sortable>
          </el-table-column>
          <el-table-column label="课程编号" show-overflow-tooltip  prop="courseCode" sortable>
          </el-table-column>
          <el-table-column label="课程名称" show-overflow-tooltip  prop="courseName" sortable>
          </el-table-column>
          <el-table-column label="制作教师" prop="teacherName">
          </el-table-column>
          <el-table-column label="作答开始时间" show-overflow-tooltip  prop="answerStartTime">
          </el-table-column>
          <el-table-column label="作答结束时间" show-overflow-tooltip  prop="answerEndTime">
          </el-table-column>
          <el-table-column label="选课学生数量"  prop="enrolCount">
          </el-table-column>
          <el-table-column label="是否启动批阅"  prop="reviewFlag">
            <template slot-scope="scope">
              <el-button type="text" @click="onAttachment(scope.row)">
                {{ scope.row.reviewFlag }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="py" style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total">
          </el-pagination>
        </div>
      </div>
    </div>



    <el-dialog title="提示" style="width: 70%;margin-left: 15vw;" :visible.sync="cchangedialogVisible" >
      <div style="margin-left: 120px;padding: 10px">
        <div style="text-align: left;margin-bottom: 30px" >
          答题开始时间：<el-date-picker
            v-model="teacherForm.answerStartTime"
            type="datetime"
            placeholder="选择日期时间（必填项）"
        >
        </el-date-picker>
        </div>
        <div style="text-align: left; margin-top: 10px">
          答题结束时间：
          <el-date-picker
              v-model="teacherForm.answerEndTime"
              type="datetime"
              placeholder="选择日期时间（必填项）"
          >
          </el-date-picker>
        </div>
        <div style="text-align: left; margin-left: 25px; margin-top: 30px">
          考试要求
          ： <el-input v-model="teacherForm.answerDesc" clearable
                      placeholder="请输入（必填项）" style="width: 220px">
        </el-input>
        </div>
      </div>
      <div style="margin-bottom: 20px; text-align: left">
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="cchangedialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sumbit">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="提示" style="width: 70%;margin-left: 15vw" :visible.sync="cchangedialogVisibles" >
      <div style="margin-left: 120px;padding: 10px">
        <div style="text-align: left;margin-bottom: 30px" >
          答题开始时间：<el-date-picker
            v-model="teacherForm.answerStartTime"
            type="datetime"
            placeholder="选择日期时间（必填项）"
        >
        </el-date-picker>
        </div>
        <div style="text-align: left; margin-top: 10px">
          答题结束时间：
          <el-date-picker
              v-model="teacherForm.answerEndTime"
              type="datetime"
              placeholder="选择日期时间（必填项）"
          >
          </el-date-picker>
        </div>
        <div style="text-align: left; margin-left: 25px; margin-top: 30px">
          考试要求
          ： <el-input v-model="teacherForm.answerDesc" clearable
                      placeholder="请输入（必填项）" style="width: 220px">
        </el-input>
        </div>
      </div>
      <div style="margin-bottom: 20px; text-align: left">
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="cchangedialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sumbits">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="提示" style="width: 90%;padding: 100px;" :visible.sync="cchangedialogVisibled" >
      <div style="margin-bottom: 10px"> "分配考试数" 不能小于 "已批阅" 数，"已批阅"为已经分配考试中已批阅的
<!--        <el-button style="margin-left: 20px"  type="primary">添加批阅教师</el-button>-->
<!--        <el-button  type="primary">移除批阅教师</el-button>-->
      </div>
      启用分配批阅<el-switch
        @click="isReview"
          v-model="isReviews.statues"
          active-color="#13ce66"
          inactive-color="#ff4949"></el-switch>
        <div style="margin-top:1rem">
          <el-table :data="versionList" :disabled="true"  element-loading-spinner="sss"
                    element-loading-background="rgba(59, 59, 59, 0.475)"  v-loading="loading" border style="width: 100%"
                    @selection-change="handleSelectionChange"
                    size="medium"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="80" label="序号" >
            </el-table-column>
            <el-table-column prop="teachName" label="教师名称" >
            </el-table-column>
            <el-table-column prop="jobNo" label="工号" >
            </el-table-column>
            <el-table-column prop="examNum" label="分配考试数" >
            </el-table-column>
            <el-table-column prop="singleNum" label="单次获取数">
            </el-table-column>
            <el-table-column prop="pressName" label="操作" >
              <template slot-scope="scope">
                <el-button type="text" v-permission="'eduReview:remove'">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      <div style="margin-left: 550px;margin-top: 50px">
      <el-button type="primary" @click="off">取消</el-button>
      <el-button type="primary" @click="sumbites" v-permission="'eduCourseExamSet:isReview'">保存</el-button>
      </div>
    </el-dialog>


  </div>
</template>
<script>
export default {
  created() {
    this.handleSearch()
    this.QueryEduGraduateWorkBatch();
  },
  data() {
    return {
      total: 0,
      totales: 0,
      pageNum: 1,
      pageSize: 10,
      currentPage4: 4,
      currentPage1: 4,
      currentPage2: 4,
      textarea: "",
      reviewFlag:"",
      loading:false,
      search: {
        name: "",

        batchId: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        answerStartTime: "",
        answerEndTime: "",
        answerDesc: "",
        id: "",
      },
      isReviews:{
        statues:false,
        status:false,
        id:""
      },
      outerVisible: false,
      innerVisible: false,
      tableChecked: [],
      options: [],
      eduGraduateWorkBatch: [],
      eduStage: [],
      eduProfession: [],
      versionList: [],
      tableData: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisibleUpload: false,
      teacherRules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        fullName: [{
          required: true,
          message: "请输入真实姓名",
          trigger: "blur"
        }, ],
        sex: [{
          required: true,
          message: "请选择性别",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "请输入手机号码",
          trigger: "blur"
        },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确"
          },
        ],
        email: [{
          required: false,
          message: "请输入邮箱",
          trigger: "blur"
        },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [{
          required: false,
          message: "请输入身份证号码",
          trigger: "blur"
        },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
      value: "",
      cchangedialogVisible: false,
      cchangedialogVisibles: false,
      cchangedialogVisibled: false,
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduCourseExamSet:list', () => {
        this.openLoadingView()
        let fromData = new FormData();
        fromData.append("info",this.search.name);
        fromData.append("batchId",this.search.batchId);
        fromData.append("page",this.search.page);
        fromData.append("pageSize",this.search.pageSize);
        this.$axios_supermall.post("/hnjxjy-core/eduCourseExamSet/list",fromData).then(resp => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
            this.reviewFlag=this.tableData.reviewFlag
          }
        })
      });
    },
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },
    // 批量删除
    batchDelete() {

        this.cchangedialogVisibles = true
        this.teacherForm={}
    },
    changeTime() {
      if(this.tableChecked!=null&&this.tableChecked!=""){

          this.cchangedialogVisible = true
          this.teacherForm={}
      }else{
        this.$message({
          type: "error",
          message: "请选择一条数据",
        });
      }

    },
    sumbit(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.tableChecked.length; i++) {
        array.push(this.tableChecked[i].id);
      }
      if(this.teacherForm.answerStartTime!=null&&this.teacherForm.answerEndTime!=null&&this.teacherForm.answerDesc!=null&&this.teacherForm.answerDesc!=""){
        let arr = array.toString();
        let reg=new RegExp('-','g')//g代表全部
        //let newMsg=JSON.stringify(msg).replace(reg,'&&&');
        this.$axios({
          url: "hnjxjy-core/eduCourseExamSet/updateEduCourseExamSet",
          headers: this.loginList,
          method: "post",
          data: {
            ids: arr,
            answerStartTime: this.teacherForm.answerStartTime.formatToLocalDateTime().toString().replace(reg,'/'),
            answerEndTime:  this.teacherForm.answerEndTime.formatToLocalDateTime().toString().replace(reg,'/'),
            answerDesc: this.teacherForm.answerDesc,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.cchangedialogVisible = false
            this.handleSearch()
          }
        });
      }else{
        this.$message({
          type: "error",
          message: "必填项不能为空",
        });
      }
    },
    sumbits(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.tableChecked.length; i++) {
        array.push(this.tableChecked[i].id);
      }
      if(this.teacherForm.answerStartTime!=null&&this.teacherForm.answerEndTime!=null&&this.teacherForm.answerDesc!=null&&this.teacherForm.answerDesc!=""){
        let arr = array.toString();
        let reg=new RegExp('-','g')//g代表全部
        //let newMsg=JSON.stringify(msg).replace(reg,'&&&');
        this.$axios({
          url: "hnjxjy-core/eduCourseExamSet/updateEduCourseExamSet",
          headers: this.loginList,
          method: "post",
          data: {
            answerStartTime: this.teacherForm.answerStartTime.formatToLocalDateTime().toString().replace(reg,'/'),
            answerEndTime:  this.teacherForm.answerEndTime.formatToLocalDateTime().toString().replace(reg,'/'),
            answerDesc: this.teacherForm.answerDesc,
          },
        }).then((res) => {
          if (res.data.code == 2000) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.cchangedialogVisibles = false
            this.handleSearch()
          }
        }).catch(() => {
          this.$message({
            type: "info",
            message: "操作失败",
          });
        });
      }else{
        this.$message({
          type: "error",
          message: "必填项不能为空",
        });
      }
    },
    off(){
      this.cchangedialogVisibled=false
    },
    onAttachment(row){

      this.getPermission('eduReview:list', () => {
        this.cchangedialogVisibled = true
        this.versionList=[]
        let fromData = new FormData();
        //fromData.append("")
        this.$axios_supermall.post("/hnjxjy-core/eduReview/list?id="+row.id).then(resp => {
          if (resp.data.code == 2000) {
            this.versionList=resp.data.data.list;
            if(row.reviewFlag=='已启用'){
              this.isReviews.statues = true
              this.loading = false
            }else{
              this.isReviews.statues = false
              this.loading = true
            }
          }
          this.isReviews.id = row.id
        })
      });
    },
    isReview(){
    },
    sumbites(){
      if(this.isReviews.statues==true){
        this.isReviews.status=1
      }else{
        this.isReviews.status=0
      }
      let formData = new FormData();
      formData.append("id",this.isReviews.id)
      formData.append("status",this.isReviews.status)
      this.$axios_supermall.post("/hnjxjy-core/eduCourseExamSet/isReview",formData).then(resp => {
        if (resp.data.code == 2000) {
          this.cchangedialogVisibled = false;
          this.$message.success("操作成功")
          this.handleSearch()
        }
      })
    },
    // 点击导入
    upload() {
      this.dialogVisibleUpload = true;
    },
    //设置制作教师
    production() {
      this.dialogVisible2 = true;
    },
    //设置教师
    Setting() {
      this.outerVisible = true
    },
    // 导入上传文件
    uploadFile() {},
    // 导入上传文件前判断文件格式
    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    // 点击导出
    download() {},
    handleSelectionChange(val) {
      this.tableChecked = val;
    },
    // 点击修改
    editor(row) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    handleSizeChange2(val) {
    },
    handleCurrentChange2(val) {
    },
    // 点击删除
    deleteCorrespond() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}
.el-dialog__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* overflow: auto; */
  margin: 0;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}

.py {
  background: white;
}
</style>


