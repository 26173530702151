<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        成绩修改审核
      </div>
      <div class="search">
        <el-form :model="search" ref="search"  label-width="75px"  inline>
          <el-form-item>
            <el-button icon="el-icon-check" v-permission="'eduScoreChange:updateByPrimaryKeySelective'" type="primary" @click="editor">审核</el-button>
            <el-button icon="el-icon-close" type="primary"@click="reject">驳回</el-button
            >
            <el-button  type="primary" v-permission="'eduScoreChange:queryByPage'" @click="handleSearch">查询</el-button
            >
            <el-button @click="rest(search)">重置</el-button
            >
          </el-form-item>
          <div>
              <el-form-item label="姓名/学号" prop="name">

                <el-input
                  v-model="search.name"
                  clearable
                  @input="() => (search.name = search.name.trim())"
                  placeholder="姓名/学号"
                  clearable></el-input>
              </el-form-item>
           <el-form-item label="考试批次" prop="batchId" >

             <el-select
                 clearable
                 v-model="search.batchId"
                 placeholder="请选择"
                 class="el-form-input-width"
             >
               <el-option
                   v-for="item in eduGraduateWorkBatch"
                   :key="item.id"
                   :label="item.batchName"
                   :value="item.id"
               >
               </el-option>
             </el-select>
          </el-form-item>
           <el-form-item label=" 成绩类型" prop="scoreType" >

            <el-select v-model="search.scoreType" placeholder="请选择" clearable>
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="审核状态" prop="checkStatus" >

            <el-select v-model="search.checkStatus" placeholder="请选择" clearable>
                <el-option
                v-for="item in versionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="550px"
                  max-height="550px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="160"> </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="160"> </el-table-column>
          <el-table-column label="考试批次" prop="batchName" width="160"> </el-table-column>
          <el-table-column label="课程名称" prop="courseName" width="160"> </el-table-column>
          <el-table-column label="课程编号" show-overflow-tooltip prop="courseCode" width="160"> </el-table-column>
          <el-table-column label="成绩类型" prop="scoreType" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.scoreType == 0">离线成绩</span>
              <span v-if="scope.row.scoreType == 1">在线交互</span>
              <span v-if="scope.row.scoreType == 2">在线作业</span>
              <span v-if="scope.row.scoreType == 3">考试成绩</span>
              <span v-if="scope.row.scoreType == 4">总评成绩</span>
            </template>
          </el-table-column>
          <el-table-column label="现有成绩" prop="orgScore" width="160"> </el-table-column>
          <el-table-column label="更改成绩" prop="changeScore" width="160"> </el-table-column>
          <el-table-column label="提交时间" prop="submitTime" width="160"> </el-table-column>
          <el-table-column label="提交人" prop="submitName" width="160"> </el-table-column>
          <el-table-column label="审核状态" prop="checkStatus" width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.checkStatus == 0">等待审核</span>
              <span v-if="scope.row.checkStatus == 1">审核通过</span>
              <span v-if="scope.row.checkStatus == 2">驳回</span>
            </template>
          </el-table-column>
          <el-table-column label="审核时间" prop="checkTime" width="160"> </el-table-column>
          <el-table-column label="审核人" prop="checkName" width="160"> </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="驳回"
        :visible.sync="dialogVisibleUpload"
        width="30%"
      >
        <div >
        <div style="margin-bottom: 20px; text-align: left">
          驳回原因：<el-input v-model="teacherForm.remark" placeholder="请输入内容" style="width:40%"></el-input>
        </div>
        </div>
        <div style="margin-bottom: 20px; text-align: left">
          <el-button @click="dialogVisibleUpload = false"
          >关闭</el-button
          >
          <el-button type="primary"
          @click="submit">确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch();
    this.QueryEduGraduateWorkBatch();
  },
  data() {
    return {
      eduGraduateWorkBatch:"",
      total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      dialogVisibleUpload: false,
      dialogVisible: false,
      radio: "1",
      title: "",
      search: {
        name: "",
        batchId:"",
        checkStatus:"",
        scoreType:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        ids:"",
        checkStatus:"",
        remark:"",
      },
      tableChecked: [],
      tableData: [],
      versionList: [
        {
          value: 0,
          label: "等待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "驳回",
        },
      ],
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "离线成绩",
        },
        {
          value: "1",
          label: "在线交互",
        },
        {
          value: "2",
          label: "在线作业",
        },
        {
          value: "3",
          label: "考试成绩",
        },
        {
          value: "4",
          label: "总评成绩",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    //查询批次下拉框
    QueryEduGraduateWorkBatch() {
      this.$axios_supermall.post("/hnjxjy-core/eduExamBatch/list?page=1&pageSize=10").then(resp => {
        if (resp.data.code == 2000) {
          this.eduGraduateWorkBatch = resp.data.data.list;
        }
      })
    },

    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      this.$axios_supermall.get("/hnjxjy-core/eduScoreChange/queryByPage?page=" +this.search.page+
          "&pageSize="+this.search.pageSize+
          "&studentNameOrNum="+this.search.name+
          "&batchId="+this.search.batchId+
          "&scoreType="+this.search.scoreType+
          "&checkStatus="+this.search.checkStatus

      ).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;

      })
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    editor(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].id);
        }
        let arr = array.toString();

          this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$axios({
              url: "hnjxjy-core/eduScoreChange/updateByPrimaryKeySelective",
              headers: this.loginList,
              method: "post",
              data: {
                ids: arr,
                checkStatus: 1
              },
            }).then((res) => {
              if (res.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              }
              this.openLoadingView();
              this.handleSearch()
              this.dialogVisibleUpload=false
            })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "操作失败",
                  });
                });
          });
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    submit(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      if(array.length<=0){
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }else{
        this.$confirm("你将对(" + array.length + ")条数据进行操作!", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios({
            url: "hnjxjy-core/eduScoreChange/updateByPrimaryKeySelective",
            headers: this.loginList,
            method: "post",
            data: {
              ids: arr,
              checkStatus: 2,
              remark:this.teacherForm.remark
            },
          }).then((res) => {
            if (res.data.code == 2000) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
            this.openLoadingView();
            this.handleSearch()
            this.dialogVisibleUpload=false
          })

              .catch(() => {
                this.$message({
                  type: "info",
                  message: "操作失败",
                });
              });
        });
      }
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    reject(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.dialogVisibleUpload=true
        this.teacherForm={}
      }else{
        this.$message({
          type: "error",
          message: "请至少选中一条数据!",
        });
      }
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    addexam() {
      this.dialogVisibleUpload = true;
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
</style>
