<template>
  <div>


    <div>
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        肆业管理
      </div>
      <div class="search">
        <el-form :model="search" ref="search" label-width="75px" inline>
          <el-form-item>
            <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduStudentUndergraduate:export'">导出</el-button>
            <el-button icon="el-icon-bottom" type="primary" @click="downloads" v-permission="'eduScoreOverall:export'">导出成绩单</el-button>
            <el-button icon="el-icon-upload2" type="primary" v-permission="'eduStudentUndergraduate:importData'" @click="Batch()">导入肆业生</el-button>
            <el-button icon="el-icon-setting" type="primary" v-permission="'eduStudentUndergraduate:setIsunder'" @click="unusual()">设置肆业</el-button>
            <el-button icon="el-icon-bottom" type="primary" v-permission="'eduStudentUndergraduate:updateIsunder'" @click="editRecovery">取消肆业</el-button>
            <el-button type="primary" v-permission="'eduStudentUndergraduate:list'" @click="handleSearch">查询</el-button>
            <el-button @click="rest(search)">重置</el-button>
          </el-form-item>
          <div>
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                    v-model="search.name"
                    clearable
                    @input="() => (search.name = search.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 220px"
                ></el-input>
              </el-form-item>
            <el-form-item label="函授站" prop="siteId" >
              <el-select
                  @change="handleSearch"
                  style="width: 220px"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="gradeId" >
              <el-select clearable style="width: 220px" v-model="search.gradeId" @change="handleSearch"  class="el-form-input-width" placeholder="请选择">
                <el-option
                    v-for="item in this.option"
                    :key="item.id"
                    :label="item.gradeName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次" prop="stageId" >
              <el-select @change="handleSearch" style="width: 220px" clearable v-model="search.stageId"  placeholder="请选择">
                <el-option
                    v-for="item in eduStage"
                    :key="item.id"
                    :label="item.stageName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业" prop="profId" >
              <el-select
                  @change="handleSearch"
                  style="width: 220px"
                  clearable
                  v-model="search.profId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in eduProfession"
                    :key="item.id"
                    :label="item.profName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间"  prop="name">
              <el-date-picker
                  style="width: 220px"
                  v-model="search.startTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间"  prop="name">
              <el-date-picker
                  style="width: 220px"
                  v-model="search.endTime"
                  type="datetime"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="tableData" border @selection-change="handleSelectionChange"
                  size="medium"
                  height="520px"
                  max-height="520px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" width="100" type="index"></el-table-column>
          <el-table-column label="姓名" prop="name" width="180">
          </el-table-column>
          <el-table-column label="学号" prop="studentNo" width="180">
          </el-table-column>
          <el-table-column label="性别" prop="sex" width="180">
          </el-table-column>
          <el-table-column label="年级" prop="gradeName" width="180">
          </el-table-column>
          <el-table-column label="层次" prop="stageName" width="180">
          </el-table-column>
          <el-table-column label="专业" prop="profName" width="180">
          </el-table-column>
          <el-table-column label="身份证号" prop="certificateNo" width="180">
          </el-table-column>
          <el-table-column label="函授站" prop="siteName" width="180">
          </el-table-column>
          <el-table-column label="肄业时间" prop="undergraduateTime" width="180">
          </el-table-column>
          <el-table-column label="肄业原因" prop="undergraduateReason" width="180">
          </el-table-column>
          <el-table-column label="操作" width="130" fixed="right">
            <template slot-scope="scope">
              <div style="color: #1890ff;cursor: pointer;" @click="reshuffle(scope.row)">
                查看成绩
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="search.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入 上传文件 -->


      <!--   复学   -->
      <el-dialog
          title="取消肄业"
          :visible.sync="dialogreasonss"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">

            <span style="margin-left: 20px">年级</span>
            <el-select clearable v-model="versionLists.gradeId" style="width: 200px;margin-left: 15px"  class="el-form-input-width" placeholder="请选择">
              <el-option
                  v-for="item in this.option"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
<!--            <span style="margin-left: 550px">学学籍状态</span>-->
<!--            <el-select clearable v-model="versionLists.xueji" style="width: 200px;margin-left: 10px"  class="el-form-input-width" placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in this.options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
          </div>
        </div>
        <div >
          <div style="margin-bottom: 20px;margin-left: 105px ">
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasonss = false">关闭</el-button>
          <el-button type="primary"
                     @click="submits">确定</el-button
          >
        </div>
      </el-dialog>

      <!-- 导入 上传文件 -->
      <el-dialog title="上传文件" :visible.sync="dialogVisibleUpload" width="30%">
        <div style="margin-bottom: 20px">
          <el-button type="primary" v-permission="'eduStudentUndergraduate:exportMB'" @click="downloadbutton">下载模板</el-button>
          <span style="color: red"
          >提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span
          >
        </div>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过50M
          </div>
        </el-upload>
      </el-dialog>
      <!-- 查看成绩 -->
      <el-dialog
          title="查看成绩"
          :visible.sync="dialogVisible1"
          width="60%"
      >
        <!-- <div style="margin-bottom: 20px; text-align: left">
          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
          <el-button type="primary"
            >设置休学</el-button
          >
        </div> -->
        <div style="text-align: left">
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            姓名：{{ selects.name }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            学号：{{ this.selects.studentNo }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            教学点：{{ this.selects.siteName }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            肆业时间：{{ this.selects.undergraduateTime }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            专业：{{ this.selects.profName }}
          </div>
          <div style="padding: 0.4rem; display: inline-block;width:40%">
            层次： {{ this.selects.stageName }}
          </div>
        </div>
        <div style="margin-top:1rem">
          <el-table :data="versionList" border style="width: 100%"
                    size="medium"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}">
            <el-table-column prop="courseName" label="课程名称" >
            </el-table-column>
            <el-table-column prop="courseCode" label="课程编号">
            </el-table-column>
            <el-table-column prop="score" label="总评成绩" >
            </el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <!--设置休学学生 -->
      <el-dialog
          title="设置肄业原因"
          :visible.sync="dialogVisible2"
          width="60%"
      >
        <div style=" text-align: left">
          <el-button type="primary" @click="dialogVisible2=false">取消</el-button>
          <el-button type="primary" @click="editPause" style="margin-bottom: 10px">肄业</el-button>
          <el-button type="primary" @click="unusual" style="margin-bottom: 10px">查询</el-button>
        </div>

        <div>
          <el-form :model="search2" ref="search" label-width="90px" inline>
            <div>
              <el-form-item prop="name" label="姓名/学号" >
                <el-input
                    v-model="search2.name"
                    clearable
                    @input="() => (search2.name = search2.name.trim())"
                    placeholder="姓名/学号"
                    style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="函授站" prop="siteId" >
                <el-select
                    @change="unusual"
                    style="width: 220px"
                    clearable
                    v-model="search2.siteId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in site"
                      :key="item.id"
                      :label="item.siteName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年级" prop="gradeId" >
                <el-select clearable style="width: 220px" v-model="search2.gradeId" @change="unusual"  class="el-form-input-width" placeholder="请选择">
                  <el-option
                      v-for="item in this.option"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次" prop="stageId" >
                <el-select @change="unusual" style="width: 220px" clearable v-model="search2.stageId"  placeholder="请选择">
                  <el-option
                      v-for="item in eduStage"
                      :key="item.id"
                      :label="item.stageName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业" prop="profId" >
                <el-select
                    @change="unusual"
                    style="width: 220px"
                    clearable
                    v-model="search2.profId"
                    placeholder="请选择"
                    class="el-form-input-width"
                >
                  <el-option
                      v-for="item in eduProfession"
                      :key="item.id"
                      :label="item.profName"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="margin-top:1rem">
          <el-table :data="tableDatas" border style="width: 100%" @selection-change="handleSelectionChange"
                    size="medium"
                    max-height="400px"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" label="序号" width="130"></el-table-column>
            <el-table-column prop="studentNo" label="学号" width="130">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="siteName" label="函授站" >
            </el-table-column>
            <el-table-column prop="stageName" label="层次">
            </el-table-column>
            <el-table-column prop="profName" label="专业" >
            </el-table-column>
            <el-table-column prop="gradeName" label="年级">
            </el-table-column>
          </el-table>
          <div style="padding: 1rem">
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="search2.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search2.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="search2Total"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
      <el-dialog
          title="设置肆业原因"
          :visible.sync="dialogreasons"
          width="30%"
      >
        <div >
          <div style="margin-bottom: 20px; text-align: center">
            原因：
            <el-input v-model="teacherForm.reason" type="textarea" placeholder="请输入内容" style="width:40%"></el-input>
          </div>
        </div>
        <div style="margin-bottom: 20px; text-align: center">
          <el-button @click="dialogreasons = false"
          >关闭</el-button
          >
          <el-button type="primary"
                     @click="submit">确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.queryEduStage();
    this.queryEduSite();
    this.queryEduProfession();
    this.gradList();
    this.handleSearch();
  },
  data() {
    return {
      site:[],
      option:[],
      eduStage:[],
      eduProfession:[],
      selects:{
        name:"",
        studentNo:"",
        siteName:"",
        profName:"",
        stageName:"",
        undergraduateTime:"",
      },
      total: 0,
      search2Total: 0,
      pageNum: 1,
      num: 1,
      input: "",
      pageSize: 10,
      textarea: "",
      radio: "1",
      title: "",
      dialogVisibleUpload: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogreasons:false,
      dialogreasonss:false,
      search: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10,
      },
      search2: {
        name: "",
        siteId: "",
        gradeId: "",
        stageId: "",
        profId: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10,
      },
      teacherForm: {
        reason: "",
      },
      tableChecked: [],
      tableData: [
      ],
      tableDatas: [
      ],
      versionList: [],
      versionLists:{
        gradeId:"",
        xueji:""
      },
      currentPage4: 4,
      currentPage1: 4,
      options: [
        {
          value: "0",
          label: "正式生",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduStudentCourse:list', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("gradeId",this.search.gradeId);
      fromData.append("stageId",this.search.stageId);
      fromData.append("profId",this.search.profId);
      fromData.append("info",this.search.name);
      if(this.search.startTime!=null&&this.search.startTime!=""){
        fromData.append("startTime",this.search.startTime.formatToLocalDateTime());
      }
      if (this.search.endTime!=null&&this.search.endTime!=""){
        fromData.append("endTime",this.search.endTime.formatToLocalDateTime());
      }
      this.$axios_supermall.post("/hnjxjy-core/eduStudentUndergraduate/list?&page="+this.search.page+"&pageSize="+this.search.pageSize,fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
      });
    },
    //查询年级
    gradList() {
      this.$axios_supermall.post("/hnjxjy-core/eduGrade/listGL").then(resp=>{
        if (resp.data.code == 2000) {
          this.option = resp.data.data;
        }
      })
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    //查询层次
    queryEduStage() {
      this.$axios_supermall.post("/hnjxjy-core/eduStage/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduStage = resp.data.data;
        }
      })
    },
    //查询专业
    queryEduProfession() {
      this.$axios_supermall.post("/hnjxjy-core/eduProfession/listGL").then(resp => {
        if (resp.data.code == 2000) {
          this.eduProfession = resp.data.data;
        }
      })
    },

    beforeUpload(file) {
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls && !isXlsx) {
        this.$message.error("上传文件只能是XLS/XLSX格式！");
        return false;
      }
      return isXls || isXlsx;
    },
    uploadFile(file){
      let fileDate = file.file;
      let forms = new FormData();
      forms.append("file", fileDate);
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "post",
        url: "hnjxjy-core/eduStudentUndergraduate/importData",
        data: forms,
        headers: objPar,
        responseType: "blob",
      }).then((res) => {
        if(res.data.type === "application/json"){
          this.$message.success("操作成功")
          this.dialogVisibleUpload = false
          this.handleSearch()
        }else{
          this.$message.error("操作失败")
          this.dialogVisibleUpload = false
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
        }
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.dialogVisibleUpload = false;
        this.loadingView.close();
      });
    },



    downloadbutton(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentUndergraduate/exportMB",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '导入肆业生模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
//导入休学
    Batch() {
      this.dialogVisibleUpload = true;
    },
    unusual() {
      this.dialogVisible2 = true;

      setTimeout(() => {
        this.openLoadingView()
      }, 100);
      setTimeout(() => {
        let fromData = new FormData();
        fromData.append("siteId",this.search2.siteId);
        fromData.append("gradeId",this.search2.gradeId);
        fromData.append("stageId",this.search2.stageId);
        fromData.append("profId",this.search2.profId);
        fromData.append("info",this.search2.name);
        fromData.append("page",this.search2.page);
        fromData.append("pageSize",this.search2.pageSize);
        this.$axios_supermall.post("/hnjxjy-core/eduStudentInfo/list",fromData).then(resp=>{
          this.tableDatas = resp.data.data.list; //表格列表
          this.search2Total = resp.data.data.total;
        })
        this.loadingView.close();
      }, 1000);
    },

    editRecovery(){

        if(this.multipleSelection!=null&&this.multipleSelection!=""){
            this.dialogreasonss = true
            this.versionLists ={}
        }else{
          this.$message({
            type: "error",
            message: "请至少选择一条数据!",
          });
        }

      // if(this.multipleSelection!=null&&this.multipleSelection!=""){
      //   this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      //   let array = [];
      //   for (let i = 0; i < this.multipleSelection.length; i++) {
      //     array.push(this.multipleSelection[i].id);
      //   }
      //   let arr = array.toString();
      //   this.$axios({
      //     url: "hnjxjy-core/eduStudentUndergraduate/updateIsunder",
      //     headers: this.loginList,
      //     method: "post",
      //     params: {
      //       ids: arr,
      //     },
      //   }).then((res) => {
      //     if (res.data.code == 2000) {
      //       this.$message({
      //         type: "success",
      //         message: "操作成功!",
      //       });
      //       this.dialogreasons = false
      //       this.handleSearch()
      //     }else{
      //       this.$message({
      //         type: "info",
      //         message: res.data.message,
      //       });
      //     }
      //   }).catch(() => {
      //         this.$message({
      //           type: "info",
      //           message: "操作失败",
      //         });
      //       });
      // }else{
      //   this.$message({
      //     type: "error",
      //     message: "请至少选择一条数据!",
      //   });
      // }
    },

    reshuffle(row){
      this.dialogVisible1 = true
      this.versionList=[]
      this.$axios_supermall.get("/hnjxjy-core/eduStudentScoreStat/selectAchievement?studentId="+row.studentId,
      ).then(resp=>{
        this.versionList=resp.data.data
      })
      this.selects.name = row.name;
      this.selects.studentNo = row.studentNo;
      this.selects.siteName = row.siteName;
      this.selects.profName = row.profName;
      this.selects.stageName = row.stageName;
      this.selects.undergraduateTime = row.undergraduateTime;
    },
    rest(form){
      this.$refs.search.resetFields()
    },
    editPause(){
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        this.dialogreasons = true
        this.teacherForm = {}
      }else{
        this.$message({
          type: "error",
          message: "请至少选择一条数据!",
        });
      }
    },
    download(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView();
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentUndergraduate/export",
        responseType: "blob",
        headers: objPar,
        params:{
          siteId:this.search.siteId,
          gradeI:this.search.gradeId,
          stageId:this.search.stageId,
          profId:this.search.profId,
          info:this.search.name,
          startTime:this.search.startTime,
          endTime:this.search.endTime
        }
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.$message.success("操作成功");
        this.loadingView.close();
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    },
    downloads(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      if(this.multipleSelection!=null&&this.multipleSelection!=""){
        let objPar = JSON.parse(localStorage.getItem("loginStrage"));
        let array = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          array.push(this.multipleSelection[i].studentId);
        }
        let arr = array.toString();
        this.openLoadingView();
        this.$axios({
          method: "get",
          url: "hnjxjy-core/eduScoreOverall/export",
          responseType: "blob",
          headers: objPar,
          params:{
            id:arr
          }
        }).then((res) => {
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          let url = window.URL.createObjectURL(blob);
          window.location.href = url;
          this.$message.success("操作成功");
          this.loadingView.close();
        }).catch((err) => {
          this.$message.error("操作失败");
          this.loadingView.close();
        });
      }else{
        this.$message({
          type:"error",
          message:"至少选择一个！"
        })
      }
    },
    photo(){
      this.$axios_supermall.post("/hnjxjy-core/upload/uploadPictures",).then(resp=>{
      })
    },
    submit(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      this.$axios({
        url: "hnjxjy-core/eduStudentUndergraduate/setIsunder",
        headers: this.loginList,
        method: "post",
        params: {
          ids: arr,
          reason:this.teacherForm.reason
        },
      }).then((res) => {
        if (res.data.code == 2000) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.dialogreasons = false
          this.handleSearch()
        }else{
          // this.$message({
          //   type: "info",
          //   message: res.data.message,
          // });
        }
      })

          .catch(() => {
            this.$message({
              type: "info",
              message: "操作失败",
            });
          });
    },
    submits(){
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let array = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        array.push(this.multipleSelection[i].id);
      }
      let arr = array.toString();
      if(this.versionLists.gradeId != null && this.versionLists.gradeId != ""){
        let formData = new FormData();
        formData.append("gradeId",this.versionLists.gradeId)
        formData.append("ids",arr)
        this.$axios_supermallData.post("hnjxjy-core/eduStudentUndergraduate/updateIsunder",formData).then((res) => {
        if (res.data.code == 2000) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.dialogreasonss = false
          this.handleSearch()
        }else{
          this.$message({
            type: "info",
            message: res.data.message,
          });
        }
      })
      }else{
        this.$message.error("年级不能为空")
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {

      this.search.page = val;
      this.handleSearch();

    },
    handleSizeChange1(val) {
      this.search2.pageSize = val;
      this.unusual();
    },
    handleCurrentChange1(val) {
      this.search2.page = val;
      this.unusual();
    },
  },
};
</script>
<style>


.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.backgroundColor {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
.background {
  display: inline-block;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-right: 1rem;
  font-size: 14px;
  border-radius: 6px;
}
</style>
