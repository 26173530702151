<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                批次管理
            </div>
            <div class="search">
                <el-form :model="search" class="demo-ruleForm" inline>
                    <el-form-item>
                        <el-button icon="el-icon-bottom" type="primary" @click="download" v-permission="'eduExamBatch:exportAllEduExamBatch'">导出</el-button>
                        <el-button icon="el-icon-edit" type="primary" v-permission="'eduExamBatch:insertEduExamBatch'" @click="Batch()">
                            添加
                        </el-button>
                        <!-- <el-button icon="el-icon-setting" type="primary" @click="reshuffle()"
                          >设置学籍异动（层次和专业）</el-button
                        > -->
                        <!-- <el-button icon="el-icon-check" type="primary">审核</el-button> -->
                        <!-- <el-button icon="el-icon-close" type="primary">驳回</el-button> -->
                    </el-form-item>
                    <div>
                        <el-form-item label="批次名称" style="margin-bottom: 0">
                            <el-input
                                    v-model="queryBO1.batchName"
                                    clearable
                                    placeholder="请输入内容"
                                    style="width: 200px">
                            </el-input>
                            <el-button
                                    icon="el-icon-search"
                                    @click="handleSearch">
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-form-item label="函授站">
                                <el-select v-model="queryBO1.siteId" placeholder="请选择函授站" @change="handleSearch"
                                           style="width: 300px">
                                    <el-option
                                            v-for="item in siteList"
                                            :key="item.id"
                                            :label="item.siteName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable1" @selection-change="change1" :data="tableData1" border
                          size="medium"
                          height="520px"
                          max-height="520px"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column label="序号" width="100" type="index">
                    </el-table-column>
                    <el-table-column label="添加考试范围" prop="name" width="220">
                        <template slot-scope="scope">
                            <div style="color: #1890ff; cursor: pointer" @click="testscope(scope.row.id)">添加考试范围</div>
                        </template>
                    </el-table-column>
                  <el-table-column label="查看考试课程" prop="name" width="220">
                    <template slot-scope="scope">
                      <div style="color: #1890ff; cursor: pointer" @click="examine(scope.row.id)">查看考试课程</div>
                    </template>
                  </el-table-column>
<!--                    <el-table-column label="设置场次" prop="sex" width="180">-->
<!--                        <template>-->
<!--                            <div style="color: #1890ff; cursor: pointer" @click="sessions()">设置场次</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                  <el-table-column label="设置年级场次" prop="sex" width="180">-->
<!--                    <template>-->
<!--                      <div style="color: #1890ff; cursor: pointer" @click="etc()">设置年级场次</div>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                    <el-table-column label="批次名称" prop="batchName" width="220">
                    </el-table-column>
                    <el-table-column label="开始时间" prop="startTime" width="220">
                    </el-table-column>
                    <el-table-column label="结束时间" prop="endTime" width="220">
                    </el-table-column>
<!--                    <el-table-column label="安排考试开始时间" prop="roomStartTime" width="200">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="安排考试结束时间" prop="roomEndTime" width="200">-->
<!--                    </el-table-column>-->
                    <el-table-column label="备注" prop="remark" width="200">
                    </el-table-column>
                    <el-table-column label="操作" width="260" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="primary" v-permission="'eduExamBatch:updateEduExamBatch'" @click="reshuffle(scope.row)">
                                编辑
                            </el-button>
                            <!--                            <el-button type="primary" @click="offline(scope.row)">-->
                            <!--                                线下预约-->
                            <!--                            </el-button>-->
                            <el-button type="primary" v-permission="'stuCourseDetail:onlineBookingStu'" @click="onlineBookingStu(scope.row.id)">
                                线上预约
                            </el-button>
                            <!--                            <el-button type="primary" @click="adjustment(scope.row)">-->
                            <!--                                生成调整-->
                            <!--                            </el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                  <el-pagination
                      @size-change="handleSizeChange1"
                      @current-change="handleCurrentChange1"
                      :current-page="queryBO1.page"
                      :page-sizes="[10, 50, 100, 500]"
                      :page-size="queryBO1.pageSize"
                      background
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="queryBO1.total">
                  </el-pagination>

                </div>
            </div>
            <!-- 添加批次 -->
            <el-dialog
                    :title="this.titles"
                    :visible.sync="dialogVisibleUpload"
                    width="50%"
                    :before-close="handleClose"
                    style="text-align: left">
                <el-form
                        :model="teacherForm"
                        ref="ruleForm"
                        :rules="rulesOne"
                        class="demo-ruleForm"
                        label-width="110px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="批次名称：" prop="batchName">
                                <el-input
                                        style="width:15vw"
                                        v-model="teacherForm.batchName"
                                        placeholder="请输入"
                                        class="el-form-input-width">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否补考：" prop="secondExam">
                                <el-select  style="width:15vw" v-model="teacherForm.secondExam" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="开始时间：" prop="startTime">
                                <el-date-picker
                                    style="width:15vw"
                                        v-model="teacherForm.startTime"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="结束时间：" prop="endTime">
                                <el-date-picker
                                    style="width:15vw"
                                        v-model="teacherForm.endTime"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
<!--                    <el-row>-->
<!--                        <el-col :span="12">-->
<!--                            <el-form-item-->
<!--                                    label="安排考场开始时间："-->
<!--                                    prop="roomStartTime"-->
<!--                                    style="width: 150px">-->
<!--                                <el-date-picker-->
<!--                                        style="width:300px"-->
<!--                                        v-model="teacherForm.roomStartTime"-->
<!--                                        type="datetime"-->
<!--                                        placeholder="选择日期时间">-->
<!--                                </el-date-picker>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="12">-->
<!--                            <el-form-item label="安排考场结束时间：" prop="roomEndTime">-->
<!--                                <el-date-picker-->
<!--                                        style="width:300px"-->
<!--                                        v-model="teacherForm.roomEndTime"-->
<!--                                        type="datetime"-->
<!--                                        placeholder="选择日期时间">-->
<!--                                </el-date-picker>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="备注：" prop="remark">
                                <el-input
                                        style="width:39vw"
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="teacherForm.remark">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleUpload = false">取 消</el-button>
                    <el-button type="primary" @click="handleSave('ruleForm')">保存后关闭</el-button>
                </div>
            </el-dialog>
            <!-- 编辑 -->
            <!-- 考场预约分布 -->
            <el-dialog
                    title="考场预约分布"
                    :visible.sync="dialogVisible2"
                    width="45%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="date" label="展开" width="120">
                        </el-table-column>
                        <el-table-column prop="name" label="场次">
                        </el-table-column>
                        <el-table-column prop="address" label="学生总数"></el-table-column>
                    </el-table>
                </div>
                <div style="margin-bottom: 20px; text-align: right">
                    <el-button @click="dialogVisible2 = false">取消</el-button>
                    <el-button type="primary">确认</el-button>
                </div>
            </el-dialog>
            <!-- 生成调整 -->
            <el-dialog
                    title="未生成学生"
                    :visible.sync="dialogVisible3"
                    width="60%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" @click="dialogVisible3=false">关闭</el-button>
                    <el-button type="primary">
                        生成
                    </el-button>
                </div>
                <div style="text-align: left">
                    <div style="padding: 0.4rem; display: inline-block">
                        姓名/学号：
                        <el-input
                                v-model="search.name"
                                clearable
                                @input="() => (search.name = search.name.trim())"
                                placeholder="请输入内容"
                                style="width: 200px">
                        </el-input>
                        <el-button icon="el-icon-search" @click="handleSearch"></el-button>
                    </div>
                    <div style="padding: 0.4rem; display: inline-block">
                        函授站
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="padding: 0.4rem; display: inline-block">
                        年级
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="padding: 0.4rem; display: inline-block">
                        层次
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="padding: 0.4rem; display: inline-block">
                        专业
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-top:1rem">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="date" label="序号" width="100"></el-table-column>
                        <el-table-column prop="date" label="学号" width="130">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="函授站">
                        </el-table-column>
                        <el-table-column prop="name" label="层次">
                        </el-table-column>
                        <el-table-column prop="name" label="专业">
                        </el-table-column>
                        <el-table-column prop="name" label="年级">
                        </el-table-column>
                        <el-table-column prop="name" label="课程名称">
                        </el-table-column>
                        <el-table-column prop="name" label="课程编号">
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChange1"
                                @current-change="handleCurrentChange1"
                                :current-page="currentPage1"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="400">
                        </el-pagination>
                    </div>
                </div>
            </el-dialog>
            <!-- 添加考试范围 -->
            <el-dialog
                    title="添加考试范围"
                    :visible.sync="dialogVisible4"
                    width="50%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible4=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-remove-outline" @click="deleteEduExamBatch">删除</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible5=true">添加
                    </el-button>
                </div>
                <div style="text-align: left">
                    <div style="padding: 0.4rem; display: inline-block">
                        年级
                        <el-select v-model="queryBO2.gradeId" placeholder="请选择" @change="">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-top:1rem">
                    <el-table :data="tableData2" @selection-change="change2" border style="width: 100%">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column type="index" label="序号" width="100"></el-table-column>
                        <el-table-column prop="gradeName" label="年级" width="130">
                        </el-table-column>
                        <el-table-column prop="stageName" label="层次" width="180">
                        </el-table-column>
                        <el-table-column prop="currentTerm" label="学期">
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChange2"
                                @current-change="handleCurrentChange2"
                                :current-page="queryBO2.page"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="queryBO2.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="queryBO2.total">
                        </el-pagination>
                    </div>
                </div>

            </el-dialog>
            <el-dialog
                    title="添加考试范围"
                    :visible.sync="dialogVisible5"
                    width="30%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible5=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-folder-add" @click="insertEduExamBatchScope('addForm')">
                        保存后关闭
                    </el-button>
                </div>
                <div style="text-align: left;margin-top:1rem">
                    <div style="padding: 0.4rem;">
                        <el-form ref="addForm" :model="queryBO3" :rules="rulesTwo" label-width="80px">
                            <el-form-item label="年级：" prop="gradeId">
                                <el-select v-model="queryBO3.gradeId" placeholder="请选择">
                                    <el-option
                                            v-for="item in gradeList"
                                            :key="item.id"
                                            :label="item.gradeName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="层次：" prop="stageId">
                                <el-select v-model="queryBO3.stageId" placeholder="请选择">
                                    <el-option
                                            v-for="item in stageList"
                                            :key="item.id"
                                            :label="item.stageName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
            <!-- 查看考试课程 -->
            <el-dialog
                    title="查看课程"
                    :visible.sync="dialogVisible6"
                    :before-close = "examBatchCourseBeforeClose"
                    width="50%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="examBatchCourseClose()">关闭</el-button>
                    <el-button type="primary" icon="el-icon-remove-outline" @click="deleteEduExamBatchCourse()">移除</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible11True">修改课程考试类型
                    </el-button>
                  <el-button type="primary" icon="el-icon-remove-outline" @click="insertEduExamBatchCourse">添加</el-button>
                </div>
                <div style="text-align: left">
                    <div style="padding: 0.4rem; display: inline-block">
                        名称/编号：
                        <el-input
                                v-model="examCourse.courseNameOrCode"
                                clearable
                                @input="() => (examCourse.courseNameOrCode = examCourse.courseNameOrCode.trim())"
                                placeholder="请输入内容"
                                style="width: 200px">
                        </el-input>
                        <el-button icon="el-icon-search" @click="queryEduExamBatchCourseList"></el-button>
                    </div>
                    <div style="padding: 0.4rem; display: inline-block">
                        考试类型
<!--                        <el-select v-model="examCourse.examType" placeholder="请选择" clearable>-->
<!--                            <el-option-->
<!--                                    v-for="item in examCourseOptions"-->
<!--                                    :key="item.value"-->
<!--                                    :label="item.label"-->
<!--                                    :value="item.value">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
                    </div>
                </div>
                <div style="margin-top:1rem">
                    <el-table :data="tableDataExamCourse" border style="width: 100%" @selection-change="examCourseSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column type="index" label="序号" width="100"></el-table-column>
                        <el-table-column prop="courseName" label="课程名称">
                        </el-table-column>
                        <el-table-column prop="courseCode" label="课程编号" width="180">
                        </el-table-column>
                        <el-table-column label="考试类型">
                          <template slot-scope="scope">
                            <span v-if="scope.row.examType === 0">考查课</span>
                            <span v-if="scope.row.examType === 1">在线考试</span>
                            <span v-if="scope.row.examType === 2">书面考试</span>
                          </template>
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChangeExamCourse"
                                @current-change="handleCurrentChangeExamCourse"
                                :current-page="examCourse.page"
                                :page-sizes="[10, 50, 100, 500]"
                                :page-size="examCourse.pageSize"
                                background
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="examCourse.total">
                        </el-pagination>
                    </div>
                </div>
            </el-dialog>
            <!-- 设置场次 -->
            <el-dialog
                    title="设置场次"
                    :visible.sync="dialogVisible7"
                    width="50%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible7=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-remove-outline" @click="deleteEduExamBatchPlace">删除
                    </el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible8=true">
                        添加
                    </el-button>
                </div>
                <div style="margin-top:1rem">
                    <el-table :data="tableData3" @selection-change="change3" border style="width: 100%">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="date" label="序号" width="100"></el-table-column>
                        <el-table-column prop="date" label="场次名称">
                        </el-table-column>
                        <el-table-column prop="name" label="开始时间" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="结束时间" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="备注">
                        </el-table-column>
                        <el-table-column label="编辑">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="updateEduExamBatchPlace(scope.row)">编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChange3"
                                @current-change="handleCurrentChange3"
                                :current-page="queryBO4.page"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="queryBO4.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="queryBO4.total">
                        </el-pagination>
                    </div>
                </div>
            </el-dialog>
            <el-dialog
                    title="添加场次"
                    :visible.sync="dialogVisible8"
                    width="30%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible8=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-folder-add" @click="insertEduExamBatchPlace('form5')">保存后关闭
                    </el-button>
                </div>
                <div style="text-align: left;margin-top:1rem">
                    <div style="padding: 0.4rem;">
                        <el-form ref="form5" :model="queryBO5" :rules="rulesThere" label-width="80px">
                            <el-form-item label="场次：" prop="placeId">
                                <el-select v-model="queryBO5.placeId" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="开始时间：" prop="startTime">
                                <el-date-picker
                                        v-model="queryBO5.startTime"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="结束时间：" prop="endTime">
                                <el-date-picker
                                        v-model="queryBO5.endTime"
                                        type="datetime"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="备注：" prop="remark">
                                <el-input
                                        style="width:80%"
                                        type="textarea"
                                        placeholder="请输入内容"
                                        v-model="queryBO5.remark">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
            <!-- 设置年级场次 -->
            <el-dialog
                    title="设置年级场次"
                    :visible.sync="dialogVisible9"
                    width="50%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible9=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-remove-outline">删除</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible10=true">添加
                    </el-button>
                </div>
                <div style="margin-top:1rem">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="date" label="序号" width="100"></el-table-column>
                        <el-table-column prop="date" label="年级名称">
                        </el-table-column>
                        <el-table-column prop="name" label="场次名称">
                        </el-table-column>
                    </el-table>
                    <div style="padding: 1rem">
                        <el-pagination
                                @size-change="handleSizeChange1"
                                @current-change="handleCurrentChange1"
                                :current-page="currentPage1"
                                :page-sizes="[100, 200, 300, 400]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="400">
                        </el-pagination>
                    </div>
                </div>
            </el-dialog>
            <el-dialog
                    title="添加年级场次"
                    :visible.sync="dialogVisible10"
                    width="30%">
                <div style="margin-bottom: 20px; text-align: left">
                    <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible10=false">关闭</el-button>
                    <el-button type="primary" icon="el-icon-folder-add">保存后关闭
                    </el-button>
                </div>
                <div style="text-align: left;margin-top:1rem">
                    <div style="padding: 0.4rem;">
                        年级：
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="padding: 0.4rem;">
                        场次：
                        <el-select v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </el-dialog>

          <el-dialog
              title="修改课程考试类型"
              :visible.sync="dialogVisible11"
              width="30%">
            <div style="margin-bottom: 20px; text-align: left">
              <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible11 = false">关闭</el-button>
              <el-button type="primary" icon="el-icon-folder-add" @click="updateEduExamBatchCourse">保存后关闭
              </el-button>
            </div>
            <div style="text-align: left;margin-top:1rem">
              <div style="padding: 0.4rem; display: inline-block">
                考试类型
                <el-select v-model="updateExamCourse.examType" placeholder="请选择" clearable>
                  <el-option
                      v-for="item in examTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-dialog>


          <!-- 添加考试课程 -->
          <el-dialog
              title="添加考试课程"
              :visible.sync="dialogVisible16"
              :before-close="queryEduCourseBeforeClose"
              width="50%">
            <div style="margin-bottom: 20px; text-align: left">
              <el-button type="primary" icon="el-icon-circle-close" @click="queryEduCourseClose">关闭</el-button>
              <el-button type="primary" icon="el-icon-remove-outline" @click="addEduExamBatchCourse">添加</el-button>
            </div>
            <div style="text-align: left">
            </div>
            <div style="margin-top:1rem">
              <el-table :data="tableDataQueryEduCourse" border style="width: 100%" @selection-change="queryEduCourseSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column type="index" label="序号" width="100"></el-table-column>
                <el-table-column prop="courseCode" label="课程编号" width="180">
                </el-table-column>
                <el-table-column prop="courseName" label="课程名称">
                </el-table-column>
              </el-table>
              <div style="padding: 1rem">
                <el-pagination
                    @size-change="handleSizeChangeQueryEduCourse"
                    @current-change="handleCurrentChangeQueryEduCourse"
                    :current-page="queryEduCourse.page"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="queryEduCourse.pageSize"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryEduCourse.total">
                </el-pagination>
              </div>
            </div>
          </el-dialog>




        </div>
    </div>
</template>
<script>



    export default {

        data() {
            return {
              insertEduExamBatchCourseBatchId: null,
              tableDataExamCourse: [],
              examCourse: {
                page: 1,
                pageSize: 10,
                total: 0,
                courseNameOrCode: null,
                examType: null,
              },
              examTypeOptions: [
                {value: 0, label: '考查课'},
                {value: 1, label: '在线考试'},
                {value: 2, label: '书面考试'}
              ],
              updateExamCourse: {
                examType: null,
              },
              examCourseArray: [],

              tableDataQueryEduCourse: [],
              queryEduCourse: {
                page: 1,
                pageSize: 10,
                total: 0,
              },
              queryEduCourseArray: [],


                total: 0,
                pageNum: 1,
                num: 1,
                input: "",
                pageSize: 10,
                textarea: "",
                radio: "1",
                title: "",
                dialogVisibleUpload: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                dialogVisible4: false,
                dialogVisible5: false,
                dialogVisible6: false,
                dialogVisible7: false,
                dialogVisible8: false,
                dialogVisible9: false,
                dialogVisible10: false,
              dialogVisible11: false,
              dialogVisible16: false,
                search: {
                    name: "",
                },
                teacherForm: {
                    batchName: "",
                    secondExam: 0,
                    startTime: '',
                    endTime: '',
                    roomStartTime: '',
                    roomEndTime: '',
                    remark: ''
                },
                tableChecked: [],
                tableData: [],
                tableData2: [],
                tableData3: [],
                versionList: [
                    {
                        value: 0,
                        label: "旧版",
                    },
                    {
                        value: 1,
                        label: "新版",
                    },
                ],
                currentPage4: 4,
                currentPage1: 4,
                options: [
                    {value: 0, label: '否'},
                    {value: 1, label: '是'}
                ],
                value: "",
              titles: "",
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "今天",
                            onClick(picker) {
                                picker.$emit("pick", new Date());
                            },
                        },
                        {
                            text: "昨天",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24);
                                picker.$emit("pick", date);
                            },
                        },
                        {
                            text: "一周前",
                            onClick(picker) {
                                const date = new Date();
                                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", date);
                            },
                        },
                    ],
                },
                value1: "",
                siteList: [],
                queryBO1: {
                    batchName: '',
                    siteId: null,
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                queryBO2: {
                    gradeId: '',
                    page: 1,
                    pageSize: 10,
                    total: 0,
                    batchId: ''
                },
                queryBO3: {
                    gradeId: '',
                    stageId: ''
                },
                queryBO4: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                queryBO5: {
                    batchId: '',
                    placeId: '',
                    startTime: '',
                    endTime: '',
                    remark: ''
                },
                tableData1: [],
                multipleSelection1: [],
                multipleSelection2: [],
                multipleSelection3: [],
                rulesOne: {
                    batchName: [
                        {required: true, message: '请输入批次名称', trigger: 'blur'}
                    ],
                    secondExam: [
                        {required: true, message: '请选择是否是补考', trigger: 'change'}
                    ],
                    startTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                    endTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                    roomStartTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                    roomEndTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                },
                rulesTwo: {
                    gradeId: [
                        {required: true, message: '请选择年级', trigger: 'change'}
                    ],
                    stageId: [
                        {required: true, message: '请选择层次', trigger: 'change'}
                    ]
                },
                rulesThere: {
                    placeId: [
                        {required: true, message: '请选择场次', trigger: 'change'}
                    ],
                    startTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ],
                    endTime: [
                        {required: true, message: '请选择时间', trigger: 'change'}
                    ]
                },
                gradeList: [],
                stageList: []
            };
        },
        methods: {
            updateEduExamBatchPlace(row) {
                this.$set(this.queryBO5, 'id', row.id);
                this.$set(this.queryBO5, 'placeId', row.placeId);
                this.$set(this.queryBO5, 'startTime', row.startTime);
                this.$set(this.queryBO5, 'endTime', row.endTime);
                this.$set(this.queryBO5, 'remark', row.remark);
                this.dialogVisible8 = true;
            },
            insertEduExamBatchPlace(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let bo = {
                            batchId: this.queryBO5.batchId,
                            placeId: this.queryBO5.placeId,
                            startTime: this.formatToLocalDateTimeMixin('yyyy-MM-dd HH:mm:ss', this.queryBO5.startTime),
                            endTime: this.formatToLocalDateTimeMixin('yyyy-MM-dd HH:mm:ss', this.queryBO5.endTime),
                            remark: this.queryBO5.remark
                        };
                        this.$axios_supermall.post('/eduExamBatch/insertEduExamBatchPlace', bo).then(response => {
                            if (response.data.code === 2000) {
                                this.$message.success('处理成功');
                                this.dialogVisible8 = false;
                                this.queryExamBatchPlace();
                            }
                        })
                    } else {
                        return false
                    }
                })
            },
            deleteEduExamBatchPlace() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection3)) {
                    this.$message.warning("请至少选择一条");
                    return;
                }
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.getIds(this.multipleSelection3, 'id');
                    let bo = 'ids=' + ids + '&batchId=' + this.queryBO2.batchId;
                    this.openLoadingView();
                    this.$axios_supermall.get('/hnjxjy-core/?' + bo).then(response => {
                        if (response.data.code === 2000) {
                            this.$message.success("删除成功");
                            this.queryExamBatchPlace();
                        }
                    })
                }).catch(() => {

                });
            },
          updateEduExamBatchCourse(){
            let ids = '';
            this.examCourseArray.forEach(m => {
              ids += m.id + ','
            });
            ids = ids.substring(0, ids.length - 1);
            let formData = new FormData();
            formData.append("ids",ids);
            formData.append("examType",this.updateExamCourse.examType);
            this.$axios_supermallData.post('/hnjxjy-core/eduExamBatch/updateEduExamBatchCourseById',formData).then(response => {
              if (response.data.code === 2000) {
               this.dialogVisible11 = false
                this.$message.success("操作成功")
                this.queryEduExamBatchCourseList();
              }else{
                this.dialogVisible11 = false
                this.$message.success("操作失败")
                this.queryEduExamBatchCourseList();
              }
            })
          },
            queryExamBatchPlace() {
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduExamBatch/queryEduExamBatchPlace').then(response => {
                    if (response.data.code === 2000) {
                        this.tableData3 = response.data.data.list;
                        this.queryBO4.total = response.data.data.total;
                    }
                })
            },
            download() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduExamBatch/exportAllEduExamBatch",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '批次管理.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$message.success("操作成功")
                })
            },
            onlineBookingStu(id) {
                let bo = {
                    batchId: id
                };
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/stuCourseDetail/onlineBookingStu', bo).then(response => {
                    if (response.data.code === 2000) {
                        this.$message.success('线上考试预约成功');
                    }
                })
            },
            insertEduExamBatchScope(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let bo = {
                            batchId: this.queryBO2.batchId,
                            gradeId: this.queryBO3.gradeId,
                            stageId: this.queryBO3.stageId
                        };
                        this.openLoadingView();
                        this.$axios_supermall.post('/hnjxjy-core/eduExamBatch/insertEduExamBatchScope', bo).then(response => {
                            if (response.data.code === 2000) {
                                this.$message.success("添加成功");
                                this.$refs[formName].resetFields();
                                this.dialogVisible5 = false;
                                this.queryEduExamBatchScopeAll();
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            deleteEduExamBatch() {
                if (this.getMultipleSelectionIsNull(this.multipleSelection2)) {
                    this.$message.warning("请至少选择一条");
                    return;
                }
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.getIds(this.multipleSelection2, 'id');
                    let bo = 'ids=' + ids + '&batchId=' + this.queryBO2.batchId;
                    this.openLoadingView();
                    this.$axios_supermall.get('/hnjxjy-core/eduExamBatch/deleteEduExamBatchScope?' + bo).then(response => {
                        if (response.data.code === 2000) {
                            this.$message.success("删除成功");
                            this.queryEduExamBatchScopeAll();
                        }
                    })
                }).catch(() => {

                });
            },
            queryEduExamBatchScopeAll() {
                let bo = 'page=' + this.queryBO2.page + '&pageSize=' + this.queryBO2.pageSize + '&batchId=' + this.queryBO2.batchId;
                if (this.getIsNull(this.queryBO2.gradeId)) {
                    bo += '&gradeId=' + this.queryBO2.gradeId;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduExamBatch/queryEduExamBatchScopeAll?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData2 = response.data.data.list;
                        this.queryBO2.total = response.data.data.total;
                    }
                })
            },
            handleClose(done) {
                this.teacherForm = {
                    batchName: "",
                    secondExam: 0,
                    startTime: '',
                    endTime: '',
                    roomStartTime: '',
                    roomEndTime: '',
                    remark: ''
                };
                done();
            },
            handleSave(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = 'eduExamBatch/insertEduExamBatch';
                        // let bo = {
                        //     "batchName": this.teacherForm.batchName,
                        //     "secondExam": this.teacherForm.secondExam,
                        //     "startTime": this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.startTime),
                        //     "endTime": this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.endTime),
                        //     // "roomStartTime": this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.roomStartTime),
                        //     // "roomEndTime": this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.roomEndTime),
                        //     "remark": this.teacherForm.remark
                        // };
                        // if (this.teacherForm.id != null && this.teacherForm.id !== '') {
                        //     url = 'eduExamBatch/updateEduExamBatch';
                        //     this.$set(bo, 'id', this.teacherForm.id);
                        // }
                        //
                        let formData = new FormData();
                        formData.append("batchName", this.teacherForm.batchName);
                        formData.append("secondExam", this.teacherForm.secondExam);
                        formData.append("startTime",  this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.startTime));
                        formData.append("endTime", this.formatToLocalDateTimeMixin('yyyy/MM/dd HH:mm:ss', this.teacherForm.endTime));
                        formData.append("remark", this.teacherForm.remark);

                      if (this.teacherForm.id != null && this.teacherForm.id !== '') {
                        url = 'eduExamBatch/updateEduExamBatch';
                        // this.$set(bo, 'id', this.teacherForm.id);
                        formData.append("id", this.teacherForm.id);
                      }
                        this.openLoadingView();
                        this.$axios_supermallData.post('/hnjxjy-core/' + url, formData).then(response => {
                            if (response.data.code === 2000) {
                                this.$message.success("处理成功");
                                this.$set(this.teacherForm, 'id', null);
                                this.$refs[formName].resetFields();
                                this.dialogVisibleUpload = false;
                                this.handleSearch();
                                this.loadingView.close();
                            }else {
                              this.$message.error(response.data.message)
                              this.loadingView.close();
                            }
                        }).catch((e)=>{
                          this.$message.error(e)
                          this.loadingView.close();
                        })
                    } else {
                        return false;
                    }
                });
            },
            handleSearch() {
              this.getPermission('eduStudentCourse:list', () => {
                let fromData = new FormData();
                fromData.append("page",this.queryBO1.page);
                fromData.append("pageSize",this.queryBO1.pageSize);
                if (this.getIsNull(this.queryBO1.batchName)) {
                  fromData.append('batchName', this.queryBO1.batchName);
                }
                if (this.getIsNull(this.queryBO1.siteId)) {
                  fromData.append('siteId', this.queryBO1.siteId);
                }
                this.openLoadingView();
                this.$axios_supermallData.post('/hnjxjy-core/eduExamBatch/list', fromData).then(response => {
                    if (response.data.code === 2000) {
                        // this.tableData1 = response.data.data.list;
                        this.tableData1 = response.data.data.list;
                        this.queryBO1.total = response.data.data.total;
                        this.loadingView.close();
                    }else {
                      this.$message.error(response.data.message)
                      this.loadingView.close();
                    }
                }).catch((e)=>{
                  this.$message.error(e)
                  this.loadingView.close()
                });
                });
            },
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.site = resp.data.data;
                }
              })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = JSON.parse(JSON.stringify(response.data.data));
                    }
                })
            },
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = JSON.parse(JSON.stringify(response.data.data));
                    }
                })
            },
            //endregion
            handleSizeChange1(val) {
                this.queryBO1.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange1(val) {
                this.queryBO1.page = val;
                this.handleSearch();
            },
            change1(val) {
                this.multipleSelection1 = val;
            },
            handleSizeChange2(val) {
                this.queryBO2.pageSize = val;
                this.queryEduExamBatchScopeAll();
            },
            handleCurrentChange2(val) {
                this.queryBO2.page = val;
                this.queryEduExamBatchScopeAll();
            },
            change2(val) {
                this.multipleSelection2 = val;
            },
            handleSizeChange3(val) {
                this.queryBO4.pageSize = val;
                queryExamBatchPlace();
            },
            handleCurrentChange3(val) {
                this.queryBO4.page = val;
                queryExamBatchPlace();
            },
            change3(val) {
                this.multipleSelection3 = val;
            },
            //批量上传图片
            Batch() {
              this.titles="添加批次"
                this.dialogVisibleUpload = true;
            },
            reshuffle(row) {
                this.$set(this.teacherForm, 'id', row.id);
                this.$set(this.teacherForm, 'batchName', row.batchName);
                this.$set(this.teacherForm, 'secondExam', row.secondExam);
                this.$set(this.teacherForm, 'startTime', row.startTime);
                this.$set(this.teacherForm, 'endTime', row.endTime);
                this.$set(this.teacherForm, 'roomStartTime', row.roomStartTime);
                this.$set(this.teacherForm, 'roomEndTime', row.roomEndTime);
                this.$set(this.teacherForm, 'remark', row.remark);
              this.titles="编辑批次"
                this.dialogVisibleUpload = true;
            },
            offline() {
                this.dialogVisible2 = true;
            },
            adjustment() {
                this.dialogVisible3 = true;
            },
            //添加考试范围
            testscope(id) {
                this.dialogVisible4 = true;
                this.queryBO2.batchId = id;
                this.queryEduExamBatchScopeAll();
            },
            //查看考试课程
            examine(id) {
              this.insertEduExamBatchCourseBatchId = id;
              this.dialogVisible6 = true;
              setTimeout(() => {
                this.openLoadingView();
              }, 100);
              setTimeout(() => {
                this.queryEduExamBatchCourseList();
                this.loadingView.close();
              }, 1000);
            },
            //设置场次
            sessions(id) {
                this.queryBO5.batchId = id;
                this.dialogVisible7 = true;
                this.queryExamBatchPlace();
            },
            etc() {
                this.dialogVisible9 = true;
            },
          queryEduExamBatchCourseList(){
              this.openLoadingView();
              this.$axios_supermallData({
                url: '/hnjxjy-core/eduExamBatch/queryEduExamBatchCourseList',
                method: 'get',
                params: {
                  page: this.examCourse.page,
                  pageSize: this.examCourse.pageSize,
                  courseNameOrCode: this.examCourse.courseNameOrCode,
                  examType: this.examCourse.examType,
                }
              }).then(resp => {
                if (resp.data.code === 2000) {
                  this.tableDataExamCourse = resp.data.data.list;
                  this.examCourse.total = resp.data.data.total;
                  this.loadingView.close();
                }
              }).catch((e) => {
                this.$message.error(e)
                this.loadingView.close();
              })
          },
          handleSizeChangeExamCourse(val){
              this.examCourse.pageSize = val;
              this.queryEduExamBatchCourseList();
          },
          handleCurrentChangeExamCourse(val){
              this.examCourse.page = val;
              this.queryEduExamBatchCourseList();
          },
          dialogVisible11True(){
              if (this.examCourseArray.length == 0){
                return this.$message.error("请选择数据")
              }
              this.dialogVisible11 = true;
          },
          examCourseSelectionChange(val){
            this.examCourseArray = val;
          },
          deleteEduExamBatchCourse(){
              if (this.examCourseArray.length === 0){
                return this.$message.error("请选择数据")
              }
              let idArray = [];
              for (let i = 0 ; i < this.examCourseArray.length ; i++){
                idArray.push(this.examCourseArray[i].id);
              }
              this.openLoadingView();
              this.$axios_supermallData({
                url: '/hnjxjy-core/eduExamBatch/deleteEduExamBatchCourseById',
                method: 'get',
                params: {
                  ids: idArray.toString()
                }
              }).then(resp => {
                if (resp.data.code === 2000){
                  this.$message.success("移除成功")
                  this.examCourse.page = 1;
                  this.queryEduExamBatchCourseList();
                  this.loadingView.close();
                }else {
                  this.$message.error("移除失败")
                  this.loadingView.close();
                }
              }).catch(err => {
                this.$message.error(err)
                this.loadingView.close();
              });
          },
          insertEduExamBatchCourse(){
            this.dialogVisible16 = true;
            setTimeout(() => {
              this.openLoadingView();
            }, 100);
            setTimeout(() => {
              this.queryEduCourseBySchoolId();
              this.loadingView.close();
            }, 1000);

          },
          examBatchCourseClose(){
            this.dialogVisible6 = false;
            this.insertEduExamBatchCourseBatchId = null;
            this.tableDataExamCourse = [];
            this.examCourseArray = [];
          },
          examBatchCourseBeforeClose(done){
            done();
            this.insertEduExamBatchCourseBatchId = null;
            this.tableDataExamCourse = [];
            this.examCourseArray = [];
          },
          queryEduCourseBySchoolId(){
              this.openLoadingView();
              this.$axios_supermallData.get("/hnjxjy-core/eduExamBatch/queryEduCourseBySchoolId?page="+this.queryEduCourse.page+"&pageSize="+this.queryEduCourse.pageSize)
            .then(resp => {
              if (resp.data.code === 2000){
                this.tableDataQueryEduCourse = resp.data.data.list;
                this.queryEduCourse.total = resp.data.data.total;
                this.loadingView.close();
              }
            })
            .catch(err => {
              this.$message.error(err)
              this.loadingView.close();
            });
          },
          addEduExamBatchCourse(){
            if (this.queryEduCourseArray.length === 0){
              return this.$message.error("请选择数据")
            }
            let idArray = [];
            for (let i = 0 ; i < this.queryEduCourseArray.length ; i++){
              idArray.push(this.queryEduCourseArray[i].id);
            }
            this.$axios_supermallData({
              url: '/hnjxjy-core/eduExamBatch/insertEduExamBatchCourse',
              method: 'post',
              params: {
                ids: idArray.toString(),
                batchId: this.insertEduExamBatchCourseBatchId,
              }
            }).then(resp => {
              if (resp.data.code === 2000){
                this.$message.success("添加成功")
                this.queryEduCourseClose();
                this.queryEduExamBatchCourseList();
              }
            }).catch(err => {
              this.$message.error(err)
            });
          },
          queryEduCourseSelectionChange(val){
              this.queryEduCourseArray = val;
          },
          handleSizeChangeQueryEduCourse(val){
            this.queryEduCourse.pageSize = val;
            this.queryEduCourseBySchoolId();
          },
          handleCurrentChangeQueryEduCourse(val){
            this.queryEduCourse.page = val;
            this.queryEduCourseBySchoolId();
          },
          queryEduCourseClose(){
            this.dialogVisible16 = false;
            this.tableDataQueryEduCourse = [];
            this.queryEduCourseArray = [];
          },
          queryEduCourseBeforeClose(done){
            done();
            this.tableDataQueryEduCourse = [];
            this.queryEduCourseArray = [];
          },
        }
        ,
        mounted() {
            this.handleSearch();
            this.querySiteList();
            this.queryGradeList();
            this.queryStageList();
        }
    }
    ;
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    .backgroundColor {
        display: inline-block;
        padding: 0.3rem 0.5rem;
        color: #fff;
        margin-right: 1rem;
        font-size: 14px;
        border-radius: 6px;
    }

    .background {
        display: inline-block;
        padding: 0.5rem 1rem;
        color: #fff;
        margin-right: 1rem;
        font-size: 14px;
        border-radius: 6px;
    }
</style>
