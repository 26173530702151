<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                学生信息
            </div>
            <div class="search">
                <el-form inline label-width="110px" ref="selectForm" :model="queryBO">
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateStudentInfo:export'" @click="download">导出</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateStudentInfo:update'" @click="updateInfo(0)">修改毕业设计批次</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateStudentInfo:updateType'" @click="updateInfo(1)">修改类型</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduGraduateStudentInfo:updateTypes'" @click="importDialog = true">修改类型批量导入</el-button>
                    </el-form-item>
<!--                    <el-form-item>-->
<!--                        <el-button type="primary">发送预警信息</el-button>-->
<!--                    </el-form-item>-->
                    <el-form-item style="float: right">
                        <el-button type="primary" v-permission="'eduGraduateStudentInfo:list'" @click="queryBasic">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button type="primary" @click="$refs.selectForm.resetFields()">重置</el-button>
                    </el-form-item>
                    <br/>
                    <el-form-item label="姓名/学号" prop="studentOrTeacherNameOrNum">
                        <el-input v-model="queryBO.studentOrTeacherNameOrNum" style="width: 300px"
                                  placeholder="学生姓名/学号"></el-input>
                    </el-form-item>
                    <el-form-item label="毕业类型" prop="graduateType">
                        <el-select v-model="queryBO.graduateType" placeholder="毕业类型" style="width: 300px">
                            <el-option
                                    v-for="item in batchTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="函授站" prop="siteId">
                        <el-select v-model="queryBO.siteId" placeholder="请选择函授站" style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" placeholder="请选择专业" style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开题报告" prop="openReportStatus">
                        <el-select v-model="queryBO.openReportStatus" placeholder="开题报告" style="width: 300px">
                            <el-option
                                    v-for="item in openReportStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="论文" prop="paperStatus">
                        <el-select v-model="queryBO.paperStatus" placeholder="论文" style="width: 300px">
                            <el-option
                                    v-for="item in paperStatusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        border
                        @selection-change="selectionChange"
                        :data="tableData"
                        style="width: 100%"
                        size="medium"
                        height="530px"
                        max-height="530px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="80"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="siteName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            show-overflow-tooltip
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            show-overflow-tooltip
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="batchName"
                            width="120"
                            show-overflow-tooltip
                            label="毕业设计批次">
                    </el-table-column>
                    <el-table-column
                            prop="contactPhone"
                            show-overflow-tooltip
                            label="联系电话">
                    </el-table-column>
                    <el-table-column
                            prop="graduateTypes"
                            label="类型">
                    </el-table-column>
                    <el-table-column
                            prop="teacherName"
                            label="指导教师">
                    </el-table-column>
                    <el-table-column
                            prop="applyFrees"
                            label="免做申请">
                    </el-table-column>
                    <el-table-column
                            prop="openReportStatuss"
                            label="开题报告">
                    </el-table-column>
                    <el-table-column
                            prop="paperStatuss"
                            label="论文">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialog"
                width="60%">
            <div>
                <el-form label-width="80px" inline>
                    <el-form-item>
                        <el-button type="primary" @click="dialog = false">关闭</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="commitUpdate">保存后关闭</el-button>
                    </el-form-item>
                </el-form>
                <el-form label-width="80px" style="width: 100%">
                    <el-form-item label="批次" style="width: 100%" v-show="updateInfoType==0">
                        <el-select v-model="updateBO.batchId">
                            <el-option
                                    v-for="item in batchList"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="毕业类型" style="width: 100%" v-show="updateInfoType==1">
                        <el-select v-model="updateBO.graduateType">
                            <el-option
                                    v-for="item in [{label: '答辩', value: 0}, {label: '评审', value: 1}]"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadTemplate">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "studentInfo",

        data() {
            return {
                importDialog:false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    siteId: null,//站点id
                    gradeId: null,//年级id
                    stageId: null,//层次id
                    profId: null,//专业id
                    graduateType: null,//毕业类型 0答辩 1评审
                    openReportStatus: null,//开题报告状态 论文状态 0未提交 1等待审核 2审核通过 3驳回
                    paperStatus: null,//论文状态 0未提交 1等待审核 2审核通过 3驳回
                    studentOrTeacherNameOrNum: ''//学生姓名/学号
                },
                siteList: [],//函授站下拉框列表
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                batchList: [],//毕业设计批次
                batchTypeList: [
                    {label: '全部', value: null},
                    {label: '答辩', value: 0},
                    {label: '评审', value: 1}
                ],//批次类型列表
                openReportStatusList: [
                    {label: '全部', value: null},
                    {label: '未提交', value: 0},
                    {label: '等待审核', value: 1},
                    {label: '审核通过', value: 2},
                    {label: '驳回', value: 3}
                ],//开题报告状态
                paperStatusList: [
                    {label: '全部', value: null},
                    {label: '未提交', value: 0},
                    {label: '等待审核', value: 1},
                    {label: '审核通过', value: 2},
                    {label: '驳回', value: 3}
                ],//论文状态
                totalOne: 0,//主table分页
                updateInfoType: 0,//0-修改毕业设计批次 1-修改类型
                dialog: false,
                dialogTitle: '修改毕业批次',
                updateBO: {
                    ids: '',
                    batchId: '',
                    graduateType:0
                },
                multipleSelection: []
            }
        },
        methods: {
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
                this.$axios_supermall({
                    method: "post",
                    url: "hnjxjy-core/eduGraduateDesignStu/importEduScoreGraduateTemplate2",
                    data: forms,
                }).then((res) => {
                    if (res.data.code === 2000) {
                        this.$message.success(res.data.data);
                        this.importDialog = false;
                        this.queryBasic();
                    }
                }).catch((err) => {
                });
            },
            downloadTemplate(){
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateDesignStu/exprotTemplateEduGraStuMake",
                    responseType: "blob"
                }).then(res=>{
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '修改类型批量导入模板.xlsx';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                })
            },
            download(){
                // this.$axios({
                //     method: "get",
                //     url: "/hnjxjy-core/eduGraduateDesignStu/statEduStuInfoExcel",
                //     responseType: "blob",
                //     headers: objPar,
                // }).then((res) => {
                //     let blob = new Blob([res.data], {
                //         type: "application/vnd.ms-excel",
                //     });
                //     let url = window.URL.createObjectURL(blob);
                //     window.location.href = url;
                // })
              this.openLoadingView()
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduGraduateDesignStu/exprotTemplateEduGraStuMake",
                    responseType: "blob"
                }).then(res=>{
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '学生信息.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            commitUpdate() {
                let url = '/hnjxjy-core/eduGraduateDesignStu/updateEduGraduateBatchStu';
                let ids = '';
                let ides = '';
                this.multipleSelection.forEach(m => {
                    ids += m.designId + ','
                });
              this.multipleSelection.forEach(m => {
                ides += m.id + ','
              });
                ids = ids.substring(0, ids.length - 1);
                ides = ides.substring(0, ides.length - 1);
                if (this.updateInfoType == 1) {
                  let bo = {
                    "ids": ids //学生id字符串
                  };
                    url = '/hnjxjy-core/eduGraduateDesignStu/updateByGraduateTypeById';
                    this.$set(bo,'graduateType',this.updateBO.graduateType);
                  this.openLoadingView();
                  this.$axios_supermall.post(url, bo).then(response => {
                    if (response.data.code === 2000) {
                      this.$message.success("处理成功");
                      this.dialog = false;
                      this.queryBasic();
                    }
                  })
                }else{
                  let bo = {
                    "ids": ides //学生id字符串
                  };
                    this.$set(bo,'batchId',this.updateBO.batchId) //批次id
                  this.openLoadingView();
                  this.$axios_supermall.post(url, bo).then(response => {
                    if (response.data.code === 2000) {
                      this.$message.success("处理成功");
                      this.dialog = false;
                      this.queryBasic();
                    }
                  })
                }
            },
            //region  修改信息
            updateInfo(type) {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
                    this.$message.warning("至少选择一项");
                    return;
                }
                this.updateInfoType = type;
                this.dialog = true;
                if (type == 0) {//修改毕业设计批次
                    this.dialogTitle = '修改毕业批次';
                } else if (type == 1) {//修改类型
                    this.dialogTitle = '修改毕业类型';
                    this.updateBO.graduateType = this.multipleSelection[0].graduateType;
                }
            },
            //endregion
            //region
            selectionChange(val) {
                this.multipleSelection = val;
            },
            //endregion
            //region 查询批次下拉框
            queryBatchList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateDesignBatch/list').then(response => {
                    if (response.data.code === 2000) {
                        this.batchList = response.data.data;
                        this.updateBO.batchId = this.batchList[0].id
                    }
                })
            },
            //endregion
            //region 基础查询
            queryBasic() {

              this.getPermission('eduGraduateStudentInfo:list', () => {

                    let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;
                if (this.getIsNull(this.queryBO.siteId)) {
                    bo += '&siteId=' + this.queryBO.siteId;
                }
                if (this.getIsNull(this.queryBO.gradeId)) {
                    bo += '&gradeId=' + this.queryBO.gradeId;
                }
                if (this.getIsNull(this.queryBO.stageId)) {
                    bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                    bo += '&profId=' + this.queryBO.profId;
                }
                if (this.getIsNull(this.queryBO.graduateType)) {
                    bo += '&graduateType=' + this.queryBO.graduateType;
                }
                if (this.getIsNull(this.queryBO.openReportStatus)) {
                    bo += '&openReportStatus=' + this.queryBO.openReportStatus;
                }
                if (this.getIsNull(this.queryBO.paperStatus)) {
                    bo += '&paperStatus=' + this.queryBO.paperStatus;
                }
                if (this.getIsNull(this.queryBO.studentOrTeacherNameOrNum)) {
                    bo += '&studentOrTeacherNameOrNum=' + this.queryBO.studentOrTeacherNameOrNum;
                }
                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduGraduateDesignStu/queryStuInfoByPage?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.totalOne = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = response.data.data;
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = response.data.data;
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = response.data.data;
                        this.gradeList.unshift({id: null, gradeName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.querySiteList();//查询函授站下拉框列表
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBasic();//基础查询
            this.queryBatchList();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
