<template>
    <div>


        <div>
            <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
                录入成绩
            </div>
            <div class="search">
                <el-form inline label-width="80px" :model="queryBO" ref="queryForm">
                    <el-form-item>
                        <el-button type="primary" @click="results">录入成绩</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduAchievement:export'" @click="downLoad">导出</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-permission="'eduAchievement:import'" @click="importDialog=true">导入</el-button>
                    </el-form-item>
<!--                    <el-form-item>-->
<!--                        <el-button v-permission="'eduAchievement:deleted'" type="primary">删除</el-button>-->
<!--                    </el-form-item>-->
                    <div style="float: right">
                        <el-form-item>
                            <el-button type="primary" v-permission="'eduAchievement:list'" @click="queryBasic">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="$refs.queryForm.resetFields()">重置</el-button>
                        </el-form-item>
                    </div>
                    <br/>
                    <el-form-item label="学号/姓名" prop="studentNo">
                        <el-input v-model="queryBO.studentNo" style="width: 300px"
                                  placeholder="学号/姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="毕业批次" prop="batchId">
                        <el-select v-model="queryBO.batchId" placeholder="请选择毕业批次" style="width: 300px">
                            <el-option
                                    v-for="item in batchList"
                                    :key="item.id"
                                    :label="item.batchName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="毕业类型" prop="graduateType">
                        <el-select v-model="queryBO.graduateType" style="width: 300px">
                            <el-option
                                    v-for="item in [{value:null,label:'全部'},{value:0,label:'答辩'},{value:1,label:'评审'}]"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分数类型" prop="scoreLevel">
                        <el-select v-model="queryBO.scoreLevel" style="width: 300px">
                            <el-option
                                    v-for="item in scoreLevelList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="函授站" prop="schoolId">
                        <el-select v-model="queryBO.schoolId" placeholder="请选择函授站" style="width: 300px">
                            <el-option
                                    v-for="item in siteList"
                                    :key="item.id"
                                    :label="item.siteName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年级" prop="gradeId">
                        <el-select v-model="queryBO.gradeId" placeholder="请选择年级" style="width: 300px">
                            <el-option
                                    v-for="item in gradeList"
                                    :key="item.id"
                                    :label="item.gradeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="层次" prop="stageId">
                        <el-select v-model="queryBO.stageId" placeholder="请选择层次" style="width: 300px">
                            <el-option
                                    v-for="item in stageList"
                                    :key="item.id"
                                    :label="item.stageName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业" prop="profId">
                        <el-select v-model="queryBO.profId" placeholder="请选择专业" style="width: 300px">
                            <el-option
                                    v-for="item in profList"
                                    :key="item.id"
                                    :label="item.profName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main_contain">
                <el-table
                        :data="tableData"
                        border
                        @selection-change="change"
                        style="width: 100%"
                        size="medium"
                        height="530px"
                        max-height="530px"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                        width="80"
                            type="index"
                            label="序号">
                    </el-table-column>
                    <el-table-column
                            prop="studentName"
                            label="姓名">
                    </el-table-column>
                    <el-table-column
                            prop="studentNo"
                            label="学号">
                    </el-table-column>
                    <el-table-column
                            prop="schoolName"
                            label="函授站">
                    </el-table-column>
                    <el-table-column
                            prop="gradeName"
                            label="年级">
                    </el-table-column>
                    <el-table-column
                            prop="stageName"
                            label="层次">
                    </el-table-column>
                    <el-table-column
                            prop="profName"
                            label="专业">
                    </el-table-column>
                    <el-table-column
                            prop="batchName"
                            show-overflow-tooltip
                            label="毕业设计批次">
                    </el-table-column>
                    <el-table-column
                            label="毕业类型">
                        <template slot-scope="scope">
                            <span v-show="scope.row.graduateType == 0">答辩</span>
                            <span v-show="scope.row.graduateType == 1">评审</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="teacherName"
                            label="指导教师">
                    </el-table-column>
                    <el-table-column
                            prop="score"
                            label="成绩">
                    </el-table-column>
                </el-table>
                <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 30, 50, 100]"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-size="queryBO.pageSize"
                        :current-page="queryBO.page"
                        :total="totalOne">
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="录入成绩"
                :visible.sync="dialog"
                width="50%">
            <el-form inline label-width="80px">
                <el-form-item>
                    <el-button @click="dialog = false">关闭</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveAndClose">保存并关闭</el-button>
                </el-form-item>
            </el-form>
            <el-form label-width="80px">
                <el-form-item label="成绩">
                    <el-input-number v-model="commitBO.score" :min="1" label="label"
                                     style="width:300px"></el-input-number>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="导入" :visible.sync="importDialog" width="30%">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="downloadTemplate">下载模板</el-button>
                <span style="color: red">提示：导入前请先下载模板，根据模版整理好数据后上传导入.</span>
            </div>
            <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    只能上传excel文件，且不超过50M
                </div>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>



    export default {
        name: "enterResults",

        data() {
            return {
                importDialog: false,
                queryBO: {
                    page: 1,
                    pageSize: 10,
                    studentNo: '',
                    schoolId: null,
                    gradeId: null,
                    stageId: null,
                    profId: null,
                    batchId: null,
                    graduateType: null,
                    scoreLevel: null
                },
                siteList: [],//函授站下拉框列表
                gradeList: [],//年级下拉框列表
                stageList: [],//层次下拉框列表
                profList: [],//专业下拉框列表
                tableData: [],//主table
                batchList: [],//毕业批次下拉框列表
                totalOne: 0,
                dialog: false,
                scoreLevelList: [
                    {value: null, label: '全部'},
                    {value: 0, label: '未评分'},
                    {value: 1, label: '不通过'},
                    {value: 2, label: '不及格'},
                    {value: 3, label: '通过'},
                    {value: 4, label: '及格'},
                    {value: 5, label: '中'},
                    {value: 6, label: '良'}, {id: 7, label: '优'}
                ],
                multipleSelection: [],
                commitBO: {
                    id: 0, //主键id
                    score: 0, //分数
                    scoreLevel: 0 //0未评分 1不通过 2不及格 3通过 4及格 5中 6良 7优
                },
            }
        },
        methods: {
            beforeUpload(file) {
                const isXls = file.type === "application/vnd.ms-excel";
                const isXlsx =
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (!isXls && !isXlsx) {
                    this.$message.error("上传文件只能是XLS/XLSX格式！");
                    return false;
                }
                return isXls || isXlsx;
            },
            uploadFile(file) {
                let fileDate = file.file;
                let forms = new FormData();
                forms.append("file", fileDate);
              this.openLoadingView()
                this.$axios_supermall({
                    method: "post",
                    url: "/hnjxjy-core/eduScoreGraduate/importEduScoreGraduateTemplate2",
                    data: forms,
                }).then((res) => {
                    if (res.data.code === 2000) {
                      this.loadingView.close()
                      this.$message.success("操作成功")
                        this.importDialog = false;
                        this.queryBasic();
                    }
                }).catch((err) => {
                });
            },
            downloadTemplate() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduScoreGraduate/exportEduScoreGraduateTemplate3",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '导入成绩模板.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            results() {
                if (this.multipleSelection == null || this.multipleSelection.length <= 0 || this.multipleSelection.length > 1) {
                    this.$message.warning("请选择一项");
                    return;
                }
                this.dialog = true;
            },
            change(val) {
                this.multipleSelection = val;
            },
            //region 保存并关闭
            saveAndClose() {
                let bo = {
                    id: this.multipleSelection[0].id
                };
                this.$set(bo, 'score', this.commitBO.score)
                this.commitBO.id = this.multipleSelection[0].id;
                this.openLoadingView();
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateWorkBatch/updateServiceScoreById', bo).then(response => {
                    if (response.data.code === 2000) {
                      this.$message.success("操作成功")
                        this.dialog = false;
                        this.queryBasic();
                    }
                })
            },
            //endregion
            //region 导出
            downLoad() {
                this.openLoadingView();
                this.$axios_supermall({
                    method: "get",
                    url: "/hnjxjy-core/eduScoreGraduate/exportEduScoreGrauateVoo",
                    responseType: "blob"
                }).then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel",
                    });
                    // let url = window.URL.createObjectURL(blob);
                    // window.location.href = url;
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = '毕业设计学生成绩信息.xls';　　　　　　　　　　// xxx.xls/xxx.xlsx
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                  this.$message.success("操作成功")
                })
            },
            //endregion
            //region 基础查询
            queryBasic() {
              this.getPermission('eduAchievement:list', () => {
                let bo = 'page=' + this.queryBO.page + '&pageSize=' + this.queryBO.pageSize;

                if (this.getIsNull(this.queryBO.studentNo)) {
                    bo += '&studentNameOrNo=' + this.queryBO.studentNo;
                }
                if (this.getIsNull(this.queryBO.batchId)) {
                    bo += '&batchId=' + this.queryBO.batchId;
                }
                if (this.getIsNull(this.queryBO.graduateType)) {
                    bo += '&graduateType=' + this.queryBO.graduateType;
                }
                if (this.getIsNull(this.queryBO.scoreLevel)) {
                    bo += '&scoreLevel=' + this.queryBO.scoreLevel;
                }
                if (this.getIsNull(this.queryBO.schoolId)) {
                    bo += '&schoolId=' + this.queryBO.schoolId;
                }
                if (this.getIsNull(this.queryBO.gradeId)) {
                    bo += '&gradeId=' + this.queryBO.gradeId;
                }

                if (this.getIsNull(this.queryBO.stageId)) {
                    bo += '&stageId=' + this.queryBO.stageId;
                }
                if (this.getIsNull(this.queryBO.profId)) {
                    bo += '&profId=' + this.queryBO.profId;
                }

                this.openLoadingView();
                this.$axios_supermall.get('/hnjxjy-core/eduScoreGraduate/queryByAllPage?' + bo).then(response => {
                    if (response.data.code === 2000) {
                        this.tableData = response.data.data.list;
                        this.totalOne = response.data.data.total;
                    }
                })
                });
            },
            //endregion
            // region 查询专业下拉框列表
            queryProfList() {
                this.$axios_supermall.post('/hnjxjy-core/eduProfession/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.profList = response.data.data;
                        this.profList.unshift({id: null, profName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询层次下拉框列表
            queryStageList() {
                this.$axios_supermall.post('/hnjxjy-core/eduStage/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.stageList = response.data.data;
                        this.stageList.unshift({id: null, stageName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询年级下拉框列表
            queryGradeList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGrade/listGL', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.gradeList = response.data.data;
                        this.gradeList.unshift({id: null, gradeName: "全部"})
                    }
                })
            },
            //endregion
            //region 查询函授站下拉框列表
            querySiteList() {
              let formData = new FormData();
              formData.append("isAll","1");
              this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
                if (resp.data.code == 2000) {
                  this.siteList = JSON.parse(JSON.stringify(resp.data.data));
                  this.siteList.unshift({id: null, siteName: "全部"});
                }
              })
            },
            //endregion
            //region 查询毕业批次下拉框列表
            queryBatchList() {
                this.$axios_supermall.post('/hnjxjy-core/eduGraduateDesignBatch/list', {isAll: 1}).then(response => {
                    if (response.data.code === 2000) {
                        this.batchList = response.data.data;
                        this.batchList.unshift({id: null, batchName: "全部"})
                    }
                })
            },
            //endregion
            //region 分页size
            handleSizeChange(val) {
                this.queryBO.pageSize = val;
                this.queryBasic();
            },
            //endregion
            //region 分页Current
            handleCurrentChange(val) {
                this.queryBO.page = val;
                this.queryBasic();
            }
            //endregion
        },
        mounted() {
            this.querySiteList();//查询函授站下拉框列表
            this.queryGradeList();//查询年级下拉框列表
            this.queryStageList();//查询层次下拉框列表
            this.queryProfList();//查询专业下拉框列表
            this.queryBatchList();
            this.queryBasic();
        }
    }
</script>

<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }


    .right {
        float: right;
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);

        text-align: left;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .main_contain {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }
</style>
